import Immutable from 'immutable'

import * as actions from '../actions'
import { extendProductWithUrl } from '../../urlGenerators'

export default function shop(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.SET_MBO_BASE_URL:
      return state.setIn(['shop', 'mboBaseUrl'], action.mboBaseUrl)
    case actions.GET_THEMES_SUCCESS:
      return state.set('themes', Immutable.fromJS(action.response))
    case actions.SET_THEME_SUCCESS:
      return state
        .setIn(['shop', 'theme'], Immutable.fromJS(action.response))
        .setIn(['shop', 'themeId'], action.response.id)
    case actions.LOAD_SHOP_EMAIL_SUCCESS:
      return state.setIn(['shop', 'email'], action.response)
    case actions.PREVIEW_SHOP_LOGO:
    case actions.SET_SHOP_LOGO_SUCCESS:
      return state
        .setIn(['shop', 'logoUrl'], action.response.logoUrl)
        .setIn(['shop', 'logoWidth'], action.response.logoWidth)
        .setIn(['shop', 'logoHeight'], action.response.logoHeight)
        .setIn(['shop', 'shouldHideLogo'], action.response.shouldHideLogo)

    case actions.PREVIEW_USER_SETTINGS:
    case actions.SET_USER_SETTINGS_SUCCESS:
      return state.setIn(['shop', 'userSettings'], Immutable.fromJS(action.response.userSettings))
    case actions.PREVIEW_FAVICON:
    case actions.SET_FAVICON_SUCCESS:
      return state.setIn(['shop', 'faviconUrl'], action.response.faviconUrl)
    case actions.GET_THEME_SETTINGS_SCHEMA_SUCCESS:
      return state.set('themeSettingsSchema', Immutable.fromJS(action.response))
    case actions.GET_DEFAULT_THEME_SETTINGS_SUCCESS:
      return state.set('defaultThemeSettings', Immutable.fromJS(action.response))

    case actions.PREVIEW_THEME_SETTINGS:
    case actions.GET_THEME_SETTINGS_SUCCESS:
    case actions.SET_THEME_SETTINGS_SUCCESS:
      return state.setIn(['shop', 'themeSettings'], Immutable.fromJS(action.response))
    case actions.GET_THEME_META_SUCCESS:
      return state.set('themeMeta', Immutable.fromJS(action.response))
    case actions.SET_THEME_VARIABLES_CSS:
      return state.set('themeVariablesCss', action.variablesCss)
    case actions.GET_SCRIPT_TAGS_SUCCESS:
      return state.set('scriptTags', Immutable.fromJS(action.response))
    case actions.GET_RESELLER_NAME_SUCCESS:
      return state.set('resellerName', action.response)
    case actions.GET_SOCIAL_SHARING_ENABLED_SUCCESS:
      return state.setIn(['shop', 'socialSharingEnabled'], action.response)
    case actions.GET_TAX_SUCCESS:
      return state.set('tax', Immutable.fromJS(action.response))
    case actions.LOAD_CATEGORY_SUCCESS:
      return state.setIn(['shop', 'csrfToken'], action.response.csrfToken)
    case actions.LOAD_PRODUCT_SUCCESS: {
      return state.setIn(['products', action.response.slug], extendProductWithUrl(Immutable.fromJS(action.response)))
    }
    case actions.LOAD_ORDER_SUCCESS:
      return state.setIn(['orders', action.orderId], Immutable.fromJS(action.response))
    case actions.LOAD_SHOP_COUNTRIES_SUCCESS:
      return state.setIn(['shop', 'mboCountries'], Immutable.fromJS(action.payload))
    case actions.SET_SELLING_COUNTRY:
      return state.setIn(['shop', 'sellingCountryId'], action.countryId)
    default:
      return state
  }
}
