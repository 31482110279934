import type { PropsWithChildren, ReactElement } from 'react'
import { noop } from 'lodash'

type Props = {
  onClick: () => void
}

export default function SearchFilterButton({
  onClick = noop,
  children,
}: Readonly<PropsWithChildren<Props>>): ReactElement {
  return (
    <button type="button" className="filter-button" onClick={onClick}>
      {children}
    </button>
  )
}
