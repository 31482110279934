import type { ReactElement } from 'react'
import { flushSync } from 'react-dom'
import { useEffect, useState } from 'react'

import usePrevious from '../utils/hooks/usePrevious'

type DelayUpdateProps = {
  children: ReactElement
  promise: Promise<void>
}

export default function DelayUpdate({ children, promise }: DelayUpdateProps) {
  const previousPromise = usePrevious(promise)
  const [state, setState] = useState(children)

  useEffect(() => {
    if (promise !== previousPromise) {
      promise.then(() => {
        flushSync(() => {
          setState(children)
        })
      })
    }
  }, [children, promise, previousPromise])

  return state
}
