import axios from 'axios'

import {
  customFacetIdPrefix,
  defaultFacetIds,
  getDefaultSorting,
  pagingDefaults,
  rangeFacetDelimiter,
  rangeFacetIds,
  sortingDefaults,
} from '../../utils/pageAndSort'
import { setViewBusyState } from '../actions'

export type ProductActionTypes =
  | SetProductsForCategoryAction
  | AddProductsForCategoryAction
  | SetProductsForSearchAction
  | AddProductsForSearchAction
  | SetIsInWishlistAction
  | ResolvedApiAction<LoadProductsByIds>
  | ResolvedApiAction<LoadProductsForCrossSelling>

// URL queries normally are strings, but sometimes we already parseInt them for parameters.
export type Query<T = string | number> = {
  page?: T
  resultsPerPage?: T
  sort?: string
  [key: string]: any
}

export const fetchVimeoThumbnail = async (vimeoUrl: string): Promise<string> => {
  const result = vimeoUrl.match(/\/video\/(\d*)/)
  return axios
    .get(`https://vimeo.com/api/v2/video/${result?.[1]}.json`)
    .then((response) => response.data[0].thumbnail_medium)
    .catch(() => '')
}

type FilterParams = { key: string; value: string }[]
export const getFilters = (facets: Core.Facets): FilterParams => {
  const params: FilterParams = []

  Object.entries(facets).forEach(([id, facet]) => {
    if (facet.type === 'selection') {
      Object.values(facet.values).forEach((value) => {
        if (value.selected) {
          params.push({
            key: id,
            value: value.value,
          })
        }
      })
    }

    if (facet.type === 'range') {
      if (facet.values?.selected) {
        params.push({
          key: id,
          value: facet.values.selection.min + rangeFacetDelimiter + facet.values.selection.max,
        })
      }
    }
  })

  return params
}

export const SET_PRODUCTS_FOR_CATEGORY = 'SET_PRODUCTS_FOR_CATEGORY'
export type SetProductsForCategoryAction = {
  type: typeof SET_PRODUCTS_FOR_CATEGORY
  payload: {
    categoryId: string
    productResult: Core.PageableSearchResult
    sort: string
    filters: Core.Filter[]
  }
}
export function setProductsForCategory(
  categoryId: string,
  productResult: Core.PageableProductResult,
  sort: string,
  filters: Core.Filter[] = [],
): SetProductsForCategoryAction {
  return {
    type: SET_PRODUCTS_FOR_CATEGORY,
    payload: { categoryId, productResult, sort, filters },
  }
}

export const setProductsForCategoryAsync =
  (categoryId: string, query?: Query, filters: Core.Filter[] = []): GlobalAction =>
  async (dispatch, getState, api) => {
    dispatch(setViewBusyState(true))

    const locale = getState().getIn(['shop', 'locale'])
    const sort = query?.sort || sortingDefaults.sort

    const sellingCountryId = getState().getIn(['shop', 'sellingCountryId']) || null
    const searchParamsObj = {
      ...sortingDefaults,
      ...pagingDefaults,
      ...query,
      categoryId,
      locale,
      sellingCountryId,
      // Axios is not able to merge plain objects with URLSearchParams.
      // So we have to pass the default params manually.
      ...api.defaults.params,
    } as { [key: string]: string | string[] }
    const productData = getState().getIn(['categoryProductData', categoryId])
    const categoryData = getState().getIn(['categories', categoryId])
    const facets: Core.Facets | undefined = productData?.facets

    const params = new URLSearchParams()

    if (facets) {
      getFilters(facets).forEach(({ key, value }) => {
        if (value) params.append(key, value)
      })
    }

    Object.entries(searchParamsObj).forEach(([key, param]) => {
      if (!params.has(key)) {
        if (Array.isArray(param)) {
          param.forEach((value) => {
            if (value) params.append(key, value)
          })
        } else {
          if (param) params.append(key, param)
        }
      }
    })

    if (categoryData?.get('facetedSearchShowFacetsOnCategory')) {
      params.append('CategoryIDsWithoutSubCategories', categoryData.get('objectId'))
    }

    await api
      .get<Core.PageableProductResult>('/api/v2/products', { params })
      .then((res) => res.data)
      .then((data) => dispatch(setProductsForCategory(categoryId, data, sort, filters)))

    dispatch(setViewBusyState(false))
  }

export const ADD_PRODUCTS_FOR_CATEGORY = 'ADD_PRODUCTS_FOR_CATEGORY'
export type AddProductsForCategoryAction = {
  type: typeof ADD_PRODUCTS_FOR_CATEGORY
  payload: SetProductsForCategoryAction['payload']
}
export function addProductsForCategory(
  categoryId: string,
  productResult: Core.PageableProductResult,
  sort: string,
  filters: Core.Filter[] = [],
): AddProductsForCategoryAction {
  return {
    type: ADD_PRODUCTS_FOR_CATEGORY,
    payload: { categoryId, productResult, sort, filters },
  }
}

export const addProductsForCategoryAsync =
  (categoryId: string, query?: Query, filters: Core.Filter[] = []): GlobalAction =>
  async (dispatch, getState, api) => {
    const locale = getState().getIn(['shop', 'locale'])
    const sort = query?.sort || sortingDefaults.sort

    const sellingCountryId = getState().getIn(['shop', 'sellingCountryId']) || null
    const searchParamsObj = {
      ...sortingDefaults,
      ...pagingDefaults,
      ...query,
      categoryId,
      locale,
      sellingCountryId,
      // Axios is not able to merge plain objects with URLSearchParams.
      // So we have to pass the default params manually.
      ...api.defaults.params,
    } as { [key: string]: string | string[] }
    const productData = getState().getIn(['categoryProductData', categoryId])
    const categoryData = getState().getIn(['categories', categoryId])
    const facets: Core.Facets | undefined = productData?.facets

    const params = new URLSearchParams()

    if (facets) {
      getFilters(facets).forEach(({ key, value }) => {
        if (value) params.append(key, value)
      })
    }

    Object.entries(searchParamsObj).forEach(([key, param]) => {
      if (!params.has(key)) {
        if (Array.isArray(param)) {
          param.forEach((value) => {
            if (value) params.append(key, value)
          })
        } else {
          if (param) params.append(key, param)
        }
      }
    })

    if (categoryData?.get('facetedSearchShowFacetsOnCategory')) {
      params.append('CategoryIDsWithoutSubCategories', categoryData.get('objectId'))
    }

    await api
      .get<Core.PageableProductResult>('/api/v2/products', { params })
      .then((res) => res.data)
      .then((data) => dispatch(addProductsForCategory(categoryId, data, sort, filters)))
  }

export const SET_PRODUCTS_FOR_SEARCH = 'SET_PRODUCTS_FOR_SEARCH'
export type SetProductsForSearchAction = {
  type: typeof SET_PRODUCTS_FOR_SEARCH
  payload: { searchResult: Core.PageableSearchResult; sort: string; query: string; filters: SearchFacet[] }
}
export function setProductsForSearch(
  searchResult: Core.PageableSearchResult,
  sort: string,
  query: string,
  filters: SearchFacet[],
): SetProductsForSearchAction {
  return {
    type: SET_PRODUCTS_FOR_SEARCH,
    payload: { searchResult, sort, query, filters },
  }
}
export const ADD_PRODUCTS_FOR_SEARCH = 'ADD_PRODUCTS_FOR_SEARCH'
export type AddProductsForSearchAction = {
  type: typeof ADD_PRODUCTS_FOR_SEARCH
  payload: SetProductsForSearchAction['payload']
}
export function addProductsForSearch(
  searchResult: Core.PageableSearchResult,
  sort: string,
  query: string,
  filters: SearchFacet[],
): AddProductsForSearchAction {
  return {
    type: ADD_PRODUCTS_FOR_SEARCH,
    payload: { searchResult, sort, query, filters },
  }
}

export const SET_IS_IN_WISHLIST = 'SET_IS_IN_WISHLIST'
export type SetIsInWishlistAction = {
  type: typeof SET_IS_IN_WISHLIST
  payload: { productId: string; isInWishlist: boolean }
}
export function setIsInWishlist(productId: string, isInWishlist: boolean): SetIsInWishlistAction {
  return {
    type: SET_IS_IN_WISHLIST,
    payload: { productId, isInWishlist },
  }
}

type SearchFacet = Core.SelectionFilter | Core.RangeFilter
export const buildFilterArray = (query: { [key: string]: string | string[] }): SearchFacet[] => {
  const filters: SearchFacet[] = []
  Object.entries(query).forEach(([key, value]) => {
    // each filter should be one of 'CategoryID', 'Manufacturer, 'ListPrice', 'CategoryIDsWithoutSubCategories' or start with 'PreDefString_'
    if (defaultFacetIds.includes(key) || key.startsWith(customFacetIdPrefix)) {
      if (rangeFacetIds.includes(key)) {
        const [min, max] = (value as string).split(rangeFacetDelimiter)
        filters.push({ id: key, range: { min: parseInt(min), max: parseInt(max) } })
      } else if (Array.isArray(value)) {
        value.forEach((singleValue) => filters.push({ id: key, value: singleValue }))
      } else {
        filters.push({ id: key, value })
      }
    }
  })
  return filters
}

export function loadProductsForSearchAsync(appendProductData?: boolean): GlobalAction {
  return async (dispatch, getState, api) => {
    dispatch(setViewBusyState(true))

    const state = getState()
    const location = state.get('location')
    const locale = state.getIn(['shop', 'locale'])

    const sort = location.getIn(['query', 'sort'], getDefaultSorting())

    const query = location.get('query').toJS()
    const filters = buildFilterArray(query)

    const loadProducts = async (paging: Core.Pagination) =>
      await api
        .post<Core.PageableSearchResult>(
          '/api/v2/search',
          { filters, query: query.q, sort },
          { params: { ...paging, locale } },
        )
        .then((res) => res.data)

    if (appendProductData) {
      const page = parseInt(location.getIn(['query', 'page'], pagingDefaults.page))
      const productResult = await loadProducts({ ...pagingDefaults, page })

      dispatch(addProductsForSearch(productResult, sort, query.q, filters))
    } else {
      const queryPage = parseInt(location.getIn(['query', 'page'], pagingDefaults.page))

      // Fetching too many results per page at once from ePagesJ can lead to timeout errors.
      const maxResultsPerPage = 50
      let page = 1
      let resultsToFetch = queryPage * pagingDefaults.resultsPerPage
      const resultsPerPage = Math.min(resultsToFetch, maxResultsPerPage)
      while (resultsToFetch > 0) {
        const productResult = await loadProducts({ ...pagingDefaults, resultsPerPage, page })
        if (page === 1) dispatch(setProductsForSearch(productResult, sort, query.q, filters))
        else dispatch(addProductsForSearch(productResult, sort, query.q, filters))
        resultsToFetch -= maxResultsPerPage
        page++
      }
    }

    dispatch(setViewBusyState(false))
  }
}

type LoadProductsByIds = {
  type: typeof LOAD_PRODUCTS_BY_IDS
  payload: {
    productIds: string[]
    pluginId: string
  }
  response: Core.PageableProductResult
}
export const LOAD_PRODUCTS_BY_IDS = 'LOAD_PRODUCTS_BY_IDS'
export const LOAD_PRODUCTS_BY_IDS_SUCCESS = 'LOAD_PRODUCTS_BY_IDS_SUCCESS'
export const LOAD_PRODUCTS_BY_IDS_FAILURE = 'LOAD_PRODUCTS_BY_IDS_FAILURE'
export function loadProductsByIds(
  pluginId: string,
  productIds: string[],
  sellingCountryId: number | null,
  options?: ActionOptions,
): ApiAction<LoadProductsByIds> | ResolvedApiAction<LoadProductsByIds> {
  return productIds.length
    ? {
        type: LOAD_PRODUCTS_BY_IDS,
        idempotent: true,
        payload: { productIds, pluginId },
        callApi: (api, { productIds }, { locale }) =>
          api
            .get('/api/v2/products', {
              params: {
                productIds,
                locale,
                sellingCountryId,
                resultsPerPage: 50,
              },
            })
            .then((res) => res.data),
        options,
      }
    : {
        type: LOAD_PRODUCTS_BY_IDS_SUCCESS,
        productIds,
        pluginId,
        response: { products: [], totalNumberOfProducts: 0 },
      }
}

type LoadProductsForCrossSelling = {
  type: typeof LOAD_PRODUCTS_FOR_CROSSSELLING
  payload: {
    productId: string
    query: Core.Pagination
  }
  response: Core.CrossSellingResult
}
export const LOAD_PRODUCTS_FOR_CROSSSELLING = 'LOAD_PRODUCTS_FOR_CROSSSELLING'
export const LOAD_PRODUCTS_FOR_CROSSSELLING_SUCCESS = 'LOAD_PRODUCTS_FOR_CROSSSELLING_SUCCESS'
export const LOAD_PRODUCTS_FOR_CROSSSELLING_FAILURE = 'LOAD_PRODUCTS_FOR_CROSSSELLING_FAILURE'
export function loadProductsForCrossSelling(
  productId: string,
  { page = 1, size = 4 }: { page?: number; size?: number },
  sellingCountryId: number | null,
  options?: ActionOptions,
): ApiAction<LoadProductsForCrossSelling> {
  return {
    type: LOAD_PRODUCTS_FOR_CROSSSELLING,
    idempotent: true,
    payload: {
      productId,
      query: {
        ...sortingDefaults,
        resultsPerPage: size,
        page,
      },
    },
    callApi: (api, { productId, query }, { locale }) =>
      api
        .get(`/api/v2/products/${productId}/crossselling`, {
          params: {
            ...query,
            locale,
            sellingCountryId,
          },
        })
        .then((res) => res.data),
    options,
  }
}
