import type { PropsWithChildren, ReactElement } from 'react'
import { useEffect } from 'react'
import ReactModal from 'react-modal'

import SlideLayer from '../../SlideLayer'

type Props = {
  isOpen: boolean
  title: string
  onRequestClose: () => void
  hasFacets: boolean
}

export default function FacetedSearchSlideLayer({
  children,
  isOpen,
  onRequestClose,
  hasFacets,
  title,
}: Readonly<PropsWithChildren<Props>>): ReactElement | null {
  useEffect(() => {
    ReactModal.setAppElement('#app')
  }, [])

  return hasFacets ? (
    <SlideLayer
      className="slide-layer-faceted-search"
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeElement={<span className="slide-layer-header-close-icon" aria-hidden="true" />}
    >
      {children}
    </SlideLayer>
  ) : null
}
