import { Manager, Reference, usePopper } from 'react-popper'
import { createPortal } from 'react-dom'
import { useEffect, useId, useRef, useState } from 'react'
import cc from 'classcat'

import type { AspectRatioLabel } from './ImageEditor'
import { allowedMimeTypes } from '../templateComponents/Workspace/daliConfig'
import { getNormalizedZoomFactor } from './ImageEditor.helpers'
import { useWheelZoom } from './useWheelZoom'
import Popover from '../Popover'

export const maxZoomFactor = 5
export const zoomStep = 0.1

type ImageEditorMenuProps = Readonly<{
  t: TranslateProps['t']
  referenceElement: HTMLElement | null
  disabled?: boolean
  aspectRatio: { label: AspectRatioLabel; value?: number }
  aspectRatioOptions: Partial<Record<AspectRatioLabel, number>>
  zoomFactor: number
  onZoomFactorChange: (zoomFactor: number) => void
  onAspectRatioChange: (aspectRatio: { label: AspectRatioLabel; value: number }) => void
  onFileChange: (file: File) => void
  onCancel: () => void
  onSave: () => void
}>

/**
 * Image editor menu with image editing options. This component is used in the
 * image editor component to allow users to change the image, zoom level,
 * aspect ratio, and save or cancel the changes.
 *
 * @param t Scoped translation function
 * @param referenceElement The reference element to position the menu
 * @param disabled Whether the menu is disabled (e.g. during pending state)
 * @param aspectRatio The current image aspect ratio
 * @param aspectRatioOptions The available aspect ratio options to choose from
 * @param zoomFactor The current zoom factor
 * @param onZoomFactorChange Callback to handle changes to the zoom factor
 * @param onAspectRatioChange Callback to handle aspect ratio selection
 * @param onFileChange Callback to handle changes to the image file
 * @param onCancel Callback to handle canceling the editing
 * @param onSave Callback to handle saving the changes
 */
export function ImageEditorMenu({
  t,
  referenceElement,
  disabled,
  aspectRatio,
  aspectRatioOptions,
  zoomFactor,
  onZoomFactorChange,
  onAspectRatioChange,
  onFileChange,
  onCancel,
  onSave,
}: ImageEditorMenuProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const aspectRatioMenuTarget = useRef<HTMLElement | null>(null)
  const [isAspectRatioMenuOpen, setIsAspectRatioMenuOpen] = useState(false)

  const [zoomSlider, setZoomSlider] = useState<HTMLInputElement | null>(null)
  useWheelZoom(zoomSlider, onZoomFactorChange, !disabled)

  const [popperElement, setPopperElement] = useState<HTMLFieldSetElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 30],
        },
      },
      {
        name: 'flip',
        enabled: false,
      },
    ],
  })

  function handleZoom(value: number) {
    onZoomFactorChange(getNormalizedZoomFactor(value))
  }

  function closeAspectRatioMenu() {
    setIsAspectRatioMenuOpen(false)
  }

  function toggleAspectRatioMenu(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    setIsAspectRatioMenuOpen((isOpen) => !isOpen)
  }

  function handleMouseDownOutsideAspectRatioMenu(event: MouseEvent) {
    if (aspectRatioMenuTarget.current && !aspectRatioMenuTarget.current.contains(event.target as Node)) {
      closeAspectRatioMenu()
    }
  }

  function setAspectRatioMenuTargetRef(node: HTMLElement) {
    if (node) aspectRatioMenuTarget.current = node
  }

  return createPortal(
    <fieldset
      data-ep-editor
      className="ep-image-editor-menu"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      disabled={disabled}
    >
      <legend className="visually-hidden">{t('imageEditOptions.accessibilityLabel')}</legend>
      <div className="ep-image-editor-menu-range-input">
        <button
          title={t('imageZoomOutButton.tooltip')}
          type="button"
          onClick={() => handleZoom(zoomFactor - zoomStep)}
          disabled={zoomFactor === 1}
        ></button>
        <label>
          <span className="visually-hidden">{t('imageZoomLevelSlider.accessibilityLabel')}</span>
          <input
            ref={setZoomSlider}
            type="range"
            min={1}
            max={maxZoomFactor}
            style={{ '--min': 1, '--max': maxZoomFactor, '--val': zoomFactor }}
            step={zoomStep}
            value={zoomFactor}
            onChange={(event) => handleZoom(parseFloat(event.target.value))}
          />
        </label>
        <button
          title={t('imageZoomInButton.tooltip')}
          type="button"
          onClick={() => handleZoom(zoomFactor + zoomStep)}
          disabled={zoomFactor === maxZoomFactor}
        ></button>
      </div>
      <div className="ep-image-editor-menu-seperator"></div>
      <div>
        <button
          title={t('changeImageButton.tooltip')}
          aria-label={t('changeImageButton.tooltip')}
          type="button"
          className="ep-image-button-change"
          onClick={() => fileInputRef.current?.click()}
        ></button>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          accept={allowedMimeTypes.join(',')}
          onChange={(event) => {
            const file = event.target.files?.[0]
            if (file) {
              onFileChange(file)
              // Unset the value to force that the "change" event is triggered
              // even if the same file is selected again. The selected file is
              // processed with the "onFileChange" callback, so the value is
              // not needed to be kept in the input field.
              event.target.value = ''
            }
          }}
        />
      </div>
      <Manager>
        <div>
          {isAspectRatioMenuOpen && (
            <Popover
              placement="top"
              offsetSkidding={20}
              offsetDistance={30}
              isResizable={false}
              onEscapeKeyDown={closeAspectRatioMenu}
              onOutsideMouseDown={handleMouseDownOutsideAspectRatioMenu}
              onEnterKeyDown={closeAspectRatioMenu}
              withoutPortal
            >
              <AspectRatioMenu
                t={t}
                aspectRatio={aspectRatio}
                aspectRatioOptions={aspectRatioOptions}
                onAspectRatioChange={onAspectRatioChange}
              />
            </Popover>
          )}
          <Reference innerRef={setAspectRatioMenuTargetRef}>
            {({ ref }) => (
              <button
                ref={ref}
                title={t('aspectRatioButton.tooltip')}
                aria-label={t('aspectRatioButton.tooltip')}
                type="button"
                className={cc(['ep-image-button-aspect-ratio', isAspectRatioMenuOpen && 'active'])}
                onClick={toggleAspectRatioMenu}
              ></button>
            )}
          </Reference>
        </div>
      </Manager>
      <div>
        <button type="button" className="ep-modal-button-cancel" onClick={onCancel}>
          {t('cancelButton.label')}
        </button>
        <button type="button" className="ep-modal-button-save" onClick={onSave}>
          {t('saveButton.label')}
        </button>
      </div>
    </fieldset>,
    document.body,
  )
}

type AspectRatioMenuProps = Readonly<{
  aspectRatio: { label: AspectRatioLabel; value?: number }
  aspectRatioOptions: Partial<Record<AspectRatioLabel, number>>
  onAspectRatioChange: (aspectRatio: { label: AspectRatioLabel; value: number }) => void
}> &
  TranslateProps

function AspectRatioMenu({ t, aspectRatio, aspectRatioOptions, onAspectRatioChange }: AspectRatioMenuProps) {
  const optionsArray = Object.entries(aspectRatioOptions) as [AspectRatioLabel, number][]
  const htmlInputId = useId()
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    focusRef.current?.focus()
  }, [])

  return (
    <fieldset role="radiogroup" className="ep-image-editor-menu-aspect-ratio">
      <legend className="visually-hidden">{t('aspectRatioMenu.accessibilityLabel')}</legend>
      {optionsArray.map(([label, value]) => (
        <div key={label}>
          <input
            type="radio"
            name={`image-editor-${htmlInputId}-aspectRatio`}
            id={`image-editor-${htmlInputId}-aspectRatio-${label}`}
            value={value}
            // In some cases two options can have the same value, so we need to check the label as well.
            // E.g. '1:1' and 'circle' both have an aspect ratio of 1, and 'original' could also happen to match
            // with any other suggested aspect ratio.
            checked={value === aspectRatio.value && label === aspectRatio.label}
            onChange={(event) => {
              onAspectRatioChange({ label, value: parseFloat(event.target.value) })
            }}
            ref={value === aspectRatio.value && label === aspectRatio.label ? focusRef : null}
          />
          <label htmlFor={`image-editor-${htmlInputId}-aspectRatio-${label}`}>
            <span></span>
            {label.includes(':') ? label : t(`aspectRatioMenu.aspectRatioOptions.${label}.label`)}
          </label>
        </div>
      ))}
    </fieldset>
  )
}
