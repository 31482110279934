import { fromJS } from 'immutable'

import * as actions from '../actions'
import { extendPageWithUrl } from '../../urlGenerators'

export default function pages(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.SET_PAGE: {
      return state
        .setIn(['pages', action.payload.pageSlug], extendPageWithUrl(fromJS(action.payload.page)))
        .setIn(['view', 'activePageSlug'], action.payload.pageSlug)
        .setIn(['shop', 'csrfToken'], action.payload.csrfToken)
    }
    case actions.UPDATE_PAGE_CONTENT:
      return state.setIn(['pages', action.pageSlug, 'content'], fromJS(action.content))

    case actions.UPDATE_PAGE_SETTINGS_SUCCESS:
      return state.deleteIn(['pages', action.oldPageSlug]).setIn(['pages', action.newPageSlug], fromJS(action.response))

    case actions.LOAD_LEGAL_PAGE_SUCCESS:
      return state.setIn(['legalPagesContents', action.response.slug], extendPageWithUrl(fromJS(action.response)))
    case actions.UPDATE_LEGAL_PAGE_CONTENT:
      return state.setIn(['legalPagesContents', action.legalPageSlug, 'content'], fromJS(action.content))

    default:
      return state
  }
}
