import { useDispatch } from 'react-redux'
import cc from 'classcat'

import { switchLanguage } from '../../../store/actions'
import Dropdown from './Dropdown'
import translate from '../../../utils/translate'
import useLanguageSwitchLocales from '../../../utils/hooks/useLanguageSwitchLocales'

export function HeaderLanguageSwitch({ t }: Readonly<TranslateProps>) {
  const dispatch = useDispatch<GlobalDispatch>()
  const [locales, shopLocale, languageSelectTranslations] = useLanguageSwitchLocales()

  // Always show the language code as dropdown button label, except for the Norwegian Bokmål language in Norway.
  const dropdownButtonLabel =
    shopLocale === 'nb_NO' || shopLocale === 'nb-NO'
      ? shopLocale.substring(3)
      : shopLocale.substring(0, 2).toUpperCase()

  if (locales.length <= 1) return null

  return (
    <div className="header-language">
      <Dropdown
        ariaId="language-dropdown"
        ariaLabel={t('languageSwitchButton.accessibilityLabel')}
        buttonLabel={dropdownButtonLabel}
      >
        {locales.map((locale) => (
          <li key={locale.identifier}>
            <button
              className={cc({ selected: locale.identifier === shopLocale })}
              onClick={() => dispatch(switchLanguage(locale.identifier))}
            >
              {languageSelectTranslations.get(locale.language)}
            </button>
          </li>
        ))}
      </Dropdown>
      <span className="header-language-divider" aria-hidden="true"></span>
    </div>
  )
}

export default translate('components.headerLanguageSwitchComponent')(HeaderLanguageSwitch)
