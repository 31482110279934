import type { DependencyList, EffectCallback } from 'react'
import { useEffect, useRef } from 'react'

export default function useEffectExceptMount(effect: EffectCallback, deps: DependencyList): void {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) isInitialMount.current = false
    else return effect()
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}
