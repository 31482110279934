export type CategorySearchActionTypes =
  | SelectCategorySearchFacetValueAction
  | SetCategorySearchFacetRangeAction
  | ResetAllCategoryFiltersAction

export const SELECT_CATEGORY_SEARCH_FACET_VALUE = 'SELECT_CATEGORY_SEARCH_FACET_VALUE'
type SelectCategorySearchFacetValueAction = {
  type: typeof SELECT_CATEGORY_SEARCH_FACET_VALUE
  payload: {
    facetId: string
    valueId: string
    categoryId: string
    selected: boolean
  }
}
export function selectCategorySearchFacetValue(
  facetId: string,
  valueId: string,
  categoryId: string,
  selected: boolean,
): SelectCategorySearchFacetValueAction {
  return {
    type: SELECT_CATEGORY_SEARCH_FACET_VALUE,
    payload: {
      facetId,
      valueId,
      categoryId,
      selected,
    },
  }
}

export const SET_CATEGORY_SEARCH_FACET_RANGE = 'SET_CATEGORY_SEARCH_FACET_RANGE'
type SetCategorySearchFacetRangeAction = {
  type: typeof SET_CATEGORY_SEARCH_FACET_RANGE
  payload: {
    facetId: string
    categoryId: string
    range: {
      min: number
      max: number
    }
  }
}
export function setCategorySearchFacetRange(
  facetId: string,
  categoryId: string,
  range: { min: number; max: number },
): SetCategorySearchFacetRangeAction {
  return {
    type: SET_CATEGORY_SEARCH_FACET_RANGE,
    payload: {
      facetId,
      categoryId,
      range,
    },
  }
}

export const RESET_ALL_CATEGORY_FILTERS = 'RESET_ALL_CATEGORY_FILTERS'
type ResetAllCategoryFiltersAction = {
  type: typeof RESET_ALL_CATEGORY_FILTERS
  payload: {
    categoryId: string
  }
}
export function resetAllCategoryFilters(categoryId: string): ResetAllCategoryFiltersAction {
  return {
    type: RESET_ALL_CATEGORY_FILTERS,
    payload: {
      categoryId,
    },
  }
}
