import type { ReactElement } from 'react'

import Link from '../../templateComponents/Link'

type FooterPage = {
  id: string
  href: string
  title: string
  isVisible: boolean
}

type Props = {
  footerPages: FooterPage[]
}

export default function FooterPages({ footerPages = [] }: Readonly<Props>): ReactElement {
  return (
    <ul className="pages">
      {footerPages
        .filter((p) => p.isVisible)
        .map((page) => (
          <li className="pages-item" key={page.id}>
            <Link to={page.href} className="pages-item-link">
              {page.title}
            </Link>
          </li>
        ))}
    </ul>
  )
}
