import { List } from 'immutable'

/* CAUTION: "trees" are actually Iterables with a `children` property that in turn holds a "tree" */

// reduceDeep doesn't have a third parameter (or, to be precise, it is always an empty List),
// since that wouldn't make sense on deeper levels
export function reduceDeep(nodes, action) {
  function recursion(children) {
    return children.reduce(
      (prevNodes, node) => action(prevNodes, node.set('children', recursion(node.get('children')))),
      new List(),
    )
  }

  return recursion(nodes)
}

export function mapDeep(nodes, action, stopCondition = false) {
  function recursion(parent, children) {
    return stopCondition
      ? children
      : children
          .map((node) => action(node, parent))
          .map((node) => node.set('children', recursion(node, node.get('children'))))
  }

  return recursion(undefined, nodes)
}

export function filterDeep(nodes, predicate) {
  function recursion(parent, children) {
    return children
      .filter((node) => predicate(node, parent))
      .map((node) => node.set('children', recursion(node, node.get('children'))))
  }

  return recursion(undefined, nodes)
}

export function findDeep(nodes, predicate) {
  if (!nodes) {
    return undefined
  }

  let foundNode = nodes.find((node) => predicate(node))
  if (foundNode) {
    return foundNode
  }

  nodes.forEach((node) => {
    const possibility = findDeep(node.get('children'), predicate)
    if (possibility) {
      foundNode = possibility
    }
  })

  if (foundNode) {
    return foundNode
  }

  return undefined
}
