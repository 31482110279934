import buildURL from '../../utils/buildURL'

export type Sorting = Pick<Core.Pagination, 'sort'>
export type Paging = Pick<Core.Pagination, 'page' | 'resultsPerPage'>

export const POSITION_ASC = { positionAscending: 'position-asc' }
export const PRICE_ASC = { priceAscending: 'price-asc' }
export const PRICE_DESC = { priceDescending: 'price-desc' }
export const RELEVANCE = { relevance: 'relevance' }
export const NAME_ASC = { nameAscending: 'name-asc' }
export const NAME_DESC = { nameDescending: 'name-desc' }
export const NEWEST_DESC = { newestDescending: 'newest-desc' }
export const ON_SALE = { onSale: 'onSale' }
export const OUT_OF_STOCK = { outOfStock: 'outOfStock' }

export type AvailableSortings = typeof POSITION_ASC &
  typeof PRICE_ASC &
  typeof PRICE_DESC &
  typeof NAME_ASC &
  typeof NAME_DESC &
  typeof RELEVANCE

export const getDefaultSorting = (): string => 'relevance'

export const sortingDefaults: Required<Sorting> = { sort: 'position-asc' }
export const pagingDefaults: Required<Paging> = {
  resultsPerPage: 12,
  page: 1,
}

const ListPriceFacetId = 'ListPrice'
export const rangeFacetIds = [ListPriceFacetId]
export const rangeFacetDelimiter = ';'

export const defaultFacetIds = [
  'CategoryID',
  'Manufacturer',
  ListPriceFacetId,
  'CategoryIDsWithoutSubCategories',
  'IsNew',
]
export const customFacetIdPrefix = 'PreDefString_'

type CategorySorting = {
  label: string
  active: boolean
  url: string
}

export const createCategorySortings = (
  options: Core.ProductRequestQuery & { pageUrl: string },
  availableSortings: Partial<AvailableSortings>,
): CategorySorting[] => {
  const { pageUrl, page, resultsPerPage, sort, q } = options

  return Object.keys(availableSortings).map((type) => ({
    label: type,
    url: buildURL(pageUrl, {
      sort: availableSortings[type],
      resultsPerPage,
      page: page || pagingDefaults.page,
      q,
    }),
    active: availableSortings[type] === sort,
  }))
}

type SortMap = {
  label: string
  sorting: string
  active: boolean
}

export const createSortings = (
  sort: string,
  availableSortings: Partial<AvailableSortings> = {
    ...RELEVANCE,
    ...NAME_ASC,
    ...NAME_DESC,
    ...PRICE_ASC,
    ...PRICE_DESC,
  },
): SortMap[] =>
  Object.keys(availableSortings).map((type) => ({
    label: type,
    sorting: availableSortings[type],
    active: availableSortings[type] === sort,
  }))
