import type { ReactElement } from 'react'

type Props = {
  error: {
    message: string
    statusCode: number
    requestId: string | null
  }
} & TranslateProps

export default function ErrorComponent({ error, t }: Readonly<Props>): ReactElement {
  return (
    <div className="template-error">
      <div>{error.statusCode}</div>
      {error.requestId && (
        <small>
          {t('views.storefrontView.errorPageSection.idExplanation')} <code>{error.requestId}</code>
        </small>
      )}
    </div>
  )
}
