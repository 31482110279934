import loadable from '@loadable/component'

export const Cart = loadable(() => import('./Cart'))
export const FacetedSearch = loadable(() => import('./FacetedSearch'))
export const Product = loadable(() => import('./Product'))

export { default as Search } from './Search'
export { default as Category } from './Category'
export { default as Home } from './Home'
export { default as Layout } from './Layout'
