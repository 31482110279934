import type { CSSProperties, ReactElement } from 'react'
import type { InViewHookResponse } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import cc from 'classcat'

import { PriceStructuredData, getProductMicrodata } from '../themeComponents/epages.base/ProductPrice'
import { imageUrl } from '../../components/ThemeView'
import EnergyLabel from './EnergyLabel'
import LazyImage from '../LazyImage'
import Link from './Link'
import Percentage from './Percentage'
import PlaceholderImage from '../PlaceholderImage'
import translate from '../../utils/translate'
import withI18n from '../withI18n'

type Props = {
  product: Frontend.Product
  productIndex: number
  trackProductClick: (product: Frontend.Product, index: number) => void
  withImageAspectRatio?: boolean
  withMicrodata?: boolean
  inViewRef?: InViewHookResponse['ref']
} & TranslateProps

export function ProductListItemRaw({
  t,
  product,
  productIndex,
  trackProductClick,
  withImageAspectRatio = false,
  withMicrodata = true,
  inViewRef = () => undefined,
}: Readonly<Props>): ReactElement {
  const containerStyle = {
    '--aspect-ratio': withImageAspectRatio && product.image ? product.image.width / product.image.height : null,
  } as CSSProperties

  const hashedThemeAssets = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'hashedThemeAssets']))
  const placeholderImageSrc = `/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
    'epages.base/assets/images',
    'placeholder_540.jpg',
  ])}`

  const singleProductBulkPrice = product.bulkPrices?.find((bulkPrice) => bulkPrice.quantity.amount === 1)

  return (
    <div ref={inViewRef} className="product-item" {...(withMicrodata ? getProductMicrodata(product) : {})}>
      {withMicrodata && product.sku && <meta itemProp="sku" content={product.sku} />}
      {withMicrodata && product.gtin && <meta itemProp={`gtin${product.gtin.length}`} content={product.gtin} />}
      {withMicrodata && (
        <meta
          itemProp="image"
          content={product.image ? imageUrl(product.image.url, { width: 600, height: 2560 }) : placeholderImageSrc}
        />
      )}
      <Link
        to={product.href}
        itemProp={withMicrodata ? 'url' : undefined}
        className="product-item-link"
        onClick={() => trackProductClick && trackProductClick(product, productIndex)}
      >
        <div className="product-item-image-container" style={containerStyle}>
          {product.image ? (
            <LazyImage
              className="product-item-image"
              src={product.image.url}
              width={product.image.width}
              height={product.image.height}
              alt={product.name}
            />
          ) : (
            <PlaceholderImage className="product-item-image" />
          )}
          {product.labels.length > 0 ? (
            <div className="product-item-label-wrapper">
              {product.labels.map((label) => (
                <span key={label} className={`product-item-label product-item-label-${label.toLowerCase()}`}>
                  {t(`components.productComponent.imageLabels.${label}`)}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <h2 className="product-item-title" itemProp={withMicrodata ? 'name' : undefined}>
          {product.name}
        </h2>
      </Link>
      <div className="product-item-info-box">
        {product.energyLabel && (
          <EnergyLabel t={t} energyLabel={product.energyLabel} energyLabelSourceFile={product.energyLabelSourceFile} />
        )}
        {product.productDataSheet && (
          <a href={product.productDataSheet.dataUri} target="_blank" rel="noreferrer" className="product-data-sheet">
            {product.productDataSheet.label}
          </a>
        )}
        {(product.price || product.lowestPrice) && (
          <div
            className={cc([
              'product-item-price',
              { 'no-stock-level': !product.hasStockLevel, 'has-variations': product.hasVariations },
            ])}
          >
            {withMicrodata && <PriceStructuredData product={product} />}
            {product.basePrice && (
              <span className="product-item-price-reference">
                (
                {singleProductBulkPrice?.basePrice?.formatted ??
                  product.lowestRefPriceFormatted ??
                  product.basePrice.formatted}
                )
              </span>
            )}
            {product.strikePrice && !product.hasVariations && (
              <div className="product-item-price-old-container">
                {product.isStrikePriceRRP && (
                  <span className="product-item-price-manufacturer">
                    {t('components.productComponent.priceManufacturer')}
                  </span>
                )}
                <h3 className="product-item-price-old">{product.strikePrice.formatted}</h3>
                <Percentage
                  oldPrice={product.strikePrice.amount}
                  newPrice={singleProductBulkPrice?.price.amount ?? product.price?.amount ?? 0}
                  className="product-item-price-old-percentage"
                />
              </div>
            )}
            {!product.lowestPrice && (
              <h3 className="product-item-price-new">
                {singleProductBulkPrice?.price.formatted ?? product.price?.formatted}
              </h3>
            )}
            {product.lowestPrice && (
              <h3 className="product-item-price-new">
                {t('components.productComponent.variationLowestPrice', {
                  amount: product.lowestPrice.formatted,
                })}
              </h3>
            )}
          </div>
        )}
        {product.hasVariations && (
          <div className="product-item-availability">
            <Link to={product.href} className="product-item-availability-variations">
              {t('components.productComponent.variationsAvailable')}
            </Link>
          </div>
        )}
        {product.stockLevelClass && (
          <div className="product-item-availability">
            {product.availabilityText.includes('NOT_AVAILABLE') ? (
              <span className="product-item-availability-info">
                {t('components.productComponent.availability.notAvailableNote')}
              </span>
            ) : (
              <>
                <span className={`product-item-availability-icon ${product.stockLevelClass}-stock-icon`} />
                <span className="product-item-availability-info">{t(product.availabilityText)}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default withI18n('shop')(translate()(ProductListItemRaw))
