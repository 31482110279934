import type { RouterState } from 'react-router'
import Immutable from 'immutable'

import Theme from '../components/Theme'

function UnknownRoute() {
  const notFoundError = Immutable.Map({ statusCode: 404 })
  return <Theme withLayout error={notFoundError} currentView="notFound" />
}

UnknownRoute.meta = (_props: RouterState, storeState: State, t: TranslateProps['t']): View.Meta[] => {
  const title = t('views.storefrontView.errorPageSection.errorcode', { code: 404 })

  return [
    {
      title: `${title} - ${storeState.getIn(['shop', 'title'])}`,
    },
  ]
}

export default UnknownRoute
