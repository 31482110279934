import type { ButtonHTMLAttributes, ReactElement } from 'react'
import cc from 'classcat'

import focusDocumentBody from '../utils/focusDocumentBody'
import translate from '../utils/translate'

type ScrollToTopButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & TranslateProps

export function ScrollToTopButtonRaw({ t, className, ...htmlProps }: ScrollToTopButtonProps): ReactElement {
  return (
    <button {...htmlProps} className={cc(['scroll-to-top-button', className])} onClick={handleButtonClick}>
      {/* Unsetting transform so the button is positioned correctly in the Beyond preview. */}
      <style>{`.body { transform: unset; }`}</style>
      <span className="scroll-to-top-button-text">{t('label')}</span>
      <i className="icon" aria-hidden="true" />
    </button>
  )
}

function handleButtonClick() {
  window.scrollTo({ top: 0, behavior: 'smooth' })

  // Focus the body element to reset the tab order.
  focusDocumentBody()
}

/**
 * Scrolls to the top of the document and focuses the body element.
 */
export default translate('components.backToTopButton')(ScrollToTopButtonRaw)
