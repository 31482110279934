import { Component } from 'react'
import type { MouseEvent } from 'react'
import cc from 'classcat'

import Link from '../../templateComponents/Link'

function loopNodeList(nodeList: HTMLCollection, fn: (item: HTMLElement) => void) {
  for (let i = 0, l = nodeList.length; i < l; i++) {
    fn(nodeList.item(i) as HTMLElement)
  }
}

function repositionSubMenus(
  item: HTMLElement,
  navigationRulerRect: DOMRect,
  level = 0,
  flowDirection: 'right' | 'left' = 'right',
) {
  if (item.classList.contains('main-menu') || item.classList.contains('sub-menu')) {
    if (level <= 1) {
      // handling the first layer of sub menus
      switch (flowDirection) {
        case 'right': {
          // default position to flow right
          item.style.right = ''
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.right <= navigationRulerRect.right) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          } else {
            item.style.right = '0'
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          }

          break
        }
        case 'left': {
          // default position to flow left
          item.style.right = '0'
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.left >= navigationRulerRect.left) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          } else {
            item.style.right = ''
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          }

          break
        }
        default:
          throw new Error(`Unsupported flow direction ${flowDirection}`)
      }
    } else {
      // handling all other layers of sub menus
      switch (flowDirection) {
        case 'right': {
          // default position to flow right
          item.style.left = ''
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.right <= navigationRulerRect.right) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          } else {
            item.style.left = `${-item.getBoundingClientRect().width}px`
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          }

          break
        }
        case 'left': {
          // default position to flow left
          item.style.left = `${-item.getBoundingClientRect().width}px`
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.left >= navigationRulerRect.left) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          } else {
            item.style.left = ''
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          }

          break
        }
        default:
          throw new Error(`Unsupported flow direction ${flowDirection}`)
      }
    }
  } else {
    loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level, flowDirection))
  }
}

function extendItems(
  items: Frontend.NestedPage[],
  active: Frontend.NestedPage | null,
  opened: Frontend.NestedPage[],
): Frontend.ExtendedNestedPage[] {
  return items.reduce<Frontend.ExtendedNestedPage[]>((acc, item) => {
    return acc.concat([
      Object.assign({}, item, {
        active: Boolean(active && active.id === item.id),
        opened: Boolean(opened.find((i) => i.id === item.id)),
        children: extendItems(item.children, active, opened),
      }),
    ])
  }, [])
}

function traceOpened(items: Frontend.NestedPage[], active: Frontend.NestedPage): Frontend.NestedPage[] | null {
  return items.reduce((trace, item) => {
    if (item.id === active.id) {
      return [item]
    }

    const subTrace = traceOpened(item.children, active)
    if (subTrace) {
      return [item].concat(subTrace)
    }

    return trace || null
  }, null)
}

type NestedMenuProps = Readonly<{
  className?: string
  items: Frontend.NestedPage[]
}>

type NestedMenuState = {
  active: Frontend.NestedPage | null
  opened: Frontend.NestedPage[]
  menuOpen: boolean
}

export default class NestedMenu extends Component<NestedMenuProps, NestedMenuState> {
  private currentLocation: string
  private element: HTMLDivElement
  private rulerElement: HTMLDivElement

  state: NestedMenuState = {
    active: null,
    opened: [],
    menuOpen: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleUpdate)
    this.handleUpdate()

    this.currentLocation = window.location.href
  }

  componentDidUpdate(prevProps: NestedMenuProps) {
    // close menu on changing the adress
    if (this.currentLocation !== window.location.href) {
      this.currentLocation = window.location.href
      this.resetState()
    }

    // Update display of sub menus when items changed.
    // E.g. on initial lazy loading of items in the storefront, or when
    // adding/removing/changing pages or their nesting level in the editor.
    if (this.props.items !== prevProps.items) {
      window.requestAnimationFrame(() => this.handleUpdate())
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleUpdate)
  }

  resetState() {
    this.setState({
      active: null,
      opened: [],
      menuOpen: false,
    })
  }

  handleUpdate = () => {
    const mainMenu = this.element && this.element.querySelector<HTMLElement>('.main-menu')
    const nestedMenuRuler = this.rulerElement

    if (mainMenu && nestedMenuRuler) {
      repositionSubMenus(mainMenu, nestedMenuRuler.getBoundingClientRect())
    }
  }

  handleMouseLeave = () => {
    this.resetState()
  }

  handleItemMouseEnter = (item: Frontend.NestedPage) => {
    this.activateItem(item)
  }

  handleItemMouseLeave = (item: Frontend.NestedPage) => {
    this.deactivateItem(item)
  }

  handleItemClick = (event: MouseEvent, item: Frontend.NestedPage) => {
    const isActiveItem = this.state.active && this.state.active.id === item.id

    if (isActiveItem || !item.children.length) {
      this.resetState()
    } else {
      event.preventDefault()
      event.stopPropagation()

      this.activateItem(item)
    }
  }

  handleItemToggleClick = (event: MouseEvent<HTMLSpanElement>, item: Frontend.NestedPage) => {
    event.preventDefault()

    // prevent closing the whole mobile menu by closing a sub menu entry
    event.stopPropagation()

    if (!this.state.opened.find((i: Frontend.NestedPage) => i.id === item.id)) {
      this.activateItem(item)
    } else {
      this.deactivateItem(item)
    }
  }

  activateItem(item: Frontend.NestedPage) {
    const opened = traceOpened(this.props.items, item) || []

    this.setState({
      active: item,
      opened,
      menuOpen: true,
    })
  }

  deactivateItem(item: Frontend.NestedPage) {
    const opened = traceOpened(this.props.items, item) || []

    this.setState({
      active: opened[opened.length - 2] || null,
      opened: opened.slice(0, opened.length - 1),
    })
  }

  renderLayer(items: Frontend.ExtendedNestedPage[], level: number) {
    return (
      <ul className={cc([{ 'main-menu': level === 0, 'sub-menu': level > 0 }])}>
        {items.map((item) => {
          const hasSubMenu = item.children.length > 0

          return (
            <li
              key={item.id}
              className={cc({
                active: item.opened,
                'navigation-active': item.isInBreadcrumb,
              })}
              onMouseEnter={() => this.handleItemMouseEnter(item)}
              onMouseLeave={() => this.handleItemMouseLeave(item)}
            >
              <Link to={item.href} onClick={(e) => this.handleItemClick(e, item)}>
                <span>{item.title}</span>

                {hasSubMenu && (
                  <span
                    className={cc([
                      {
                        opened: item.opened,
                        'nested-sub-menu': level >= 1,
                        'main-menu-nested': level === 0,
                      },
                    ])}
                    onClick={(e) => this.handleItemToggleClick(e, item)}
                  />
                )}
              </Link>

              {hasSubMenu ? this.renderLayer(item.children, level + 1) : null}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    return (
      <div
        ref={(node: HTMLDivElement) => (this.element = node)}
        className={cc(['nested-menu', this.props.className, { open: this.state.menuOpen }])}
        onMouseLeave={this.handleMouseLeave}
      >
        <div ref={(node: HTMLDivElement) => (this.rulerElement = node)} className="nested-menu-ruler" />
        <div id="main-menu-nested" className={cc(['main-menu-wrapper', { show: this.state.menuOpen }])}>
          {this.renderLayer(extendItems(this.props.items, this.state.active, this.state.opened), 0)}
        </div>
      </div>
    )
  }
}
