import type { ReactElement } from 'react'

type Props = {
  socialNetworks: {
    show: boolean
    pinterestUrlSelected: boolean
    instagramUrlSelected: boolean
    twitterUrlSelected: boolean
    facebookUrlSelected: boolean
    youtubeUrlSelected: boolean
    whatsappUrlSelected: boolean
    tiktokUrlSelected: boolean
    linkedinUrlSelected: boolean
    pinterestUrl: string
    instagramUrl: string
    twitterUrl: string
    facebookUrl: string
    youtubeUrl: string
    whatsappUrl: string
    tiktokUrl: string
    linkedinUrl: string
  }
}

export default function SocialMedia({ socialNetworks }: Readonly<Props>): ReactElement | null {
  if (!socialNetworks.show) return null
  return (
    <ul className="social-media">
      {socialNetworks.facebookUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.facebookUrl || '/'}
            target={socialNetworks.facebookUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <span className="social-media-facebook" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.twitterUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.twitterUrl || '/'}
            target={socialNetworks.twitterUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="X (Twitter)"
          >
            <span className="social-media-x" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.pinterestUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.pinterestUrl || '/'}
            target={socialNetworks.pinterestUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="Pinterest"
          >
            <span className="social-media-pinterest" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.instagramUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.instagramUrl || '/'}
            target={socialNetworks.instagramUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <span className="social-media-instagram" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.youtubeUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.youtubeUrl || '/'}
            target={socialNetworks.youtubeUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <span className="social-media-youtube" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.whatsappUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.whatsappUrl || '/'}
            target={socialNetworks.whatsappUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="WhatsApp"
          >
            <span className="social-media-whatsapp" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.tiktokUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.tiktokUrl || '/'}
            target={socialNetworks.tiktokUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="TikTok"
          >
            <span className="social-media-tiktok" aria-hidden="true" />
          </a>
        </li>
      )}
      {socialNetworks.linkedinUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.linkedinUrl || '/'}
            target={socialNetworks.linkedinUrl && '_blank'}
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <span className="social-media-linkedin" aria-hidden="true" />
          </a>
        </li>
      )}
    </ul>
  )
}
