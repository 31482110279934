import flow from 'lodash/fp/flow'
import mapValues from 'lodash/mapValues'
import set from 'lodash/set'
import setFp from 'lodash/fp/set'

import type * as actions from '../actions'
import type { ProductDataType } from '../../components/templateComponents/Workspace/plugins/category/CategoryPlugin'
import { getPlain } from '../utils'

export default function categorySearch(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case 'SELECT_CATEGORY_SEARCH_FACET_VALUE': {
      const { facetId, valueId, categoryId, selected } = action.payload
      const oldData = getPlain<ProductDataType>(state.getIn(['categoryProductData', categoryId]))

      const newData = {
        ...oldData,
        facets: oldData?.facets
          ? Object.entries(oldData.facets).reduce<Core.Facets>((facets, [id, facet]) => {
              return {
                ...facets,
                [id]: facetId === id ? setFp(['values', valueId, 'selected'], selected)(facet) : facet,
              }
            }, {})
          : undefined,
      }

      return state.setIn(['categoryProductData', categoryId], newData)
    }
    case 'SET_CATEGORY_SEARCH_FACET_RANGE': {
      const { facetId, categoryId, range } = action.payload
      const oldData = getPlain<ProductDataType>(state.getIn(['categoryProductData', categoryId]))

      const newData = {
        ...oldData,
        facets: oldData?.facets
          ? Object.entries(oldData.facets).reduce<Core.Facets>((facets, [id, facet]) => {
              const oldRange = facet.values?.range as Core.FacetRange

              return {
                ...facets,
                [id]:
                  facetId === id
                    ? ({
                        ...facet,
                        values: {
                          unit: facet.values?.unit,
                          range: oldRange,
                          selection: range,
                          selected: range.min !== oldRange.min || range.max !== oldRange.max,
                        },
                      } as Core.RangeFacet)
                    : facet,
              }
            }, {})
          : undefined,
      }

      return state.setIn(['categoryProductData', categoryId], newData)
    }
    case 'RESET_ALL_CATEGORY_FILTERS': {
      const { categoryId } = action.payload
      const oldData = getPlain<ProductDataType>(state.getIn(['categoryProductData', categoryId]))

      const newData = {
        ...oldData,
        facets: oldData?.facets
          ? Object.entries(oldData.facets).reduce<Core.Facets>((facets, [id, facet]) => {
              if (facet.type === 'selection') {
                return {
                  ...facets,
                  [id]: set(
                    facet,
                    'values',
                    mapValues(facet.values, (value) => set(value, 'selected', false)),
                  ),
                }
              }

              if (facet.type === 'range') {
                const values = facet.values
                return {
                  ...facets,
                  [id]: values
                    ? set(
                        facet,
                        'values',
                        flow<[typeof values], typeof values, typeof values>(
                          setFp('selected', false),
                          setFp('selection', values.range),
                        )(values),
                      )
                    : facet,
                }
              }

              return { ...facets, [id]: facet }
            }, {})
          : undefined,
      }

      return state.setIn(['categoryProductData', categoryId], newData)
    }
    default:
      return state
  }
}
