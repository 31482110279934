import { Component } from 'react'
import { ReactReduxContext } from 'react-redux'
import hoistStatics from 'hoist-non-react-statics'

export default function withReduxContext(InjectedComponent) {
  class WrapperComponent extends Component {
    render() {
      return (
        <ReactReduxContext.Consumer>
          {(context) => <InjectedComponent {...this.props} store={context.store} />}
        </ReactReduxContext.Consumer>
      )
    }
  }

  return hoistStatics(WrapperComponent, InjectedComponent)
}
