import type { ComponentPropsWithoutRef } from 'react'
import { memo } from 'react'
import cc from 'classcat'

/**
 * Wrapper for the standard <img> element, adding automatic lazy loading and
 * responsive capabilities (i.e. automatically set the "sizes" attribute).
 *
 * Usage examples:
 *  <LazyImage src="/epages-storage/image/source.jpg" />
 *  <LazyImage src="1.jpg" srcSet="1.jpg 980w, 2.jpg 1200w" sizes="auto" />
 *
 * When the value of "src" is a epages-storage URL without width or height
 * parameters, the image will be automatically loaded in a proper responsive
 * size using lazysizes‘ RIaS plugin (Responsive Images as a Service). You can
 * override this behavior by setting a custom "sizes" and/or "srcSet" prop
 * value, in which case those take precendence.
 *
 * - Use `sizes="auto"` to have the RIaS plugin automatically set the <img>
 *   "sizes" attribute value (so that the browser can pick the best suitable
 *   image source from the provided <img> "srcset" attribute value).
 *   Not needed when you use a epages-storage "src".
 * - Use `nofallback` to omit the noscript fallback.
 * - The "alt" prop defaults to an empty string for accessibility (screen
 *   readers may read out the image URL when omitted).
 */
export default memo(LazyImage)

// https://github.com/ePages-de/epages-storage/blob/v0.10.12/src/universal/conf/application.conf#L61
const maxStorageDimension = 2560

interface LazyImage extends ComponentPropsWithoutRef<'img'> {
  src: string
  nofallback?: boolean
  innerRef?: React.Ref<HTMLImageElement>
}

function LazyImage({
  src,
  srcSet,
  sizes,
  alt = '',
  className,
  nofallback,
  innerRef,
  ...otherProps
}: Readonly<LazyImage>) {
  const hasStorageSrc = /\/(api\/core-)?storage\/images\/.+[?&](hash|remote)=/.test(src)
  const hasSizeConstraint = hasStorageSrc && /.+[?&](width|height)=/.test(src)

  // The lazysizes RIaS plugin requires `data-sizes="auto"`.
  // If "sizes" is used with any other value, or "srcset" is used, pass these
  // props as standard HTML <img> attributes and don’t use the plugin.
  const useRiasPlugin = hasStorageSrc && !hasSizeConstraint && !srcSet && (!sizes || sizes === 'auto')

  // "width"/"height" needs to be constrained to the epages-storage maximum dimension.
  const dataSrc = useRiasPlugin ? `${src}&width={width}&height=${maxStorageDimension}` : src

  // <noscript> is used as a fallback for clients where JavaScript is not available.
  //
  // It is also needed for structured data, so that crawlers are able to infer an image
  // URL when itemProp="image" is part of `otherProps`:
  // - lazysizes <img> will not have the "src" HTML attribute, which is needed for
  //   itemProp="image" on <img>.
  // - Note that it will **only** work when also rendered server-side, because React
  //   doesn’t include <noscript> content when rendered on the client.
  const fallbackSrc = hasStorageSrc && !hasSizeConstraint ? `${src}&width=600&height=${maxStorageDimension}` : src

  return (
    <>
      <img
        className={cc(['lazyload', className])}
        sizes={sizes !== 'auto' ? sizes : undefined}
        data-src={dataSrc}
        data-srcset={srcSet}
        data-sizes={sizes === 'auto' || useRiasPlugin ? 'auto' : null}
        alt={alt}
        decoding="async"
        ref={innerRef}
        {...otherProps}
        // Suppress hydration warning because some prop values will unavoidably
        // be different between the server and the client. lazysizes will change
        // these attributes on the client.
        suppressHydrationWarning
      />
      {!nofallback && (
        <noscript>
          <img className={className} src={fallbackSrc} alt={alt} ref={innerRef} {...otherProps} />
        </noscript>
      )}
    </>
  )
}
