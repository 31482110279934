import { useEffect } from 'react'

const className = 'ep-editor-spotlight'

/**
 * Draws attention to an element in the editor by visually highlighting it and
 * presenting the rest of the page as a modal-like backdrop. All elements
 * except the spotlight element and all its ancestors are set to "inert".
 *
 * Note that because ancestor elements are not set to "inert", they still can
 * be interacted with. For example, it allows for the spotlight to be placed in
 * a container that can be scrolled.
 *
 * _Non-semantic variant of the EditorSpotlight component_.
 */
export function useEditorSpotlight(element: HTMLElement | null) {
  useEffect(() => {
    const appElement = document.getElementById('app')

    if (appElement && element) {
      appElement.classList.add(`${className}-enabled`)
      element.classList.add(className)
      const inertElements = setInert(appElement, element)

      return () => {
        appElement.classList.remove(`${className}-enabled`)
        element.classList.remove(className)
        removeInert(inertElements)
      }
    }
  }, [element])
}

function setInert(startingElement: Element, activeElement: HTMLElement, inertElements: HTMLElement[] = []) {
  const elements = startingElement.children
  for (const element of elements) {
    if (element !== activeElement && element.contains(activeElement)) {
      setInert(element, activeElement, inertElements)
    } else if (element !== activeElement && element instanceof HTMLElement) {
      element.inert = true
      inertElements.push(element)
    }
  }
  return inertElements
}

function removeInert(inertElements: HTMLElement[]) {
  for (const element of inertElements) {
    element.inert = false
  }
}
