import { bool, func, number, string } from 'prop-types'
import { formField } from '@epages/react-components'
import { forwardRef, useRef, useState } from 'react'
import { mapContains } from 'react-immutable-proptypes'
import { useDispatch, useSelector } from 'react-redux'
import Immutable from 'immutable'
import cc from 'classcat'
import noop from 'lodash/noop'

import { allowedMimeTypes, isValidMimeType } from '../../daliConfig'
import { setViewBusyState } from '../../../../../store/actions'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

function createPreview(file) {
  return new Promise(function (resolve) {
    const reader = new FileReader()

    reader.onload = (upload) => resolve(upload.target.result)
    reader.readAsDataURL(file)
  })
}

export function getErrorReasonMessage(error, t, baseKey) {
  const { response } = error

  const tBaseKey = baseKey || 'components.imageUploadComponent.imageField.errorMessages.reason'
  if (error.__CANCEL__) return t(`${tBaseKey}.requestCancelled`)

  if (!response) return t(`${tBaseKey}.unknown`)

  switch (response.status) {
    case 413:
      if (response?.data?.message?.startsWith('Animated image has too many frames.')) {
        return t(`${tBaseKey}.gifHasTooManyFrames`)
      }
      if (response?.data?.message?.startsWith('Uploaded image resolution across all frames is too big.')) {
        return t(`${tBaseKey}.imageResolutionTooBig`)
      }
      return t(`${tBaseKey}.requestEntityTooLarge`)
    case 415:
      return t(`${tBaseKey}.unsupportedMediaType`)
    default:
      return t(`${tBaseKey}.unknown`)
  }
}

// Utility function used to prepare "imageData" (`{src,width,height}`) for
// usage with react-components `Form` via the parent component.
export function withImageData(data) {
  return data.set(
    'imageData',
    Immutable.Map({
      src: data.get('src'),
      width: data.get('width'),
      height: data.get('height'),
    }),
  )
}

// Utility function used to merge "imageData" (`{src,width,height}`) into the
// flat image object that is saved to the database via the parent component.
export function withMergedImageData(data) {
  return data.merge(data.get('imageData'))
}

const ImageFieldRaw = forwardRef((props, _ref) => {
  const [preview, setPreview] = useState(null)
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()
  const recommendedImageDimensions = useSelector((state) => state.getIn(['themeMeta', 'recommendedImageDimensions']))

  const fileInput = useRef(null)

  const onUpload = (file) => {
    const { onError = noop, onUploadPreview } = props

    createPreview(file)
      .then((preview) => {
        onUploadPreview?.(preview)
        dispatch(setViewBusyState(true))
        setUploading(true)
        setPreview(preview)
      })
      .then(() => props.storeFile(file))
      .then(
        (response) => {
          props.onChange(
            Immutable.Map({
              src: response.absoluteDownloadUrl,
              width: response.width,
              height: response.height,
            }),
          )

          setUploading(false)
          setPreview(undefined)
          dispatch(setViewBusyState(false))
          onError(null)
          return null
        },
        (err) => {
          const { value, t } = props
          const reason = getErrorReasonMessage(err, t)
          const message = t(
            `components.imageUploadComponent.imageField.errorMessages.${
              value.get('src') ? 'messageWithOldImage' : 'messageWithoutOldImage'
            }`,
            { reason },
          )
          const imageFieldError = new Error(message)
          imageFieldError.code = err.response?.status

          onError(imageFieldError)
          dispatch(setViewBusyState(false))
          setUploading(false)
          setPreview(undefined)
        },
      )
      .catch((err) => console.warn(err)) // eslint-disable-line no-console
  }

  const onDragOver = (event) => {
    const file = event.dataTransfer?.items?.[0]
    const isFirefox = event.dataTransfer?.items === undefined
    const isPlugin = window.dali.globalDragData

    // Files from outside of the browser are always allowed to be dropped and the browser will open them.
    // To prevent this behavior we prevent the default in all cases and do not allow unsupported files with
    // `dropEffect = 'none'`.
    // For firefox we need to set the dropEffect to copy in order to be able to drop at all.
    // Meaning we cannot check the file type on dragover in FF but we know if s/o's trying to drop one of our plugins.
    event.preventDefault()
    event.dataTransfer.dropEffect = (isFirefox && !isPlugin) || (file && !isValidMimeType(file)) ? 'copy' : 'none'
  }

  const onDrop = (event) => {
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0]

    event.preventDefault()
    onUpload(file)
  }

  const { onChange, value, showDeleteButton = false, renderCropButton, t } = props

  const renderSpinner = () => {
    return (
      <div className="dali-plugin-image-busy-indicator">
        <span className="dali-plugin-image-spinner" />
        <img
          src={preview}
          alt=""
          draggable="false"
          style={{ aspectRatio: props.editAspectRatio, objectFit: 'cover' }}
        />
      </div>
    )
  }

  const renderEmpty = () => {
    const { t, withImageInfo, withMultipleImages, className } = props
    const recommendedImageWidth = recommendedImageDimensions.get('contentImageWidth')

    return (
      <div className={cc(['dali-plugin-image-default', className])}>
        <span className="dali-plugin-image-placeholder" />
        <div className="dali-plugin-image-button-wrapper">
          <div className="dali-plugin-image-info">
            {withImageInfo
              ? t('components.imageUploadComponent.imageInfo', { recommendedImageWidth })
              : t(`components.imageUploadComponent.explanation${withMultipleImages ? '_other' : '_one'}`)}
          </div>
          <button
            type="button"
            onClick={() => fileInput.current.click()}
            className="dali-button dali-plugin-image-button-upload"
          >
            <span>
              {t(`components.imageUploadComponent.addImageButton.label${withMultipleImages ? '_other' : '_one'}`)}
            </span>
          </button>
        </div>
      </div>
    )
  }

  const renderImage = () => {
    const { value, imageUrl, previewUrl } = props
    const imgSrc = imageUrl ? imageUrl(value.get('src')) : value.get('src')
    const previewSrc = previewUrl ? previewUrl(imgSrc) : imgSrc

    return <img src={previewSrc} alt="" draggable="false" />
  }

  return (
    <div onDragOver={onDragOver} onDrop={onDrop}>
      {uploading && renderSpinner()}
      {!uploading && !value.get('src') && renderEmpty()}
      {!uploading && value.get('src') && (
        <div className="dali-plugin-image-preview">
          {renderImage()}
          <div className="dali-plugin-image-button-wrapper">
            {showDeleteButton && (
              <button
                className="dali-plugin-image-button-delete"
                title={t('components.imageUploadComponent.deleteImageButton.label')}
                onClick={() => onChange(Immutable.Map())}
                type="button"
              />
            )}
            {renderCropButton && renderCropButton()}
            <button
              onClick={() => fileInput.current.click()}
              className="dali-plugin-image-button-change"
              title={t('components.imageUploadComponent.changeImageButton.label')}
              type="button"
            />
          </div>
        </div>
      )}

      <input
        ref={fileInput}
        type="file"
        accept={allowedMimeTypes.join(',')}
        onChange={(event) => onUpload(event.target.files[0])}
        value=""
      />
    </div>
  )
})

ImageFieldRaw.displayName = 'ImageFieldRaw'

ImageFieldRaw.propTypes = {
  name: string.isRequired,
  value: mapContains({
    src: string,
    width: number,
    height: number,
  }).isRequired,
  editAspectRatio: number,
  onChange: func.isRequired,
  onUploadPreview: func,
  storeFile: func.isRequired,
  className: string,
  t: func.isRequired,
  imageUrl: func,
  previewUrl: func,
  onError: func,
  showDeleteButton: bool,
  renderCropButton: func,
  withImageInfo: bool,
  withMultipleImages: bool,
}

export default compose(withI18n('interface'), translate(), formField())(ImageFieldRaw)
