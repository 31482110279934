import { Component } from 'react'
import { bool } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'

import { PageOrCategoryContext } from './Category'
import { loadLegalPage } from '../store/actions'
import CookieConsent from '../components/CookieConsent'
import Theme from '../components/Theme'

class LegalPage extends Component {
  static propTypes = {
    isEditor: bool.isRequired,
    viewError: map,
    shop: map,
    page: map,
  }

  static storeUpdate = (props, state) => {
    const isForwardNavigation = props.location.action === 'PUSH'
    const updates = []
    if (isForwardNavigation || !state.getIn(['legalPagesContents', props.params.legalPageSlug])) {
      updates.push(loadLegalPage(props.params.legalPageSlug))
    }
    return updates
  }

  static meta = (props, state, t) => {
    const page = state.get('legalPagesContents').get(props.params.legalPageSlug)
    if (!page) return []

    const isBeyondShop = Boolean(state.getIn(['shop', 'beyond']))
    const title = isBeyondShop ? t(page.get('title')) : page.get('title')

    return [
      {
        title: `${title} - ${state.getIn(['shop', 'title'])}`,
      },
      {
        name: 'robots',
        content: 'noindex, follow',
      },
    ]
  }

  static contentCreationDisabled = () => true

  render() {
    const { shop, page, viewError, isEditor } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="legal" />
    if (!page) return null

    const cookieNoticeSettings = shop.getIn(['userSettings', 'cookieNotice'])
    const showCookieConsentPreview = Boolean(
      cookieNoticeSettings && cookieNoticeSettings.get('active') && page.get('slug') === 'cookiepolicy',
    )

    return (
      <PageOrCategoryContext.Provider value={this.props.page}>
        <Theme withLayout currentView="legal">
          {(renderView, props) => (
            <>
              {renderView('Legal', {
                ...props,
                page: page.toJS(),
              })}
              {showCookieConsentPreview && (
                <section className="section">
                  <CookieConsent inline isEditor={isEditor} />
                </section>
              )}
            </>
          )}
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default connect((state, props) => ({
  shop: state.get('shop'),
  viewError: state.getIn(['view', 'error']),
  navigation: state.get('navigation'),
  breadcrumb: state.get('breadcrumb'),
  page: state.get('legalPagesContents').get(props.params.legalPageSlug),
  isEditor: Boolean(state.getIn(['view', 'editorMode'])),
}))(LegalPage)
