import type { ReactElement } from 'react'

import SearchForm from './SearchForm'

export default function NoResults({ t }: TranslateProps): ReactElement {
  return (
    <div className="search-no-result">
      <div className="search-no-result-form">
        <h2 className="search-no-result-form-headline">
          {t('components.productSearchComponent.resultsCard.noResultsState.title')}
        </h2>
        <SearchForm className="search-form" namespace="content" maxSuggestedItems={5} />
      </div>
      <div className="search-no-result-hint">
        <h3>{t('components.productSearchComponent.resultsCard.noResultsState.hintSection.title')}</h3>
        <ul>
          <li>{t('components.productSearchComponent.resultsCard.noResultsState.hintSection.proposalOne')}</li>
          <li>{t('components.productSearchComponent.resultsCard.noResultsState.hintSection.proposalTwo')}</li>
        </ul>
      </div>
    </div>
  )
}
