import type { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie' // used on the client only

import { getPlain } from '../store/utils'
import { setSellingCountry } from '../store/actions'

type Props = {
  children: (
    shopMboCountries: Core.SellingCountry[],
    setSellingCountryAndCookie: (country: number | null) => void,
    shopSellingCountry: number | null,
  ) => ReactElement
}

function CountrySelection({ children }: Readonly<Props>): ReactElement | null {
  const featureEnabled = useSelector<State, boolean>(
    // Enable feature only in NOW shops when the setting is set to true
    (state) => !state.getIn(['shop', 'beyond']) && state.getIn(['tax', 'taxationByDestinationCountry']),
  )

  const shopSellingCountryId = useSelector<State, number | null>((state) =>
    state.getIn(['shop', 'sellingCountryId'], null),
  )
  const shopMboCountries = useSelector<State, Core.SellingCountry[]>((state) =>
    getPlain(state.getIn(['shop', 'mboCountries'], [])),
  )
  const dispatch = useDispatch<GlobalDispatch>()

  // Hide feature in beyond shops and when the feature is disabled in the MBO
  // Also hide country selection when no countries are in the store
  if (!featureEnabled || shopMboCountries.length === 0) return null

  const setSellingCountryAndCookie = (countryId: number) => {
    Cookies.set('epCountrySelection', `${countryId}`, { expires: 365 * 2, sameSite: 'lax' })
    dispatch(setSellingCountry(countryId))
    window.location.reload()
  }

  return children(shopMboCountries, setSellingCountryAndCookie, shopSellingCountryId)
}

export default CountrySelection
