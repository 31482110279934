import { useCallback, useEffect, useRef, useState } from 'react'

import { getNormalizedFile } from './ImageEditor.helpers'
import checkSupportedImageMediaType from '../../utils/checkSupportedImageMediaType'

// Maximum limits imposed by the ePages storage service:
// - Maximum file size of 20 MB
// - Maximum resolution of 50 MPx
const MAX_FILE_SIZE = 2e7
const MAX_RESOLUTION = 5e7

/**
 * Gets an HTML image element and its corresponding file object from an input
 * file. The file object is normalized to have the correct media type and
 * filename extension. The image element is loaded with the file’s data URL.
 *
 * @returns An array containing the image element, the normalized file object,
 * and a function to load the image from an input file.
 * @throws {ImageNotSupportedError} If the image is not supported.
 */
export function useImage(onLoad?: (image: HTMLImageElement, file: File) => void) {
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)

  const onLoadCallback = useRef(onLoad)
  useEffect(() => {
    onLoadCallback.current = onLoad
  }, [onLoad])

  const loadImage = useCallback(async (file: File) => {
    // Get the real media type of the image file.
    const mediaType = await checkSupportedImageMediaType(file)

    if (!mediaType) {
      throw new ImageNotSupportedError('unsupportedMediaType')
    }

    // Get the file with the correct media type and filename extension.
    const newFile = getNormalizedFile(file, mediaType)

    if (newFile.size > MAX_FILE_SIZE) {
      throw new ImageNotSupportedError('fileSizeTooLarge')
    }

    const newImage = await new Promise<HTMLImageElement>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.src = reader.result as string
        image.onload = () => resolve(image)
        image.onerror = () => reject(new ImageNotSupportedError('unknown'))
      }
      reader.readAsDataURL(newFile)
    })

    const resolutionMPx = newImage.width * newImage.height
    if (resolutionMPx > MAX_RESOLUTION) {
      throw new ImageNotSupportedError('imageResolutionTooBig')
    }

    setImage(newImage)
    setImageFile(newFile)

    onLoadCallback.current?.(newImage, newFile)
  }, [])

  return [image, imageFile, loadImage] as const
}

class ImageNotSupportedError extends Error {
  name: 'ImageNotSupported'
  message: 'fileSizeTooLarge' | 'unsupportedMediaType' | 'imageResolutionTooBig' | 'unknown'

  constructor(message: 'fileSizeTooLarge' | 'unsupportedMediaType' | 'imageResolutionTooBig' | 'unknown') {
    super(message)
    this.name = 'ImageNotSupported'
  }
}
