import type { Content } from 'fusion-backend-ts/src/api/models/content'
import { List } from 'immutable'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import striptags from 'striptags'

import { PageOrCategoryContext } from '../../../views/Category'
import { updateCategoryContent, updateLegalPageContent, updatePageContent } from '../../../store/actions'
import { useApi } from '../../ApiProvider'
import Dali from './Dali'
import daliConfig from './daliConfig'

export default function Workspace() {
  const api = useApi()
  const dispatch = useDispatch()

  const pageOrCategory = useContext(PageOrCategoryContext)
  const mboBaseUrl: string = useSelector((state: State) => state.getIn(['shop', 'mboBaseUrl']))

  const shopSlug: string = useSelector((state: State) => state.getIn(['shop', 'slug']))
  const editorMode: string = useSelector((state: State) => state.getIn(['view', 'editorMode']))
  const blocks: List<Core.Block> = pageOrCategory.getIn(['content', 'blocks']) || List()

  const handleBlocksChange = (newBlocks) => {
    const oldContent: ImmutableMap = pageOrCategory.get('content')
    const newContent: ImmutableMap = oldContent.set('blocks', newBlocks)

    switch (pageOrCategory.get('type')) {
      case 'blank':
        dispatch(updatePageContent(pageOrCategory.get('slug'), newContent))
        break
      case 'legal':
        dispatch(updateLegalPageContent(pageOrCategory.get('slug'), newContent))
        break
      case 'category':
        dispatch(updateCategoryContent(pageOrCategory.get('guid'), newContent))
    }

    if (hasAnyTextBeenWritten(oldContent.toJS(), newContent.toJS())) {
      window.parent?.postMessage(
        {
          type: 'CHECKLIST',
          payload: {
            regularShop: 'designSettings.textCustomization',
            websiteOnly: 'designSettings.textCustomization',
          },
        },
        mboBaseUrl,
      )
    }
  }

  return (
    <Dali
      config={daliConfig(editorMode, api, shopSlug)}
      editorView={editorMode}
      blocks={blocks}
      pageSlug={pageOrCategory.get('slug')}
      onBlocksChange={handleBlocksChange}
    />
  )
}

// see utils/translate.ts in fusion-backend-ts
function hasAnyTextBeenWritten(
  oldContent: Core.Content | Content['content'],
  newContent: Core.Content | Content['content'],
): boolean {
  const oldTexts = extractTextsFromContent(oldContent)
  const newTexts = extractTextsFromContent(newContent)

  // If there are more new source texts than existing source texts, then there
  // are definitely new texts.
  let hasTextChanges = newTexts.length > oldTexts.length

  if (!hasTextChanges) {
    // Texts might have been added, changed or removed. Or just the order changed.

    for (const [index, text] of newTexts.entries()) {
      if (text === oldTexts[index]) continue

      const oldIndex = oldTexts.findIndex((existingText) => existingText === text)
      if (oldIndex > -1) continue

      // If the text was not found, then it is a new or changed text.
      // In this case, we can end the loop.
      hasTextChanges = true
      break
    }
  }

  return hasTextChanges
}

function extractTextsFromContent(contentData: Core.Content | Content['content']) {
  const texts: string[] = []

  contentData?.blocks?.forEach((block) => {
    if (block.data.headline) extractTextsFromPlugin(block.data.headline, texts)
    switch (block.type) {
      case 'multi-column':
      case 'headline-block':
        block.data.columns.forEach((plugin) => {
          extractTextsFromPlugin(plugin as Core.Plugin | Core.Block, texts)
        })
        break
      case 'image-with-text':
      case 'icon-with-text':
      case 'video-with-text':
        block.data.columns.forEach((column) => {
          Object.values(column).forEach((plugin) => {
            extractTextsFromPlugin(plugin as Core.Plugin, texts)
          })
        })
        break
    }
  })
  contentData?.elements?.forEach((plugin) => {
    extractTextsFromPlugin(plugin, texts)
  })

  return texts.filter(Boolean)
}

function extractTextsFromPlugin(element: Core.Plugin, texts: string[]) {
  switch (element.type) {
    case 'epages.text':
      texts.push(striptags(element.data.content))
      break
    case 'epages.image':
      texts.push(element.data.alt)
      texts.push(element.data.text)
      texts.push(element.data.buttontext)
      texts.push(element.data.headline)
      break
    case 'epages.contentslider':
      element.data.images.forEach((image: Record<string, string>) => {
        texts.push(image.alt)
        texts.push(image.text)
        texts.push(image.buttontext)
        texts.push(image.headline)
      })
      break
    case 'epages.imagegallery':
      element.data.images.forEach((image: Record<string, string>) => {
        texts.push(image.alt)
      })
      break
    case 'nested-headline-block':
      extractTextsFromPlugin((element as Core.HeadlineBlock).data.headline, texts)
      ;(element as Core.HeadlineBlock).data.columns.forEach((plugin) => {
        extractTextsFromPlugin(plugin, texts)
      })
      break
    case 'epages.html':
      texts.push(striptags(element.data.html))
      break
    case 'epages.expander':
      element.data.entries.forEach((entry: Record<string, string>) => {
        texts.push(entry.title)
        texts.push(striptags(entry.detailsHtml))
      })
      break
  }
}
