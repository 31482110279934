import { useInView } from 'react-intersection-observer'

/**
 * This allows you to attach a hook that will call the given
 * `loadMoreFunction` when it detects that the user has gone past the `X`
 * element. The `X` is determined by the `pageSize` and
 * `currentlyShownEntries` together with the `threshold`.
 *
 * For example, using the following values:
 * `pageSize = 12, currentlyShownEntries = 24, threshold = 4`,
 * the `loadMoreFunction` would be called when the user scrolled past
 * the item corresponding to the 20th index.
 *
 * In addition, this hook also features a logic where, if the user
 * scrolls too quickly and past the triggering element (the 20th index
 * in the example above), the user will be scrolled back to the
 * triggering element. This is made under the assumption that there will
 * come new elements after the triggering element.
 *
 * @param pageSize
 * @param currentlyShownEntries
 * @param loadMoreFunction
 * @param threshold
 * @returns
 */
export default function useLazyLoadMore(
  pageSize: number,
  currentlyShownEntries: number,
  loadMoreFunction: () => void,
  threshold = 4,
) {
  const [inViewRef] = useInView({
    triggerOnce: true,
    onChange: (inView: boolean, entry: IntersectionObserverEntry) => {
      if (inView) return loadMoreFunction()

      // In case the lazy loaded products appear while the user has
      // already scrolled past them, scroll her back up, so she can see
      // them, but also to not miss additional lazy loading.
      const rect = entry.target.getBoundingClientRect()
      const userHasGonePastEntry = rect.top < 0
      if (userHasGonePastEntry) entry.target.scrollIntoView({ behavior: 'smooth' })
    },
  })

  return function (index: number) {
    // starting from page 2, the ref is always added to the nth last element, defined by the threshold
    if (index > pageSize && index === currentlyShownEntries - threshold) return inViewRef
    return null
  }
}
