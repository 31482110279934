import type { ReactElement } from 'react'

import CategoryContent from '../../templateComponents/CategoryContent'
import CategoryPageSettingsLayer from '../../templateComponents/CategoryPageSettingsLayer'
import Content from '../../templateComponents/Workspace'

export default function Home({
  t,
  isEditorMode,
  productData,
  category,
  sortingOptions,
  pageSize,
}: HomeThemeComponentProps): ReactElement {
  return (
    <div className="home">
      <section className="section-dali">
        <Content />
      </section>

      <section className="section">
        <div className="wrapper">
          {isEditorMode && <CategoryPageSettingsLayer t={t} />}
          <div className="content">
            <CategoryContent
              productData={productData}
              pageSize={pageSize}
              t={t}
              sortingOptions={sortingOptions}
              categoryData={category}
              hideTopbar
            />
          </div>
        </div>
      </section>
    </div>
  )
}
