import type { Action } from 'redux'

export type CategoriesActionTypes =
  | ResolvedApiAction<ListCategories>
  | ResolvedApiAction<LoadCategory>
  | ResolvedApiAction<UpdateCategorySettings>
  | ResolvedApiAction<UpdateCategoryContent>
  | ReturnType<typeof previewCategorySettings>

type ListCategories = {
  type: typeof LIST_CATEGORIES
  response: Core.CategoryEntry[]
}
export const LIST_CATEGORIES = 'LIST_CATEGORIES'
export const LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS'
export const LIST_CATEGORIES_FAILURE = 'LIST_CATEGORIES_FAILURE'
export function listCategories(options?: ActionOptions): ApiAction<ListCategories> {
  return {
    type: LIST_CATEGORIES,
    idempotent: true,
    callApi: (api, _payload, { locale }) =>
      api.get(`/api/v2/categories`, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type LoadCategory = {
  type: typeof LOAD_CATEGORY
  payload: {
    categoryId: string
  }
  response: {
    category: Core.Page
    csrfToken: string
  }
}
export const LOAD_CATEGORY = 'LOAD_CATEGORY'
export const LOAD_CATEGORY_SUCCESS = 'LOAD_CATEGORY_SUCCESS'
export const LOAD_CATEGORY_FAILURE = 'LOAD_CATEGORY_FAILURE'
export function loadCategory(categoryId: string, options?: ActionOptions): ApiAction<LoadCategory> {
  return {
    type: LOAD_CATEGORY,
    idempotent: true,
    payload: {
      categoryId,
    },
    callApi: async (api, { categoryId }, { locale }) => {
      const { data, headers } = await api.get(`/api/v2/categories/${categoryId}`, { params: { locale } })

      return { category: data, csrfToken: headers['x-csrf-token'] }
    },
    options,
  }
}

type PreviewCategorySettingsAction = Action<typeof PREVIEW_CATEGORY_SETTINGS> & {
  settings: Core.Page['settings']
  categoryId: string
}
export const PREVIEW_CATEGORY_SETTINGS = 'PREVIEW_CATEGORY_SETTINGS'
export function previewCategorySettings(
  settings: Core.Page['settings'],
  categoryId: string,
): PreviewCategorySettingsAction {
  return {
    type: PREVIEW_CATEGORY_SETTINGS,
    settings,
    categoryId,
  }
}

type UpdateCategorySettings = {
  type: typeof UPDATE_CATEGORY_SETTINGS
  payload: Pick<Core.Page, 'title' | 'isVisible' | 'slug'> &
    Partial<Pick<Core.Page, 'titleTag' | 'metaDescription' | 'settings'>> & {
      categoryId: string
      objectId: number
      visibleInNavigation: boolean
      facetedSearchCategoryStartDepth: number
      facetedSearchShowFacetsOnCategory: boolean
    }
  response: Core.Page
}
export const UPDATE_CATEGORY_SETTINGS = 'UPDATE_CATEGORY_SETTINGS'
export const UPDATE_CATEGORY_SETTINGS_SUCCESS = 'UPDATE_CATEGORY_SETTINGS_SUCCESS'
export const UPDATE_CATEGORY_SETTINGS_FAILURE = 'UPDATE_CATEGORY_SETTINGS_FAILURE'
export function updateCategorySettings(
  payload: UpdateCategorySettings['payload'],
  options?: ActionOptions,
): ApiAction<UpdateCategorySettings> {
  return {
    type: UPDATE_CATEGORY_SETTINGS,
    idempotent: false,
    payload,
    memorize: (state) => ({
      navigation: state.get('navigation'),
      categorySlugsToGUIDs: state.get('categorySlugsToGUIDs'),
    }),
    callApi: (
      api,
      {
        categoryId,
        title,
        titleTag,
        metaDescription,
        isVisible,
        settings,
        objectId,
        visibleInNavigation,
        facetedSearchCategoryStartDepth,
        facetedSearchShowFacetsOnCategory,
      },
      { locale },
    ) =>
      api
        .patch(
          `/api/v2/categories/${categoryId}`,
          {
            title,
            titleTag,
            metaDescription,
            isVisible,
            objectId,
            visibleInNavigation,
            facetedSearchCategoryStartDepth,
            facetedSearchShowFacetsOnCategory,
            settings,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type UpdateCategoryContent = {
  type: typeof UPDATE_CATEGORY_CONTENT
  payload: {
    categoryId: string
    content: ImmutableMap
  }
  response: Core.Page
}
export const UPDATE_CATEGORY_CONTENT = 'UPDATE_CATEGORY_CONTENT'
export const UPDATE_CATEGORY_CONTENT_SUCCESS = 'UPDATE_CATEGORY_CONTENT_SUCCESS'
export const UPDATE_CATEGORY_CONTENT_FAILURE = 'UPDATE_CATEGORY_CONTENT_FAILURE'
export function updateCategoryContent(
  categoryId: string,
  content: ImmutableMap,
  options?: ActionOptions,
): ApiAction<UpdateCategoryContent> {
  return {
    type: UPDATE_CATEGORY_CONTENT,
    idempotent: false,
    payload: {
      categoryId,
      content,
    },
    callApi: (api, { categoryId, content }, { locale }) =>
      api
        .patch(
          `/api/v2/categories/${categoryId}`,
          {
            content: content.toJS(),
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}
