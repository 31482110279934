// Resets the focus flow for keyboard navigation, just like it would be with a
// full page load navigation. *Note* that this function should only be called
// when absolutely necessary. It is currently used in "SuggestedSearch" on
// page navigation and in "BackToTopButton" on button click.
//
// For "SuggestedSearch", this will also make sure to blur the search input,
// so that the soft keyboard on mobile devices is closed and the search area
// collapsed in some themes.
export default function focusDocumentBody() {
  const isFocusable = document.body.hasAttribute('tabindex')
  if (!isFocusable) document.body.setAttribute('tabindex', '-1')
  document.body.focus()
  if (!isFocusable) document.body.removeAttribute('tabindex')
}
