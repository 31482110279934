import type { PropsWithChildren, ReactElement } from 'react'
import { useState } from 'react'
import cc from 'classcat'

type Props = {
  facetName: string
  facetType: 'range' | 'selection'
}

export default function SearchFilterFacet({
  facetName,
  facetType,
  children,
}: Readonly<PropsWithChildren<Props>>): ReactElement {
  const [isActive, setIsActive] = useState(true)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  return (
    <div>
      <button aria-expanded={isActive} className="faceted-search-facet-title" onClick={handleClick} tabIndex={0}>
        <div>{facetName}</div>
        <div>
          <span
            className={cc([
              'faceted-search-facet-title-icon',
              {
                'faceted-search-facet-title-icon-up': isActive,
                'faceted-search-facet-title-icon-down': !isActive,
              },
            ])}
            aria-hidden="true"
          />
        </div>
      </button>
      <div
        className={cc([
          'faceted-search-facet-container',
          {
            'faceted-search-facet-show': isActive,
            'faceted-search-price-range': facetType === 'range',
            'faceted-search-facet-hide': !isActive,
          },
        ])}
      >
        {children}
      </div>
    </div>
  )
}
