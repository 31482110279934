type Props = Readonly<{
  headerAnnouncementHtml: string
}>

export default function HeaderAnnouncementBar({ headerAnnouncementHtml }: Props) {
  return (
    <section className="header-announcement-bar">
      <div
        className="header-announcement-bar-wrapper"
        dangerouslySetInnerHTML={{ __html: headerAnnouncementHtml }}
      ></div>
    </section>
  )
}
