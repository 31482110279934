import type { ReactElement } from 'react'

import CategoryContent from '../../templateComponents/CategoryContent'
import CategoryPageSettingsLayer from '../../templateComponents/CategoryPageSettingsLayer'
import CategorySearch from '../../templateComponents/FacetedSearch/CategorySearch'
import Content from '../../templateComponents/Workspace'
import ToolbarTop from '../../templateComponents/ToolbarTop'

export default function Category({
  t,
  breadcrumb,
  category,
  productData,
  sortingOptions,
  pageSize,
  onScrollIntoView,
  isEditorMode,
  isBusy,
}: CategoryThemeComponentProps): ReactElement {
  return (
    <div className="category">
      <section className="section" data-marker="product-section">
        <div className="wrapper">
          {isEditorMode && <CategoryPageSettingsLayer t={t} />}
          <div className="content">
            <ToolbarTop onScrollIntoView={onScrollIntoView} />
            <h1 className="category-headline">{category.title}</h1>
            <CategorySearch
              productData={productData}
              category={category}
              categoryDepth={breadcrumb.length}
              isBusy={isBusy}
              isEditor={isEditorMode}
              t={t}
              onScrollIntoView={onScrollIntoView}
            />
            <CategoryContent
              productData={productData}
              pageSize={pageSize}
              t={t}
              isBusy={isBusy}
              sortingOptions={sortingOptions}
              categoryData={category}
            />
          </div>
        </div>
      </section>

      <section className="section-dali">
        <Content />
      </section>
    </div>
  )
}
