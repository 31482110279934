let status = false
let lastUpdate = 0
const listeners = []

const now = () => new Date().getTime()

const start = function () {
  if (status === false) {
    status = true
    listeners.forEach((listener) => listener(true))
  }
  lastUpdate = now()
}
const end = function () {
  if (status === true) {
    status = false
    listeners.forEach((listener) => listener(false))
  }
}

if (typeof document === 'object') {
  // all emits are deferred, so that global dragdata can be set before the handlers are invoked
  document.addEventListener('dragstart', () => window.setTimeout(start, 0))
  document.addEventListener('dragenter', () => window.setTimeout(start, 0))
  document.addEventListener('dragover', () => window.setTimeout(start, 0))

  document.addEventListener('dragend', () => window.setTimeout(end, 0))
  document.addEventListener('drop', () => window.setTimeout(end, 0))

  // ensure global drag status is set to false after a certain time without drag over events
  setInterval(() => now() - lastUpdate > 1000 && end(), 100)
}

export function unlisten(callback) {
  for (let i = 0; i < listeners.length; i++) {
    if (listeners[i] === callback) {
      listeners.splice(i, 1)
      return
    }
  }
}

export function listen(callback) {
  unlisten(callback)
  listeners.push(callback)
}
