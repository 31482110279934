/**
 * Checks if a given file truly has an image MIME type that we support for our image plugins.
 * Uses the file's byte pattern to check the MIME type.
 * Background: Sometimes it is not sufficient to check the file.type as it is only assumed based on the file's extension
 * (which could be changed by the user), and not on the file's bytestream, which would show the actual true MIME type.
 * See info box on https://developer.mozilla.org/en-US/docs/Web/API/File/type for more information.
 *
 * @param {File} file The file to be checked
 * @returns {Promise<string | null>} The supported MIME type or null if the file is not supported
 */
export default async function checkSupportedImageMediaType(file: File): Promise<SUPPORTED_MIME_TYPE | null> {
  const fileHeader = await getFileHeader(file)
  return headerToMimeType[fileHeader] || null
}

type SUPPORTED_MIME_TYPE = (typeof headerToMimeType)[keyof typeof headerToMimeType]

// File signatures for JPG, JPEG, PNG and GIF as per https://www.garykessler.net/library/file_sigs.html
const headerToMimeType = {
  '89504e47': 'image/png',
  '47494638': 'image/gif',
  ffd8ffe0: 'image/jpeg',
  ffd8ffe1: 'image/jpeg',
  ffd8ffe2: 'image/jpeg',
  ffd8ffe8: 'image/jpeg',
} as const

// https://stackoverflow.com/a/71320737
async function getFileHeader(file: File): Promise<string> {
  return new Promise((resolve) => {
    const headerBytes = file.slice(0, 4) // Read the first 4 bytes of the file
    const fileReader = new FileReader()
    fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
      const arr = new Uint8Array(e?.target?.result as ArrayBufferLike).subarray(0, 4)
      let header = ''
      for (const entry of arr) {
        header += entry.toString(16)
      }
      resolve(header)
    }
    fileReader.readAsArrayBuffer(headerBytes)
  })
}
