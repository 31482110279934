import type { ReactElement } from 'react'

import Plugin from '../../templateComponents/Plugin'

export default function Category({ category, productData }: CategoryThemeComponentProps): ReactElement {
  return (
    <div className="template-category">
      <h1>{category.title}</h1>
      <Plugin type="epages.text" _id="category" />
      <div>
        {productData.products.map((product) => (
          <div key={product.productId}>
            {product.price && <div>{product.price.formatted}</div>}
            {product.basePrice && <div>{product.basePrice.formatted}</div>}
            {product.image && (
              <div>
                <img src={product.image.url} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
