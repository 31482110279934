import type { ReactElement } from 'react'

import Link from '../../templateComponents/Link'

type LegalPage = {
  id: string
  href: string
  title: string
}

type Props = {
  legalPages: LegalPage[]
}

export default function LegalPages({ legalPages = [] }: Readonly<Props>): ReactElement {
  return (
    <ul className="pages">
      {legalPages.map((page) => (
        <li className="pages-item" key={page.id}>
          <Link to={page.href} className="pages-item-link">
            {page.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
