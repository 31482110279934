import { clearViewError, setViewError } from '../store/actions'
import delay from './delay'

async function runViewStoreUpdate(Component, routerProps, store, skip = false) {
  // Load a loadable component to get access to the `storeUpdate` method.
  if (Component && typeof Component.load === 'function') {
    Component = await Component.load()

    // Loadable components have the signature { default: Component }
    Component = Component.default
  }

  const storeState = store.getState()

  if (!skip && Component && Component.storeUpdate) {
    if (typeof Component === 'function' && Component.storeUpdate) {
      const disableErrorNotifications = (action) => {
        // ignore thunk actions
        if (typeof action !== 'object') return action

        return {
          ...action,
          options: {
            ...action.options,
            showErrorNotification: false,
          },
        }
      }

      const updates = Component.storeUpdate(routerProps, storeState).filter(Boolean).map(disableErrorNotifications)
      return Promise.all(updates.map((action) => store.dispatch(action)))
    } else if (typeof Component === 'object') {
      return Promise.all(Object.keys(Component).map((key) => runViewStoreUpdate(Component[key], routerProps, store)))
    }
  }
}

export default async function runAllViewStoreUpdates({
  routerProps,
  store,
  skip = false,
  runningPreliminaryUpdates = [],
  runningIndependentUpdates = [],
}) {
  try {
    // prevent the `independentUpdates` from causing dangling uncaught promise rejections
    runningIndependentUpdates.forEach((possiblyFailedUpdate) => possiblyFailedUpdate.catch(() => {}))

    await Promise.allSettled(runningPreliminaryUpdates)

    // promise rejections from these updates are expected to be handled by the apiCallMiddleware
    const runningComponentUpdates = routerProps.components.map((c) => runViewStoreUpdate(c, routerProps, store, skip))

    const allUpdates = [...runningPreliminaryUpdates, ...runningComponentUpdates, ...runningIndependentUpdates]

    const inspections = await Promise.allSettled(allUpdates)

    const firstError = inspections.find((i) => i.status === 'rejected')

    if (firstError) throw firstError.reason

    if (!skip) {
      // ensure that the view error is cleared after the view has been rendered
      await delay(0)
      if (store.getState().getIn(['view', 'error'])) store.dispatch(clearViewError())
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
      console.error(err) // eslint-disable-line no-console
    }
    store.dispatch(setViewError(err.message, err.status, err.serverRequestId))
  }
}
