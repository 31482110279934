import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from './withI18n'

export function PreviewBarRaw({ t }: TranslateProps): ReactElement {
  const isShopClosed = useSelector<State, boolean>((state) => state.getIn(['shop', 'isClosed']))
  const themeTitle = useSelector<State, string>((state) => state.getIn(['location', 'query', 'themeTitle']))

  return (
    <div className="ep-preview-bar mobile-view-sm">
      {themeTitle && (
        <span className="ep-preview-bar-theme-headline">
          {t('components.previewBarComponent.themeHeadline', { theme: themeTitle })}
        </span>
      )}
      {isShopClosed && (
        <span className="ep-preview-bar-closed-text">{t('components.previewBarComponent.shopClosedMessage')}</span>
      )}
      <span className="ep-preview-bar-info">{t('components.previewBarComponent.title')}</span>
    </div>
  )
}

export default compose(withI18n('shop'), translate())(PreviewBarRaw)
