import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { addLegacyProductProperties, track } from '../../../../../utils/tracking'
import ProductListItem from '../../../ProductListItem'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

type Props = {
  product: Frontend.Product
} & TranslateProps

function DetailedProductView({ product, t }: Readonly<Props>): ReactElement {
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))

  const trackClick = (product: Frontend.Product, productIndex: number) =>
    track('product:click', { type: 'teaser', product: addLegacyProductProperties(product, isBeyond), productIndex })

  return (
    <ProductListItem withImageAspectRatio product={product} productIndex={0} t={t} trackProductClick={trackClick} />
  )
}

export default compose(withI18n('shop'), translate('components.productPluginComponent'))(DetailedProductView)
