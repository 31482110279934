export default function equalsShallow(a, b) {
  const ak = Object.keys(a)
  const bk = Object.keys(b)

  if (ak.length !== bk.length) return false

  for (let i = 0, l = ak.length; i < l; i++) {
    if (ak.indexOf(bk[i]) < 0 || a[ak[i]] !== b[ak[i]]) return false
  }

  return true
}
