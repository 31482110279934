import type { ReactElement } from 'react'
import cc from 'classcat'

type Props = {
  facet: Core.SelectionFacet
  onFacetChange: (facet: Core.SelectionFacet, value: Core.FacetSelectionValue) => void
}

const areNoValuesSelected = (values: Core.FacetSelectionValues) =>
  Object.values(values).every((value) => !value.selected)

export default function SearchFilterSelectionFacet({ facet, onFacetChange }: Readonly<Props>): ReactElement {
  const handleChange = (value: Core.FacetSelectionValue) => {
    onFacetChange(facet, value)
  }

  return (
    <div className={cc({ 'faceted-search-none-selected': areNoValuesSelected(facet.values) })}>
      {Object.values(facet.values).map((value) => {
        return (
          <label key={value.value} className={cc({ 'faceted-search-selected-value': value.selected })}>
            <input
              className={'faceted-search-selection-value'}
              type="checkbox"
              value={value.name}
              checked={value.selected}
              onChange={() => handleChange(value)}
            />
            <span className="checkbox" />
            {`${value.name} (${value.matches})`}
          </label>
        )
      })}
    </div>
  )
}
