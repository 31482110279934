import type { ReactElement } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import InputSlider from '../../InputSlider'

const defaultRange = {
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
}

type Props = {
  facet: Core.RangeFacet
  onFacetChange: (facet: Core.RangeFacet, range: { min: number; max: number }) => void
}

export default function SearchFilterRangeFacet({ facet, onFacetChange }: Readonly<Props>): ReactElement {
  const handleChange = (selectedRange: { min: number; max: number }) => {
    onFacetChange(facet, selectedRange)
  }

  return (
    <InputSlider
      className="faceted-search-slider"
      range={facet.values?.range ?? defaultRange}
      value={facet.values?.selection}
      onChange={handleChange}
      unit={getSymbolFromCurrency(facet.values?.unit || 'EUR')}
    />
  )
}
