import type { ReactElement } from 'react'

import Content from '../../templateComponents/Workspace'

export default function Home(): ReactElement {
  return (
    <div className="template-home">
      <pre>Home.rt</pre>
      <details data-debug>
        <summary>variables</summary>
      </details>
      <hr />
      <Content />
    </div>
  )
}
