import { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import Immutable from 'immutable'

import { PageOrCategoryContext } from './Category'
import { createMultiLanguageLinks } from './utils/createMultiLanguageLinks'
import { setPageAsync } from '../store/actions'
import Theme from '../components/Theme'

class Page extends Component {
  static propTypes = {
    viewError: map,
    page: map,
  }

  static storeUpdate = (props, state) =>
    props.location.action === 'PUSH' || !state.getIn(['pages', props.params.pageSlug])
      ? [setPageAsync(props.params.pageSlug)]
      : []

  static meta = (props, state) => {
    const page = state.get('pages').get(props.params.pageSlug)
    if (!page) return []

    const isBeyondShop = Boolean(state.getIn(['shop', 'beyond']))
    const isBeyondHomepage = isBeyondShop && page.get('slug') === 'home'

    // Use the merchant-provided title if set, otherwise use the page title.
    // Append the shop title.
    //
    // If it is a Beyond shop, the page could be the home page.
    // In this case, use the merchant-provided title if set, otherwise the shop
    // title with a fallback to the page title.
    const shopTitle = state.getIn(['shop', 'title'])
    const title = isBeyondHomepage
      ? page.get('titleTag') || shopTitle || page.get('title')
      : page.get('titleTag') || [page.get('title'), shopTitle].filter(Boolean).join(' - ')

    const meta = [{ title }]

    const metaDescription = page.get('metaDescription')
    if (metaDescription) {
      meta.push({ name: 'description', content: metaDescription })
    }

    // For category content
    if (props.location.query.sort || props.location.query.page) {
      meta.push({
        name: 'robots',
        content: 'noindex, follow',
      })
    }

    return meta
  }

  static link = (props, state) => {
    const page = state.get('pages').get(props.params.pageSlug)
    if (!page) return []

    const isBeyondShop = Boolean(state.getIn(['shop', 'beyond']))
    const isBeyondHomepage = isBeyondShop && page.get('slug') === 'home'

    return createMultiLanguageLinks(
      'blank',
      isBeyondHomepage ? '' : page.get('slugs', Immutable.List()).toJS(),
      state.get('shop'),
    )
  }

  static contentCreationDisabled = () => false

  render() {
    const { viewError, page } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="page" />
    if (!page) return null

    return (
      <PageOrCategoryContext.Provider value={this.props.page}>
        <Theme withLayout currentView="page">
          {(renderView, props) =>
            renderView('Page', {
              ...props,
              page: page.toJS(),
            })
          }
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default connect((state, props) => ({
  viewError: state.getIn(['view', 'error']),
  page: state.get('pages').get(props.params.pageSlug),
}))(Page)
