import { useSelector } from 'react-redux'

type LocaleWithLanguage = Core.Locale & { language: string }

type HookReturnValue = [locales: LocaleWithLanguage[], shopLocale: string, languageSelectTranslations: ImmutableMap]

export default function useLanguageSwitchLocales(): HookReturnValue {
  const shopLocale = useSelector<State, string>((state) => state.getIn(['shop', 'locale']))
  const availableLocales: Core.Locale[] = useSelector<State, ImmutableList>((state) =>
    state.getIn(['shop', 'locales']),
  ).toJS()
  const languageSelectTranslations = useSelector<State, ImmutableMap>((state) =>
    state.getIn(['view', 'languageSelectTranslations']),
  )

  // Adds the language code as a separate property to the available locales, to easier match
  // the languageSelectTranslations where the language code for Norwegian Bokmål needs to be 'no' instead of 'nb'.
  const localesWithLanguageIdentifiers: LocaleWithLanguage[] = availableLocales.map((locale: Core.Locale) => {
    if (locale.identifier === 'nb_NO' || locale.identifier === 'nb-NO') {
      return { ...locale, language: 'no' }
    }
    return { ...locale, language: locale.identifier.substring(0, 2) }
  })

  // Filter for locales which are visible or the current shop locale,
  // and list them in alphabetical order of their translations.
  const locales = localesWithLanguageIdentifiers
    .filter((locale) => locale.isVisible || locale.identifier === shopLocale)
    .sort((localeA, localeB) => {
      const translationA = languageSelectTranslations.get(localeA.language)
      const translationB = languageSelectTranslations.get(localeB.language)
      return translationA.localeCompare(translationB, undefined, { sensitivity: 'base' })
    })

  return [locales, shopLocale, languageSelectTranslations]
}
