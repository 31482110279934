import flow from 'lodash/fp/flow'
import mapValues from 'lodash/mapValues'
import set from 'lodash/set'
import setFp from 'lodash/fp/set'

import * as actions from '../actions'
import { getPlain } from '../utils'

export default function search(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.SELECT_FACETED_SEARCH_FACET_VALUE: {
      return state.updateIn(['searchData', 'facets'], (rawFacets) => {
        const facets = getPlain<Core.Facets>(rawFacets)
        const { facetId, valueId, selected } = action.payload
        if (facets[facetId].values) {
          return setFp([facetId, 'values', valueId, 'selected'], selected)(facets)
        } else return state
      })
    }
    case actions.SET_FACETED_SEARCH_FACET_RANGE: {
      const { range, facetId } = action.payload
      return state.updateIn(['searchData', 'facets'], (rawFacets) => {
        const facets = getPlain<Core.Facets>(rawFacets)
        const oldRange: Core.FacetRange = facets[facetId].values?.range as Core.FacetRange
        return flow<[Core.Facets], Core.Facets, Core.Facets>(
          setFp([facetId, 'values', 'selection'], range),
          setFp([facetId, 'values', 'selected'], range.min !== oldRange.min || range.max !== oldRange.max),
        )(facets)
      })
    }
    case actions.RESET_ALL_FILTERS: {
      const facets = getPlain<Core.Facets>(state.getIn(['searchData', 'facets']))
      const newFacets = mapValues(facets, (facet) => {
        if (facet.type === 'selection') {
          return set(
            facet,
            'values',
            mapValues(facet.values, (value) => set(value, 'selected', false)),
          )
        } else if (facet.type === 'range') {
          const values = facet.values
          if (values)
            return set(
              facet,
              'values',
              flow<[typeof values], typeof values, typeof values>(
                setFp('selected', false),
                setFp('selection', values.range),
              )(values),
            )
        }
      })
      return state.setIn(['searchData', 'facets'], newFacets)
    }
    default:
      return state
  }
}
