import type { CSSProperties, ReactElement } from 'react'
import { Component, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSwipeable } from 'react-swipeable'
import cc from 'classcat'

import { switchLanguage } from '../../../store/actions'
import Link from '../../templateComponents/Link'
import translate from '../../../utils/translate'
import useLanguageSwitchLocales from '../../../utils/hooks/useLanguageSwitchLocales'

type MenuItemProps = Readonly<{
  item: Frontend.NestedPage
  selectedItem: Frontend.NestedPage
}>

type MenuItemState = {
  isOpen: boolean
}

export class MenuItem extends Component<MenuItemProps, MenuItemState> {
  private subMenu: HTMLUListElement

  state = {
    isOpen: isMenuItemOpen(this.props),
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: MenuItemProps) {
    this.setState({
      isOpen: isMenuItemOpen(nextProps),
    })
  }

  componentDidMount() {
    if (this.subMenu && this.state.isOpen) this.openSubMenu()
  }

  componentDidUpdate(_prevProps: MenuItemProps, prevState: MenuItemState) {
    if (this.subMenu) {
      if (!prevState.isOpen && this.state.isOpen) {
        this.openSubMenu()
      } else if (prevState.isOpen && !this.state.isOpen) {
        this.closeSubMenu()
      }
    }
  }

  openSubMenu() {
    // 1. Transition to inner content's height
    // 2. Set height to `auto` once the transition ended
    this.subMenu.style.height = `${this.subMenu.scrollHeight}px`
    this.subMenu.addEventListener('transitionend', this.handleTransitionEnd)
  }

  closeSubMenu() {
    // 1. Temporarily disable the transition effect and explicitly set the current
    //    pixel height value of the sub menu in order for the transition effect to
    //    work (can't transition out of `auto`)
    // 2. Transition the sub menu's height to `0px`
    this.subMenu.classList.add('notransition')
    window.requestAnimationFrame(() => {
      if (this.subMenu) {
        this.subMenu.style.height = `${this.subMenu.scrollHeight}px`
        this.subMenu.classList.remove('notransition')
      }
      window.requestAnimationFrame(() => {
        if (this.subMenu) {
          this.subMenu.style.height = '0'
        }
      })
    })
  }

  handleTransitionEnd = () => {
    this.subMenu.removeEventListener('transitionend', this.handleTransitionEnd)
    if (this.subMenu && this.state.isOpen) this.subMenu.style.height = 'auto'
  }

  render() {
    const { item, selectedItem } = this.props
    const { isOpen } = this.state
    const hasSubMenu = item.children.length > 0

    return (
      <li
        className={cc({
          'has-sub-menu': hasSubMenu,
          open: isOpen,
          active: item.isCurrentPage,
        })}
      >
        <Link to={item.href}>{item.title}</Link>

        {hasSubMenu && (
          <>
            <div onClick={() => this.setState({ isOpen: !isOpen })} className={cc(['drop-icon', { opened: isOpen }])} />
            <ul ref={(node: HTMLUListElement) => (this.subMenu = node)} className="sub-menu">
              {item.children.map((child) => (
                <MenuItem selectedItem={selectedItem} key={child.id} item={child} />
              ))}
            </ul>
          </>
        )}
      </li>
    )
  }
}

const openClass = 'js-page-menu-open'

type OffCanvasProps = {
  className?: string
  direction?: 'left' | 'right'
  items: Frontend.NestedPage[]
} & TranslateProps

function OffCanvas({ items, className, direction = 'left', t }: Readonly<OffCanvasProps>): ReactElement {
  const dispatch = useDispatch<GlobalDispatch>()
  const selectedItem = useRef(findActiveItem(items))
  const [isOpen, setIsOpen] = useState(false)
  const [isHidden, setHidden] = useState(false)
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)
  const [locales, shopLocale, languageSelectTranslations] = useLanguageSwitchLocales()

  useEffect(() => {
    document.body.classList.toggle(openClass, isOpen)

    let timeoutId: ReturnType<typeof setTimeout>
    if (!isOpen) {
      // Defer setting `visibility: hidden` to not hide the element in the
      // middle of the page menu slide out transition animation.
      timeoutId = setTimeout(() => {
        setHidden(true)
      }, 1000)
    } else {
      setHidden(false)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isOpen])

  useEffect(() => {
    setIsOpen(false)
    selectedItem.current = findActiveItem(items)
  }, [items])

  const toggleOffCanvas = () => {
    setIsOpen(!isOpen)
    if (isLanguageMenuOpen) setIsLanguageMenuOpen(false)
  }

  const handlers = useSwipeable({
    onSwipedLeft: direction === 'left' ? toggleOffCanvas : undefined,
    onSwipedRight: direction === 'right' ? toggleOffCanvas : undefined,
  })

  const accessibilityStyle: CSSProperties = {
    visibility: isHidden ? 'hidden' : undefined,
  }

  return (
    <div className={className}>
      <div className="toggle-menu">
        <button
          className="burger-icon"
          aria-label={t(`${isOpen ? 'closeMainMenuButton' : 'openMainMenuButton'}.accessibilityLabel`)}
          aria-controls="main-menu-offcanvas"
          aria-expanded={isOpen}
          onClick={toggleOffCanvas}
        >
          <span className="burger-icon-stripes" />
        </button>
      </div>
      <div {...handlers} id="main-menu-offcanvas" style={accessibilityStyle}>
        <div className="main-menu-overlay" onClick={toggleOffCanvas} />
        <div className="main-menu-wrapper">
          <div className="main-menu-header" />
          {locales.length > 1 && (
            <>
              <button
                className="language-indicator-mobile"
                aria-expanded={isLanguageMenuOpen}
                aria-controls="language-menu-mobile"
                onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
              >
                <span className="globe-icon"></span>
                <span>
                  {isLanguageMenuOpen
                    ? t('mobileMenuLanguageSwitch.label')
                    : t(`:enumerations.languages.${shopLocale.substring(0, 2)}`)}
                </span>
                <span className={cc(['arrow-icon', isLanguageMenuOpen && 'open'])}></span>
              </button>
              <ul id="language-menu-mobile" className={cc(['language-menu-mobile', isLanguageMenuOpen && 'open'])}>
                {locales.map((locale) => (
                  <li key={locale.identifier} className={cc({ selected: locale.identifier === shopLocale })}>
                    <button onClick={() => dispatch(switchLanguage(locale.identifier))}>
                      {languageSelectTranslations.get(locale.language)}
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
          <ul className="main-menu">
            {items.map((item) => (
              <MenuItem key={item.id} item={item} selectedItem={selectedItem.current} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default translate('components.storefrontMainMenuComponent')(OffCanvas)

export function findActiveItem(items: Frontend.NestedPage[]) {
  for (const item of items) {
    if (item.isCurrentPage) return item
    if (item.children.length) {
      const foundItem = findActiveItem(item.children)
      if (foundItem) return foundItem
    }
  }
}

export function isMenuItemOpen({
  item,
  selectedItem,
}: {
  item: Frontend.NestedPage
  selectedItem: Frontend.NestedPage
}): boolean {
  return !!(selectedItem && selectedItem.parents?.find(({ id }) => id === item.id))
}
