import type { ReactElement } from 'react'
import cc from 'classcat'

import AccountIcon from '../AccountIcon'
import HeaderLanguageSwitch from '../HeaderLanguageSwitch'
import HeaderLogo from '../HeaderLogo'
import MiniCart from '../MiniCart'
import SearchForm from '../SearchForm'
import ThemeHeaderMenu from '../ThemeHeaderMenu'

type Props = any
export default function Essence(props: Props): ReactElement {
  const { shop, isPreview } = props

  return (
    <header className="section site-header">
      <div className="wrapper">
        <div className="header">
          <div className={cc(['header-logo', shop.logoUrl && !shop.shouldHideLogo && 'header-logo-adjustments'])}>
            <HeaderLogo shop={shop} />
          </div>

          <div className="header-search">
            <SearchForm className="search-form" namespace="header" maxSuggestedItems={5} />
          </div>

          <div className="header-right">
            <HeaderLanguageSwitch />
            <AccountIcon accountUrl={shop.accountUrl} isPreview={isPreview} />
            <MiniCart cartUrl={shop.cartUrl} />
          </div>
        </div>
        <ThemeHeaderMenu {...props} />
      </div>
    </header>
  )
}
