import loadable from '@loadable/component'

export const Checkout = loadable(() => import(/* webpackChunkName: "checkout" */ './Checkout'))
export const ForgotPassword = loadable(() => import(/* webpackChunkName: "checkout" */ './ForgotPassword'))
export const ResetPassword = loadable(() => import(/* webpackChunkName: "checkout" */ './ResetPassword'))
export const Account = loadable(() => import(/* webpackChunkName: "account" */ './Account/Account'))

export { default as Error } from './Error'
export { default as Legal } from './Legal'
export { default as Order } from './Order'
export { default as Page } from './Page'
