import AccordionItem from './AccordionItem'

interface AccordionItemData {
  title: string
  content: string
}

interface AccordionProps {
  data: AccordionItemData[]
  titleClassName?: string
  innerTextClassName?: string
}

export default function Accordion({ data, innerTextClassName, titleClassName }: Readonly<AccordionProps>) {
  return (
    <>
      {data.map((item, index) => (
        <AccordionItem key={index} title={item.title} titleClassName={titleClassName}>
          <div className={innerTextClassName} dangerouslySetInnerHTML={{ __html: item.content }} />
        </AccordionItem>
      ))}
    </>
  )
}
