import type { FormEventHandler, ReactElement } from 'react'
import { fromJS } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'
import { useRef, useState } from 'react'
import loadable from '@loadable/component'

import translate, { withComponents } from '../../../../../utils/translate'
import { submitContactForm } from '../../../../../store/actions'
import Link from '../../../Link'
import compose from '../../../../../utils/compose'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const ContactFormSettings = loadable(() => import(/* webpackChunkName: "editor" */ './ContactFormSettings'))

const scoped =
  (t: TranslateProps['t']): TranslateProps['t'] =>
  (key, ...args) =>
    t('components.contactFormComponent.' + key, ...args)

function EditorHintRaw({ t }: TranslateProps): ReactElement {
  const email = useSelector<State, string>((state) => state.getIn(['shop', 'email']))
  return (
    <span className="dali-block-hint">
      {email
        ? scoped(t)('emailDestinationExplanation', { email })
        : scoped(t)('emailDestinationExplanationWithoutEmail')}
    </span>
  )
}
const EditorHint = compose(withI18n('interface'), translate())(EditorHintRaw)

const MAX_INPUT_LENGTH = 320
const MAX_MESSAGE_LENGTH = 2000

type Props = WorkspacePluginProps & TranslateProps
function ContactFormPlugin({
  t,
  editorView,
  editorMode,
  data = fromJS({ showPrivacyCheckbox: false }),
  onDataChange,
  onSave,
  onCancel,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const csrfToken = useSelector<State, string>((state) => state.getIn(['shop', 'csrfToken']))
  const privacyLink = useSelector<State, string>((state) => state.getIn(['shop', 'legalPageUrls', 'privacy']))
  const showPrivacyCheckbox = data.get('showPrivacyCheckbox', false)

  const [charCount, setCharCount] = useState(0)
  const [requestSent, setRequestSent] = useState(false)

  const scopedT = scoped(t)

  const rootElement = useRef<HTMLDivElement>(null)

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    event.persist() // thank your for nothing, React

    try {
      const formData = new FormData(event.currentTarget)

      // axios can only send multipart form data, and body-parser can only parse urlencoded form data,
      // so let's just use JSON ¯\_(ツ)_/¯
      const payload: Record<'name' | 'email' | 'message', string> & { acceptPrivacyPolicy: boolean; _tac?: string } = {
        name: '',
        email: '',
        message: '',
        acceptPrivacyPolicy: false,
      }
      formData.forEach((value: string, key) => {
        payload[key] = value
      })

      // Don't submit if the honeypot field is filled
      if (payload._tac) return
      if (showPrivacyCheckbox && !payload.acceptPrivacyPolicy) return

      await dispatch(submitContactForm(payload, csrfToken))
      ;(event.target as HTMLFormElement).reset()
      setCharCount(0)
      setRequestSent(true)
    } catch {}
  }

  return (
    <div className="dali-plugin-contactform" ref={rootElement}>
      <form
        className="dali-form"
        name="contact"
        autoComplete="on"
        onSubmit={handleSubmit}
        onInvalid={(e: any) => e.target.classList.add('invalid')}
        onChange={(event: any) => {
          event.target.classList.toggle('invalid', !event.target.validity.valid)
          setRequestSent(false)
        }}
        method="post"
        action="/api/v2/report-violation"
      >
        {requestSent && <p className="success-message">{scopedT('successMessage')}</p>}
        <div className="dali-form-group">
          <label>
            {scopedT('nameInputField.label')}*
            <input
              name="name"
              type="text"
              autoComplete="name"
              required
              maxLength={MAX_INPUT_LENGTH}
              disabled={editorView}
            />
          </label>
          <label>
            {scopedT('emailInputField.label')}*
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              maxLength={MAX_INPUT_LENGTH}
              disabled={editorView}
            />
          </label>
        </div>
        <label>
          {scopedT('messageInputField.label')}*
          <span className="dali-form-counter">
            {scopedT('characterCounter', { length: charCount, limit: MAX_MESSAGE_LENGTH })}
          </span>
          <textarea
            onChange={(e) => setCharCount(e.target.value.length)}
            name="message"
            required
            maxLength={MAX_MESSAGE_LENGTH}
            disabled={editorView}
          />
        </label>
        {showPrivacyCheckbox && (
          <label className="dali-form-label-group">
            <input type="checkbox" name="acceptPrivacyPolicy" required />
            <span>
              {withComponents(scopedT)('privacyCheckbox.label', {
                link: <Link to={privacyLink}>{scopedT('privacyCheckbox.link')}</Link>,
              })}
              *
            </span>
          </label>
        )}
        <label className="box-of-sweet-nectar">
          {scopedT('honeypotInputField.label')}
          <input name="_tac" defaultValue="" autoComplete="off" tabIndex={-1} />
        </label>
        <button>{scopedT('sendButton.label')}</button>
      </form>
      {editorView && <EditorHint />}
      {editorMode === 'edit' && (
        <SettingsLayer referenceElement={rootElement.current} placement="right" onEscapeKeyDown={onCancel}>
          {({ renderLayout }) => (
            <ContactFormSettings
              data={data}
              onDataChange={onDataChange}
              onSave={onSave}
              onCancel={onCancel}
              renderLayout={renderLayout}
            />
          )}
        </SettingsLayer>
      )}
    </div>
  )
}
ContactFormPlugin.actionBarButtons = { save: false, edit: true }

export default compose(withI18n('shop'), translate())(ContactFormPlugin)
