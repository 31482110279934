export default function jwtDecode(jwt: string): { [key: string]: any } {
  try {
    const payload = jwt.split('.')[1]
    const payloadDecoded =
      typeof window !== 'undefined' ? atob(payload) : Buffer.from(payload, 'base64').toString('ascii')
    return JSON.parse(payloadDecoded)
  } catch {
    return {}
  }
}
