import { Component, createRef } from 'react'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

import { extractGoogleMapsEmbedded } from './extractGoogleMapsSource'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

/* istanbul ignore next */
const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
/* istanbul ignore next */
const GoogleMapsSettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './GoogleMapsSettings'))

export class GoogleMapsPluginRaw extends Component {
  static propTypes = {
    editorView: PropTypes.bool.isRequired,
    editorMode: PropTypes.oneOf(['view', 'edit']).isRequired,
    isMultiColumn: PropTypes.bool.isRequired,
    data: ImmutablePropTypes.map.isRequired,
    onDataChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: Immutable.fromJS({ iframe: '' }),
  }

  static actionBarButtons = {
    save: false,
    edit: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      isSettingActive: false,
    }
    this.handlePluginActiveStateChange = this.handlePluginActiveStateChange.bind(this)
  }

  handlePluginActiveStateChange(isActive) {
    this.setState({ isSettingActive: isActive })
  }

  rootElement = createRef()

  render() {
    const { data } = this.props
    const isEmpty = !data.get('iframe')
    const googleMapsPb = extractGoogleMapsEmbedded(data.get('iframe'))
    const googleMapsPlaceholder = (
      <div className="dali-grid-element-placeholder">
        <span className="dali-plugin-maps-placeholder" />
        <button className="dali-plugin-maps-placeholder-button-add" onClick={this.props.onEdit}>
          {this.props.t('components.gmapsElementComponent.addMapButton.label')}
        </button>
      </div>
    )

    if (this.props.editorView) {
      return (
        <div
          className={`dali-plugin-maps ${this.state.isSettingActive ? 'dali-grid-element-highlighted' : ''}`}
          ref={this.rootElement}
        >
          {isEmpty ? googleMapsPlaceholder : this.renderGoogleMaps(googleMapsPb)}
          {this.props.editorMode === 'edit' ? this.renderSettingsLayer() : null}
        </div>
      )
    }
    return !googleMapsPb ? null : <div className="dali-plugin-maps">{this.renderGoogleMaps(googleMapsPb)}</div>
  }

  renderSettingsLayer() {
    const { data, onSave, onDataChange, onCancel } = this.props
    return (
      <SettingsLayer
        referenceElement={this.rootElement.current}
        placement="right"
        onActiveStateChange={this.handlePluginActiveStateChange}
        onEscapeKeyDown={onCancel}
      >
        {({ renderLayout }) => <GoogleMapsSettingsForm {...{ data, onDataChange, onSave, onCancel, renderLayout }} />}
      </SettingsLayer>
    )
  }

  renderGoogleMaps(pb) {
    return (
      <div className="dali-plugin-maps-container">
        <iframe
          className="dali-plugin-maps-content lazyload"
          data-src={`https://www.google.com/maps/embed?pb=${pb}`}
          // Suppress hydration warning because some prop values will
          // unavoidably be different between the server and the client.
          // lazysizes will change these attributes on the client.
          suppressHydrationWarning={true}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    )
  }
}

export default compose(withI18n('interface'), translate())(GoogleMapsPluginRaw)
