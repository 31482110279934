import type { HTMLProps, PropsWithChildren, ReactElement } from 'react'
import type { LinkProps } from 'react-router'
import { Link as ReactRouterLink } from 'react-router'
import { useSelector } from 'react-redux'

import { withQueryAndScope } from '../../urlGenerators'

type Props = LinkProps | HTMLProps<HTMLAnchorElement>
export default function Link(props: PropsWithChildren<Props>): ReactElement {
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))
  if ('to' in props && props.to) {
    const { to, ...other } = props
    return <ReactRouterLink to={withQueryAndScope(to, location)} {...other} />
  } else {
    // The component using this is responsible for that.
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} />
  }
}
