import type { ComponentType } from 'react'
import { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import i18n from '../i18next'

export const i18nInstancesContext = createContext<I18nInstancesContext>({})

interface I18nInstancesContext {
  interfaceI18n?: typeof i18n
  shopI18n?: typeof i18n
}

type Props = {
  Component: ComponentType
  [key: string]: any
}

export default function I18nProvider({ Component, ...other }: Readonly<Props>) {
  const shopLocale = useSelector<State, string>((state: State) => state.getIn(['shop', 'locale']).replace('_', '-'))
  const interfaceLanguage = useSelector<State, string>((state) => state.getIn(['view', 'interfaceLanguage']))

  const [interfaceI18n] = useState(i18n.cloneInstance)
  const [shopI18n] = useState(i18n.cloneInstance)

  const i18nInstances = useMemo(() => ({ interfaceI18n, shopI18n }), [interfaceI18n, shopI18n])

  // set correct shop locale (language)
  if (shopI18n.language !== shopLocale) shopI18n.changeLanguage(shopLocale)
  if (interfaceI18n.language !== interfaceLanguage) interfaceI18n.changeLanguage(interfaceLanguage)

  return (
    <i18nInstancesContext.Provider value={i18nInstances}>
      <Component {...other} />
    </i18nInstancesContext.Provider>
  )
}
