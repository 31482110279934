import type { ReactElement } from 'react'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import kebabCase from 'lodash/kebabCase'

import { getNormalizedHeaderType } from '../../utils/themes'
import { getPlain } from '../store/utils'
import { setHasThemeLoaded } from '../../web/store/actions/view'
import Notification from './templateComponents/Notification'
import ThemeView from './ThemeView'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from './withI18n'

type ThemeProps = {
  isBeyondShop: boolean
  isUnsupportedBrowser: boolean
  hasThemeLoaded: boolean | undefined
  setHasThemeLoaded: (hasThemeLoaded: boolean | undefined) => void
  isEcommerceDisabled: boolean
  currentView: string
  previewTheme: string
  shop: ImmutableMap
  defaultThemeSettings: Theme.Settings
  navigation: ImmutableMap
  breadcrumb: ImmutableList
  legalPages: ImmutableList
  footerPages: ImmutableList
  scriptTags: ImmutableList
  isEditorMode: boolean
  withLayout: boolean
  taxationByDestinationCountry: boolean
  children?: (renderView: (view: string, props: any) => ReactElement, themeComponentProps: any) => ReactElement
  error?: ImmutableMap
} & TranslateProps

export function ThemeRaw(props: Readonly<ThemeProps>) {
  const {
    isBeyondShop,
    isUnsupportedBrowser,
    previewTheme,
    isEcommerceDisabled,
    currentView,
    withLayout = false,
    taxationByDestinationCountry,
    isEditorMode,
    shop,
    defaultThemeSettings,
    error,
    children,
    navigation,
    breadcrumb,
    legalPages,
    footerPages,
    scriptTags,
    t,
    hasThemeLoaded,
    setHasThemeLoaded,
  } = props

  // might be undefined during language switch, some template components (e.g Cart)
  // will break hard when we render during that transition. Bare minimum template render props.
  // However we must make sure to only not render the respective template but load the stylesheet (see below)
  // The views passing their own props take care of checking these themselves
  const preventRendering = !error && (!shop || !navigation || !breadcrumb || !legalPages)

  // Use `shop.get('isInEU')` as fallback if the shop attribute can't be found
  const isInEU =
    getPlain(shop.get('attributes', [])).find((attribute) => attribute.name === 'isInEU')?.value ?? shop.get('isInEU')

  const themeSettings = shop.get('themeSettings').toJS()
  const themeComponentProps = {
    shop: shop ? shop.toJS() : {},
    scriptTags: scriptTags ? scriptTags.toJS() : [],
    navigation: navigation ? navigation.get('storefront').toJS() : [],
    breadcrumb: breadcrumb ? breadcrumb.toJS() : [],
    footerPages: footerPages?.toJS() || [],
    legalPages: legalPages
      ? legalPages.toJS().reduce((items, item) => {
          if (item.isVisible) {
            // In non-Beyond shops, `title` is already translated
            if (isBeyondShop) item.title = t(item.title)

            items.push(item)
          }

          return items
        }, [])
      : [],
    isTaxationByDestinationCountryEnabled: !isBeyondShop && taxationByDestinationCountry && isInEU,
    isPreview: Boolean(previewTheme),
    isEditor: isEditorMode,
  }

  const content = error
    ? renderView('Error', { shop: shop.toJS(), error: error.toJS() })
    : children?.(renderView, themeComponentProps)

  const viewTemplate = withLayout
    ? renderView('Layout', {
        ...themeComponentProps,
        content,
        currentView,
      })
    : content

  const editorStorefrontLang = isEditorMode ? shop.get('locale').substring(0, 2) : null
  const headerType = getNormalizedHeaderType(
    themeSettings?.themeHeader?.headerType || defaultThemeSettings?.themeHeader?.headerType,
  )

  // A loading state is only needed upon theme switch on the client.
  // When hasThemeLoaded is `undefined`, it means that the component is rendered on the server,
  // or just mounted. In these cases, the theme is immediately ready.
  const isLoading = hasThemeLoaded === undefined ? false : !hasThemeLoaded

  // This effect is needed to let the editor bottom bar appear/disappear correctly when
  // switching from the main editor view to the theme switch view and vice versa.
  // It is only relevant in the editor.
  useEffect(() => {
    if (isEditorMode && hasThemeLoaded === undefined) {
      setHasThemeLoaded(true)
    }
    return () => {
      if (isEditorMode && hasThemeLoaded && window.location.pathname.endsWith('/editor/themes')) {
        setHasThemeLoaded(undefined)
      }
    }
  }, [hasThemeLoaded, setHasThemeLoaded, isEditorMode])

  return (
    <div
      className={cc([
        'body',
        {
          'no-ecommerce': isEcommerceDisabled,
          [`header-type-${kebabCase(headerType)}`]: headerType,
        },
      ])}
      lang={editorStorefrontLang}
    >
      {isUnsupportedBrowser && (
        <div className="unsupported-browser">
          <div className="unsupported-browser-info">
            {t('components.unsupportedBrowserNotice.explanation')}{' '}
            <a
              className="unsupported-browser-info-link"
              href="https://browser-update.org/update-browser.html"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {t('components.unsupportedBrowserNotice.link.label')}
            </a>
          </div>
        </div>
      )}
      {isLoading ? <div className="editor-content-spinner"></div> : !preventRendering && viewTemplate}
      <Notification />
    </div>
  )
}

function renderView(view: string, props: any) {
  return <ThemeView view={view} {...props} />
}

export default compose(
  withI18n('shop'),
  translate(),
  connect(
    (state: State) => ({
      isBeyondShop: Boolean(state.getIn(['shop', 'beyond'])),
      isUnsupportedBrowser: state.getIn(['view', 'isUnsupportedBrowser']),
      hasThemeLoaded: state.getIn(['view', 'hasThemeLoaded']),
      shop: state.get('shop'),
      defaultThemeSettings: state.get('defaultThemeSettings', Immutable.Map()).toJS(),
      isEditorMode: state.getIn(['view', 'editorMode']),
      previewTheme: state.getIn(['location', 'query', 'previewTheme']),
      isEcommerceDisabled: Boolean(
        state
          .getIn(['shop', 'attributes'])
          .find(
            (attribute: ImmutableMap) =>
              attribute.get('name') === 'ecommerce:disabled' && attribute.get('value') === 'true',
          ),
      ),
      taxationByDestinationCountry: state.getIn(['tax', 'taxationByDestinationCountry']),
      navigation: state.get('navigation'),
      breadcrumb: state.get('breadcrumb'),
      legalPages: state.get('legalPages'),
      footerPages: state.get('footerPages'),
      scriptTags: state.get('scriptTags'),
    }),
    (dispatch) => ({
      setHasThemeLoaded: (hasThemeLoaded: boolean | undefined) => dispatch(setHasThemeLoaded(hasThemeLoaded)),
    }),
  ),
)(ThemeRaw)
