import type { ReactElement } from 'react'
import loadable from '@loadable/component'

import { addressTypeToString, areAddressesTheSame } from '../../templateComponents/Checkout/utils'
import Link from '../../templateComponents/Link'
import NewsletterForm from '../../templateComponents/NewsletterForm'
import translate from '../../../utils/translate'

const OpeningHours = loadable(
  () => import(/* webpackChunkName: "checkout" */ '../../templateComponents/Checkout/PickupOptionsList'),
  { resolveComponent: (components) => components.OpeningHours },
)

export default translate('views.storefrontView.orderConfirmationPageSection')(function Order({
  t,
  shop,
  order: { billingAddress, orderNumber, paymentData, pickupLineItem, shippingAddress },
}: OrderThemeComponentProps): ReactElement {
  const { companyName, regularHours, address } = pickupLineItem?.pickupOption._embedded.location || {}

  return (
    <div className="order">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>{t('title')}</h1>
            {(paymentData.failed || paymentData.canceled) && (
              <div className="order-confirmation-warning">
                <span>{t('errorStatus.paymentWarningMessage')}</span>
              </div>
            )}
            <div className="order-confirmation">
              {paymentData.success && (
                <div className="order-confirmation-status">
                  <div className="status-order">
                    <h2>{t('successStatus.orderReceived')}</h2>
                    <p className="status-order-number">
                      {t('successStatus.orderNumber', {
                        orderNumber,
                      })}
                    </p>
                    <p className="status-order-email">
                      {t('successStatus.emailNote', {
                        email: billingAddress.email,
                      })}
                    </p>
                  </div>
                  <NewsletterForm className="status-newsletter-form">
                    <div className="status-newsletter">
                      <h2>
                        <label htmlFor="email">{t(':components.newsletterSubscriptionComponent.title')}</label>
                      </h2>
                      <div className="status-newsletter-form-register">
                        <input
                          className="status-newsletter-form-field"
                          name="email"
                          type="email"
                          required
                          placeholder={t(
                            ':components.newsletterSubscriptionComponent.emailAddressInputField.placeholder',
                          )}
                        />
                        <button className="status-newsletter-form-button" type="submit">
                          <span>{t(':components.newsletterSubscriptionComponent.submitButton.label')}</span>
                        </button>
                      </div>
                    </div>
                  </NewsletterForm>
                  <div className="status-home">
                    <h2>{t('successStatus.whatNext')}</h2>
                    <Link to={shop.href} className="status-home-link" title={shop.title}>
                      {t(':views.storefrontView.returnToStartpageLink.label')}
                    </Link>
                  </div>
                </div>
              )}
              {(paymentData.failed || paymentData.canceled) && (
                <div className="order-confirmation-status">
                  <div className="status-order">
                    <h2>{t('errorStatus.orderReceived')}</h2>
                    <p>
                      {t('successStatus.emailNote', {
                        email: billingAddress.email,
                      })}
                    </p>
                    <p>{t('errorStatus.contactUs')}</p>
                    <p className="status-order-number">
                      {t('successStatus.orderNumber', {
                        orderNumber,
                      })}
                    </p>
                  </div>
                </div>
              )}
              {shippingAddress && !areAddressesTheSame(shippingAddress, billingAddress) && (
                <div className="order-confirmation-address shipping">
                  <h3>{t('successStatus.shippingAddress')}</h3>
                  <div>{addressTypeToString(shippingAddress)}</div>
                </div>
              )}
              {pickupLineItem && (
                <div className="order-confirmation-address shipping pickup">
                  <h3>{t('successStatus.pickupLocation.title')}</h3>
                  <ul className="pickup-description">
                    <li className="pickup-description-headline">{companyName}</li>
                    <li className="pickup-description-address">{addressTypeToString(address)}</li>
                    <li className="pickup-description-content">{pickupLineItem.pickupOption.description}</li>
                    {Boolean(regularHours?.periods.length) && (
                      <OpeningHours
                        t={(key, ...args) => t(`successStatus.pickupLocation.${key}`, ...args)}
                        location={pickupLineItem.pickupOption._embedded.location}
                      />
                    )}
                  </ul>
                </div>
              )}
              <div className="order-confirmation-address billing">
                {(!shippingAddress && !pickupLineItem) || areAddressesTheSame(billingAddress, shippingAddress) ? (
                  <h3>{t('successStatus.billingShippingAddress')}</h3>
                ) : (
                  <h3>{t('successStatus.billingAddress')}</h3>
                )}
                <div>{addressTypeToString(billingAddress)}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
})
