import { useEffect, useRef, useState } from 'react'

type Props = Readonly<{
  children: React.ReactNode | ((close: () => void) => React.ReactNode)
  ariaId: string
  ariaLabel: string
  buttonLabel: string | React.ReactNode
}>

export default function Dropdown({ children, ariaId, ariaLabel, buttonLabel }: Props) {
  const [expanded, setExpanded] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  function closeDropdown() {
    wrapperRef.current?.classList.add('closing')
    document.body.setAttribute('tabindex', '-1')
    document.body.focus()
  }

  // attach event listeners
  useEffect(() => {
    const dropdown = document.getElementById(ariaId)
    if (!dropdown) return

    const updateExpandedAndRemoveClosing = (event: TransitionEvent) => {
      if (event.target === event.currentTarget) {
        setExpanded(getComputedStyle(dropdown).visibility === 'visible')
        wrapperRef.current?.classList.remove('closing')
      }
    }
    dropdown.addEventListener('transitionend', updateExpandedAndRemoveClosing)

    return () => {
      dropdown.removeEventListener('transitionend', updateExpandedAndRemoveClosing)
    }
  }, [ariaId])

  // to fully meet WCAG 2.1, JS needs to be used to offer a non-mouse/non-tab way to dismiss the menu
  useEffect(() => {
    if (!expanded) return

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') closeDropdown()
    }
    window.addEventListener('keyup', handleEscapeKey, true)

    return () => {
      window.removeEventListener('keyup', handleEscapeKey, true)
    }
  }, [expanded])

  return (
    <>
      {/* tabIndex required for iOS Safari,
       see https://stackoverflow.com/questions/66477748/focus-within-works-on-android-browser-but-not-ios */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className="dropdown" ref={wrapperRef} tabIndex={0}>
        <button
          type="button"
          className="dropdown-title"
          aria-label={ariaLabel}
          aria-expanded={expanded}
          aria-controls={ariaId}
        >
          {buttonLabel}
        </button>
        <ul className="dropdown-menu" id={ariaId}>
          {typeof children === 'function' ? children(closeDropdown) : children}
        </ul>
      </div>
    </>
  )
}
