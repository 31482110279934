import { List, Map } from 'immutable'

import buildURL from '../utils/buildURL'
import getLocaleFromPath from '../utils/getLocaleFromPath'

export const cartPath = 'cart'
export const orderPath = 'o'
export const customerAccountPath = 'customer-account'
export const customerOrdersPath = customerAccountPath + '/orders'

export function generateShopUrl() {
  return '/'
}

export function generateCartUrl() {
  return generateShopUrl() + cartPath
}

export function generateCustomerAccountUrl() {
  return generateShopUrl() + customerAccountPath
}

export function generateCustomerOrdersUrl() {
  return generateShopUrl() + customerOrdersPath
}

export function generateSearchUrl(query) {
  return buildURL(`${generateShopUrl()}search`, { q: query })
}

export function generateCategoryUrl(category) {
  return encodeURI(`/c/${category.get('slug')}`)
}

export function generateProductUrl(product) {
  const slug = product.slug
  // for nonloaded products return href: null (UNITY-4546)
  return slug ? encodeURI(`/p/${slug}`) : null
}

export function generatePageUrl(page) {
  switch (page.get('type')) {
    case 'blank':
      return encodeURI(`/i/${page.get('slug')}`)
    case 'legal':
      return encodeURI(`/l/${page.get('slug')}`)
    case 'category':
      return generateCategoryUrl(page)
    default:
      return null
  }
}

export function extendShopWithUrls(shop) {
  const legalPageUrls = ['shipping', 'privacy', 'withdrawal', 'contact', 'tac', 'cookiepolicy'].reduce(
    (urls, slug) => urls.set(slug, generatePageUrl(new Map({ type: 'legal', slug }))),
    new Map(),
  )

  return shop.set('href', generateShopUrl()).set('cartUrl', generateCartUrl()).set('legalPageUrls', legalPageUrls)
}

export function extendNavigationWithUrls(navigation) {
  const recursion = (list) =>
    list.map((node) => {
      return node.set('href', generatePageUrl(node)).set('children', recursion(node.get('children') || new List()))
    })
  return recursion(navigation)
}

/**
 * @param {Map} category category data object
 * @returns {Map} updated cart data
 */
export function extendCategoryWithUrl(category) {
  return category.set('url', generateCategoryUrl(category))
}

/**
 * @param {object} productData product data
 * @returns {object} updated product data
 */
export function extendProductDataWithUrls(productData) {
  return {
    ...productData,
    products: productData.products.map((product) => ({
      ...product,
      href: generateProductUrl(product),
    })),
  }
}

/**
 * @param {Map} cart cart data
 * @returns {Map} updated cart data
 */
export function extendCartWithProductUrls(cart) {
  return cart.update('productLineItems', (productLineItems) =>
    (productLineItems || new List()).map((lineItem) => lineItem.set('productUrl', generateProductUrl(lineItem.toJS()))),
  )
}

/**
 * @param {Map} page page data
 * @returns {Map} updated page data
 */
export function extendPageWithUrl(page) {
  return page.set('href', generatePageUrl(page))
}

/**
 * @param {Map} product product data
 * @returns {Map} updated product data
 */
export function extendProductWithUrl(product) {
  return product.set('href', generateProductUrl(product.toJS()))
}

export function withQueryAndScope(to, currentLocation) {
  const paramKeys = [
    'preview',
    'previewTheme',
    'previewThemeSettings',
    'shop',
    'language',
    'token',
    'themeTitle',
    'isNowPreview',
  ]
  const params = paramKeys.reduce((result, key) => {
    const value = currentLocation.getIn(['query', key])
    if (value) {
      result[key] = value
    }
    return result
  }, {})

  // https://github.com/remix-run/react-router/blob/v3.2.6/docs/API.md#to
  let pathname, pathnameWithoutParams, existingParams, hash
  if (typeof to === 'string') {
    ;[pathname, hash = ''] = to.split('#')
    if (hash) hash = '#' + hash
    ;[pathnameWithoutParams, existingParams] = pathname.split('?')
  } else {
    pathname = pathnameWithoutParams = to.pathname || ''
    existingParams = buildURL('', to.query).substring(1)
    hash = to.hash || ''
  }

  const locale = getLocaleFromPath(currentLocation.get('pathname'))
  const pathLocale = locale && !pathname.startsWith(`/${locale}`) ? `/${locale}` : ''

  const scope = currentLocation.get('pathname').startsWith('/editor') ? `/editor${pathLocale}` : `${pathLocale}`

  // Makes `withQueryAndScope` usable if one of the param keys is already in the given url
  const uniqParams = (existingParams || '').split('&').reduce((acc, pair) => {
    const [key, value] = pair.split('=')

    // Its already separated from &, = symbols, so we can go with only replacing + and then decoding
    const valueDecoded = value === undefined ? value : decodeURIComponent(value.replace(/\+/g, '%20'))

    return paramKeys.includes(key) ? acc : { [key]: valueDecoded, ...acc }
  }, params)

  return typeof to === 'string'
    ? buildURL(scope + pathnameWithoutParams, uniqParams) + hash
    : { pathname: scope + pathnameWithoutParams, query: uniqParams, hash, state: to.state }
}
