import { memoize } from 'lodash'

/**
 * find a locale path prefix and return it
 *
 * @param {string} pathname the pathname of the request
 * @returns {string} the locale path prefix or an empty string
 */
const getLocaleFromPath = memoize((pathname) => {
  // regular expression for testing a string against the two-character language format e.g. `en`
  const localeRegEx = /^\w{2}$/i

  const splitPath = pathname.split('/')
  // in the editor the locale is the second part of the path
  const pathLocale = splitPath[1] === 'editor' ? splitPath[2] : splitPath[1]

  const isLocale = pathLocale && localeRegEx.test(pathLocale)

  return isLocale ? pathLocale : ''
})

export default getLocaleFromPath
