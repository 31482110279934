import type { ReactElement } from 'react'

export default function Order({ order }: OrderThemeComponentProps): ReactElement {
  return (
    <div className="template-order">
      <div>
        <div>
          {order.billingAddress.firstName} {order.billingAddress.lastName}
        </div>
        <div>{order.billingAddress.street}</div>
        <div>
          {order.billingAddress.postalCode} {order.billingAddress.city}
        </div>
        <div>{order.billingAddress.country}</div>
        <div>{order.billingAddress.email}</div>
      </div>
      <div>
        {order.lineItemContainer.productLineItems.map((lineItem) => (
          <div key={JSON.stringify(lineItem)}>
            {lineItem.image && (
              <div>
                <img src={lineItem.image.url} />
              </div>
            )}
            <div>{lineItem.name}</div>
            <div>{lineItem.essentialFeatures}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
