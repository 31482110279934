import type { ReactNode } from 'react'
import { useEffect, useId, useRef } from 'react'

import { useEditorSpotlight } from './useEditorSpotlight'

type EditorSpotlightProps = Readonly<{
  children: ReactNode
  onCancel?: VoidFunction
  title: string
}>

/**
 * Draws attention to an element in the editor by rendering it in a HTML dialog
 * element that is visually highlighted. The rest of the page is presented as a
 * modal-like backdrop. All elements except the spotlight element and all its
 * ancestors are set to "inert".
 *
 * Note that because ancestor elements are not set to "inert", they still can
 * be interacted with. For example, it allows for the spotlight to be placed in
 * a container that can be scrolled.
 *
 * @param children The content of the spotlight
 * @param onCancel Callback function that is called when closing with the Escape key
 * @param title The title used to aria-label the spotlight
 */
export function EditorSpotlight({ children, onCancel, title }: EditorSpotlightProps) {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const titleId = useId()

  useEditorSpotlight(dialogRef.current)

  useEffect(() => {
    dialogRef.current?.focus()
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onCancel) {
        event.preventDefault()
        onCancel()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onCancel])

  return (
    <dialog ref={dialogRef} aria-modal="true" aria-labelledby={titleId} open>
      <div className="visually-hidden" id={titleId}>
        {title}
      </div>
      {children}
    </dialog>
  )
}

export { useEditorSpotlight }
