import type { ReactElement } from 'react'

type Props = Readonly<{
  contact: {
    phone: string
    phoneSelected: boolean
    email: string
    emailSelected: boolean
    address: string
    addressSelected: boolean
  }
}>

export default function Contact({
  contact: {
    phone = '',
    phoneSelected = false,
    email = '',
    emailSelected = false,
    address = '',
    addressSelected = false,
  },
}: Props): ReactElement | null {
  if ((!phoneSelected && !emailSelected && !addressSelected) || (!phone && !email && !address)) return null

  return (
    <address className="contact">
      {phone && phoneSelected && (
        <a className="phone" href="/l/contact/phone">
          {phone}
        </a>
      )}
      {email && emailSelected && (
        <a className="email" href="/l/contact/email">
          <SpamProtect email={email} />
        </a>
      )}
      {address && addressSelected && <div className="address">{address}</div>}
    </address>
  )
}

// https://spencermortensen.com/articles/email-obfuscation/#text-display
function SpamProtect({ email }: Readonly<{ email: string }>) {
  if (!email.includes('.')) return email

  const parts = email.split('.')
  const one = parts.slice(0, parts.length - 2).join('.')
  const two = parts[parts.length - 2]
  const three = parts[parts.length - 1]

  return (
    <>
      {[one, two].filter(Boolean).join('.')}
      <b>.{two}</b>.{three}
    </>
  )
}
