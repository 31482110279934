import type { List } from 'immutable'
import Immutable from 'immutable'

import * as actions from '../actions'
import { generatePageUrl } from '../../urlGenerators'
import { hideChildren } from './navigation'
import { mapDeep, reduceDeep } from '../../utils/immutableTreeHelpers'
import getLocaleFromPath from '../../../utils/getLocaleFromPath'

export default function breadcrumb(state: State, action: actions.AllActionTypes): State {
  const isOnServer = typeof window === 'undefined'

  switch (action.type) {
    case actions.UPDATE_LOCATION:
    case actions.LOAD_NAVIGATION_SUCCESS:
    case actions.UPDATE_NAVIGATION_SUCCESS:
    case actions.UPDATE_PAGE_SETTINGS:
    case actions.UPDATE_PAGE_SETTINGS_FAILURE:
    case actions.UPDATE_CATEGORY_SETTINGS:
    case actions.UPDATE_CATEGORY_SETTINGS_FAILURE:
    case actions.UPDATE_VIEW: {
      if (!state.get('navigation') || (isOnServer && state.getIn(['breadcrumb', 0]))) return state

      const pathname = state.get('location').get('pathname')

      let breadcrumbTrail = Immutable.List()
      let currentPage: ImmutableMap | undefined

      // Try to find current (blank or category) page in navigation but use actual storefront path for that.
      const pathNameWithoutEditorAndLanguagePrefixes = pathname
        .replace(/^\/editor\/?/, '/')
        .replace(new RegExp(`^/${getLocaleFromPath(pathname)}/?`), '/')

      // Or maybe we're on a product page.
      const currentProductSlug = pathname.split('/').filter(Boolean).pop()
      const currentProduct = state.getIn(['products', currentProductSlug])
      if (currentProduct) {
        currentPage = Immutable.Map({
          title: currentProduct.get('name'),
          href: pathname,
        })
      }

      // Find and mark the current page in the tree, and build the breadcrumb trail along the way.
      const navWithCurrentPageMarkerAndSideFx = reduceDeep(
        state.getIn(['navigation', 'storefront']),
        (newNav, node) => {
          let isCurrentPage = false

          // If we're on a product page, create a breadcrumb trail from its main category and their parent categories.
          if (currentProduct && node.get('categoryId') === currentProduct.get('mainCategoryId', true)) {
            currentPage = currentPage?.set('parents', currentPage.get('parents', Immutable.List()).push(node))
            isCurrentPage = true
          } else if (node.get('href') === pathNameWithoutEditorAndLanguagePrefixes) {
            currentPage = node
            isCurrentPage = true
          }
          if (isCurrentPage) {
            breadcrumbTrail = currentPage?.get('parents', Immutable.List())
          }

          return newNav.push(node.set('isCurrentPage', isCurrentPage).set('isInBreadcrumb', isCurrentPage))
        },
      )

      // Build breadcrumb trail into navigation.
      const breadcrumbLength = breadcrumbTrail.count()
      let processedEntriesCount = 0

      const navWithBreadcrumbMarkers = mapDeep(
        navWithCurrentPageMarkerAndSideFx,
        (node) => {
          if (breadcrumbTrail.some((entry: ImmutableMap) => entry.get('id') === node.get('id'))) {
            processedEntriesCount += 1
            return node.set('isInBreadcrumb', true)
          }
          return node
        },
        processedEntriesCount === breadcrumbLength,
      )

      // Append current page if present.
      if (currentPage) breadcrumbTrail = breadcrumbTrail.push(currentPage)

      // Prepend startpage if needed.
      const startpage = navWithBreadcrumbMarkers.first()
      if (
        currentPage &&
        currentPage.get('id') !== startpage.get('id') &&
        (breadcrumbTrail.first() as ImmutableMap).get('id') !== startpage.get('id')
      ) {
        breadcrumbTrail = breadcrumbTrail.unshift(startpage)
      }

      // Remove redundant information, add href where needed.
      breadcrumbTrail = breadcrumbTrail.map((entry: ImmutableMap) =>
        entry
          .set('children', Immutable.List())
          .set('parents', Immutable.List())
          .set('href', entry.get('href', generatePageUrl(entry))),
      ) as List<ImmutableMap>

      const stateWithUpdatedNavigation = state.set(
        'navigation',
        state
          .get('navigation')
          // make the navigation appear shallow to speed up server side rendering (see navigation reducer)
          .set('storefront', isOnServer ? hideChildren(navWithBreadcrumbMarkers) : navWithBreadcrumbMarkers),
      )

      return action.type === actions.UPDATE_LOCATION
        ? stateWithUpdatedNavigation
        : stateWithUpdatedNavigation.set('breadcrumb', breadcrumbTrail)
    }
    default:
      return state
  }
}
