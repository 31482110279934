import type { ReactElement, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cc from 'classcat'

import { getPlain } from '../../store/utils'
import compose from '../../utils/compose'
import translate from '../../utils/translate'
import useEffectExceptMount from '../../utils/hooks/useEffectExceptMount'
import withI18n from '../../components/withI18n'

export default compose(withI18n('shop'), translate())(Notification)

type NotificationShape = {
  id?: string
  type?: string
  message?: ReactNode
}

// evil hack to retain cross mount state (this component remounts since each route transition remounts the complete theme)
let runningTimeoutId: number | undefined

function Notification({ duration = 8000, className, t, ...htmlAttributes }): ReactElement | null {
  const [visible, setVisible] = useState(Boolean(runningTimeoutId))

  const notification = getPlain(
    useSelector<State, NotificationShape>((state) => state.getIn(['view', 'themeNotification'], {})),
  )

  const { id, type, message } = notification

  const hide = useCallback(() => {
    setVisible(false)
    runningTimeoutId = undefined
  }, [])

  useEffect(() => {
    if (runningTimeoutId) {
      clearTimeout(runningTimeoutId)
      runningTimeoutId = window.setTimeout(hide, duration)
    }

    return () => clearTimeout(runningTimeoutId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // if there's a timer running while a new notification is displayed then
  // prevent this old timer from hiding the new notification (too soon / instantly)
  useEffectExceptMount(() => {
    clearTimeout(runningTimeoutId)
    runningTimeoutId = window.setTimeout(hide, duration)

    setVisible(Boolean(id))

    return () => clearTimeout(runningTimeoutId)
  }, [duration, hide, id])

  return (
    <div
      {...htmlAttributes}
      className={cc([
        className,
        'add-to-cart-notice',
        {
          'add-to-cart-notice-visible': visible,
          'add-to-cart-notice-hidden': !visible,
          [`add-to-cart-notice-${type}`]: type,
        },
      ])}
      onClick={hide}
    >
      <div className="add-to-cart-notice-wrapper">
        {message && <span className="add-to-cart-notice-message">{message}</span>}
        <span className="add-to-cart-notice-close" title={t('notifications.dismissButton.tooltip')} />
      </div>
    </div>
  )
}
