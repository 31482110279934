import type { LocationDescriptorObject } from 'history'
import type { PropsWithChildren, ReactElement } from 'react'
import type { Store } from 'redux'
import type { browserHistory } from 'react-router'
import { createContext } from 'react'

import { withQueryAndScope } from '../urlGenerators'

export type StateFunction = (location: LocationDescriptorObject) => null

type GotoFunction = (url: string) => null
type ContextType = {
  goto: GotoFunction
  replace: GotoFunction
  gotoState: StateFunction
}
export const GotoContext = createContext<ContextType>({
  goto: () => null,
  replace: () => null,
  gotoState: () => null,
})

type Props = {
  history?: typeof browserHistory
  store: Store<State>
}

export default function GotoProvider({ history, store, children }: Readonly<PropsWithChildren<Props>>): ReactElement {
  const goto = (url: string) => {
    if (history) {
      history.push(withQueryAndScope(url, store.getState().get('location')))
    }

    return null
  }
  const gotoState: StateFunction = (location) => {
    if (history) {
      history.push(location)
    }
    return null
  }
  const replace = (url: string) => {
    if (history) {
      history.replace(withQueryAndScope(url, store.getState().get('location')))
    }

    return null
  }
  return <GotoContext.Provider value={{ goto, gotoState, replace }}>{children}</GotoContext.Provider>
}
