import { List, Map, fromJS } from 'immutable'
import type { ReactElement } from 'react'
import type { RouteComponentProps } from 'react-router'
import { chunk, flatMap } from 'lodash'
import { createContext, useState } from 'react'
import { useSelector } from 'react-redux'
import omit from 'lodash/omit'

import {
  POSITION_ASC,
  PRICE_ASC,
  PRICE_DESC,
  createCategorySortings,
  pagingDefaults,
  sortingDefaults,
} from '../utils/pageAndSort'
import type { ProductDataType } from '../components/templateComponents/Workspace/plugins/category/CategoryPlugin'
import {
  addProductsForCategoryAsync,
  buildFilterArray,
  loadCategory,
  loadProductsByIds,
  resetAllCategoryFilters,
  setProductsForCategoryAsync,
  setViewBusyState,
} from '../store/actions'
import { createMultiLanguageLinks } from './utils/createMultiLanguageLinks'
import { getPlain } from '../store/utils'
import Theme from '../components/Theme'
import blocks from '../components/templateComponents/Workspace/blocks'

export const PageOrCategoryContext = createContext<ImmutableMap>(Map())

// since we know that Category is Now-only component
const sortingOptions = { ...POSITION_ASC, ...PRICE_ASC, ...PRICE_DESC }

// look up the GUID to the requested slug in the store state in order to save a db roundtrip
function getGuid(state: State, { params, isHomepage }: Pick<Props, 'params' | 'isHomepage'>): string {
  const navigation: ImmutableList = state.getIn(['navigation', 'storefront']) || List()
  return state.getIn(['categorySlugsToGUIDs', isHomepage ? navigation.getIn([0, 'slug']) : params.splat])
}

type Query = {
  page?: string
  resultsPerPage?: string
  sort?: string
}

type Props = {
  isHomepage: boolean
} & RouteComponentProps<unknown, { splat?: string }, any, Query>

export default function Category({ location: { query }, params, isHomepage }: Readonly<Props>): ReactElement {
  const guidOrRouterParam = useSelector<State, string | undefined>(
    (state) => getGuid(state, { params, isHomepage }) || params.splat,
  )
  const viewError = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'error']))
  const category = useSelector<State, ImmutableMap | undefined>((state) =>
    state.get('categories', Map()).get(guidOrRouterParam),
  )
  const productData = useSelector<State, ProductDataType>((state) => ({
    products: [],
    ...getPlain(state.getIn(['categoryProductData', guidOrRouterParam])),
  }))
  const isEditorMode = useSelector<State, boolean>((state) => Boolean(state.getIn(['view', 'editorMode'])))
  const isBusy = useSelector<State, boolean>((state) => state.getIn(['view', 'busy']))

  const [showScrollButton, setShowScrollButton] = useState(false)

  if (viewError || !category) return <Theme withLayout error={viewError} currentView="category" />

  const pageUrl: string = params.splat ? category.get('url') : '/'

  const imageSize = category.getIn(['settings', 'imageSize'], 'M')
  const pageSize = imageSize === 'S' ? 15 : 12

  const sort = query.sort || sortingDefaults.sort
  const page = query.page ? parseInt(query.page) : 1
  const resultsPerPage = query.resultsPerPage ? parseInt(query.resultsPerPage) : pageSize

  const totalNumberOfProducts = productData.totalNumberOfProducts || 0
  const totalNumberOfPages = Math.ceil(totalNumberOfProducts / resultsPerPage)

  const sortings = createCategorySortings(
    {
      pageUrl,
      page: pagingDefaults.page,
      resultsPerPage,
      sort,
    },
    sortingOptions,
  )

  const templateName = isHomepage ? 'Home' : 'Category'
  const products = productData.products.slice(0, page * pageSize)

  return (
    <PageOrCategoryContext.Provider value={category}>
      <Theme withLayout currentView={templateName}>
        {(renderView: any, props: any) =>
          renderView(templateName, {
            ...props,
            category: category.toJS(),
            productData: {
              products,
              sort: sortings,
              totalNumberOfProducts,
              categoryId: productData.categoryId,
              facets: productData.facets,
            },
            pageSize,
            sortingOptions,
            totalNumberOfPages,
            showScrollButton,
            isEditorMode,
            isBusy,
            onScrollIntoView: (inView) => {
              setShowScrollButton(!inView)
            },
          })
        }
      </Theme>
    </PageOrCategoryContext.Provider>
  )
}

Category.storeUpdate = ({ location, params, isHomepage }: Props, state: State) => {
  const isForwardNavigation = location.action === 'PUSH'
  // params.splat can be undefined and getGuid can be undefined. Maybe this can not happen at the same time ¯\_(ツ)_/¯
  // This code is working this way pretty good until now, so I will stop trying to fix what is not broken.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guidOrRouterParam: string = getGuid(state, { params, isHomepage }) || params.splat!

  const queryPage: number = parseInt(location.query.page ?? '') || 0

  async function loadAllCategoryProducts(dispatch: GlobalDispatch) {
    let category: ImmutableMap | undefined = state.getIn(['categories', guidOrRouterParam])
    const searchFacetFilters = isHomepage ? [] : buildFilterArray(location.query)

    const runningProductRequests: (
      | ReturnType<typeof loadProductsByIds>
      | ReturnType<typeof setProductsForCategoryAsync>
    )[] = []

    const sellingCountryId: number | null = state.getIn(['shop', 'sellingCountryId']) || null
    if (isForwardNavigation || !category) {
      try {
        // This is an old callApi action, not a think action. We can not type that properly.
        const result = (await dispatch(
          loadCategory(guidOrRouterParam, { showErrorNotification: false }),
        )) as unknown as {
          category: Core.Page
          csrfToken: string
        }

        // Load the product of a product plugin.
        const elements: Core.Plugin[] = [
          ...flatMap(result.category.content.blocks, ({ type, data }) => blocks[type].getElements(data)),
          ...result.category.content.elements,
        ]

        const productIdsFromProductPlugins = elements.reduce<string[]>((productIds, { type, data: { productId } }) => {
          if (type === 'epages.product' && productId) productIds.push(productId)
          return productIds
        }, [])
        // epagesJ allows a maximum of 12 ("size must be between 0 and 12 (path = ProductResource.getAll.arg1")
        for (const productIds of chunk(productIdsFromProductPlugins, 12)) {
          runningProductRequests.push(dispatch(loadProductsByIds('productPlugin', productIds, sellingCountryId)))
        }

        category = fromJS(result.category)
      } catch {}
    }

    const imageSize = category?.getIn(['settings', 'imageSize']) || 'M'
    const pageSize = imageSize === 'S' ? 15 : 12

    const totalProductAmount = queryPage * pageSize
    const productData = getPlain(state.getIn(['categoryProductData', guidOrRouterParam], { products: [] }))
    const products = productData.products
    const defaultSort = state.getIn(['shop', 'beyond']) ? 'price-asc' : 'position-asc'
    const sort = location.query.sort || defaultSort

    const categoryTotalNumberOfProducts = productData.totalNumberOfProducts

    if (
      !isForwardNavigation &&
      productData?.sort === sort &&
      products &&
      (products.length >= (queryPage || 1) * pageSize || products.length >= categoryTotalNumberOfProducts)
    ) {
      return null
    }

    if (queryPage > 0) {
      dispatch(setViewBusyState(true))
      const isLoadMore = location.action === 'REPLACE'

      // Fetching too many results per page at once from ePagesJ can lead to timeout errors.
      // But fetching too little is also not wanted. So we set somewhere in the middle.
      // To solve EPUI-2198, we only fetch integral multiples of the page size when loading
      // page numbers > 1 on the server, so we can seamlessly load more pages on the client.
      // See also EPUI-12, EPUI-26, and EPUI-1055 for the history of this.
      const maxResultsPerPage = 3 * pageSize

      let page = 1
      let resultsToFetch = totalProductAmount

      if (isLoadMore) {
        page = queryPage
        resultsToFetch = totalProductAmount - products.length
      }

      while (resultsToFetch > 0) {
        const resultsPerPage = resultsToFetch >= maxResultsPerPage ? maxResultsPerPage : pageSize
        const alreadyFetchedProducts = totalProductAmount - resultsToFetch
        page = Math.floor(alreadyFetchedProducts / resultsPerPage) + 1

        if (page === 1) {
          await dispatch(
            setProductsForCategoryAsync(
              guidOrRouterParam,
              {
                ...omit(location.query, ['token']),
                page,
                resultsPerPage,
              },
              searchFacetFilters,
            ),
          )
        } else {
          await dispatch(
            addProductsForCategoryAsync(
              guidOrRouterParam,
              {
                ...omit(location.query, ['token']),
                page,
                resultsPerPage,
              },
              searchFacetFilters,
            ),
          )
        }
        resultsToFetch -= resultsPerPage
      }

      dispatch(setViewBusyState(false))
    } else {
      runningProductRequests.push(
        dispatch<ResolvedApiAction<any>>(
          setProductsForCategoryAsync(
            guidOrRouterParam,
            {
              ...omit(location.query, ['token']),
              resultsPerPage: pageSize,
            },
            searchFacetFilters,
          ),
        ),
      )
    }

    await Promise.all(runningProductRequests)
  }

  const updates: [
    typeof loadAllCategoryProducts,
    ReturnType<typeof resetAllCategoryFilters>?,
    ReturnType<typeof loadCategory>?,
  ] = [loadAllCategoryProducts]

  if (isForwardNavigation) {
    updates.push(resetAllCategoryFilters(guidOrRouterParam))
  }

  if (isForwardNavigation || !state.getIn(['categories', guidOrRouterParam])) {
    updates.push(loadCategory(guidOrRouterParam))
  }

  return updates
}

Category.meta = ({ params, isHomepage, location }, state: State): View.Meta[] => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guidOrRouterParam: string = getGuid(state, { params, isHomepage }) || params.splat!
  const category = state.get('categories', Map()).get(guidOrRouterParam)
  if (!category) return []

  // Use the merchant-provided title if set, otherwise use the category title.
  // Append the shop title.
  //
  // For the home page, use the merchant-provided title if set, otherwise the
  // shop title with a fallback to the category title.
  const shopTitle = state.getIn(['shop', 'title'])
  const title = isHomepage
    ? category.get('titleTag') || shopTitle || category.get('title')
    : category.get('titleTag') || [category.get('title'), shopTitle].filter(Boolean).join(' - ')

  const meta: View.Meta[] = [{ title }]

  const metaDescription = category.get('metaDescription')
  if (metaDescription) {
    meta.push({ name: 'description', content: metaDescription })
  }

  if (location.query.sort || location.query.page) {
    meta.push({
      name: 'robots',
      content: 'noindex, follow',
    })
  }

  return meta
}

Category.link = ({ params, isHomepage }, state: State): View.Link[] => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guidOrRouterParam: string = getGuid(state, { params, isHomepage }) || params.splat!
  const category = state.get('categories', Map()).get(guidOrRouterParam)
  if (!category) return []

  return createMultiLanguageLinks(
    'category',
    isHomepage ? '' : category.get('slugs', List()).toJS(),
    state.get('shop'),
    isHomepage ? undefined : category.get('categoryId'),
  )
}

Category.contentCreationDisabled = () => false
