/**
 * Promise delay
 *
 * @param {number} delay
 * @returns {Promise<void>}
 */
export default function delay(delay) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve()
    }, delay)
  })
}
