import type { ReactElement } from 'react'

import Plugin from '../../templateComponents/Plugin'

export default function Legal(): ReactElement {
  return (
    <div className="legal">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <Plugin type="epages.text" _id="legal-content" />
          </div>
        </div>
      </section>
    </div>
  )
}
