import { stripIndents } from 'common-tags'

export const GTM_HEAD_SNIPPET_RE =
  /<script[\s\S]+gtm\.start[\s\S]+https:\/\/[\w.]+\/gtm\.js[\s\S]+(GTM-[A-Z0-9]+)[\s\S]+<\/script>/
export const GTM_BODY_SNIPPET_RE = /<iframe\s.+https:\/\/.+id=GTM-[A-Z0-9]+[\s\S]+<\/iframe>/

/** @param {string} gtmHeadSnippet Google Tag Manager HTML <head> snippet */
export function getGoogleTagManagerId(gtmHeadSnippet: string) {
  return gtmHeadSnippet.match(GTM_HEAD_SNIPPET_RE)?.[1] || ''
}

export function getGoogleTagManagerHeadHtml(id: string, cspNonce: string) {
  return id ? (
    <script
      nonce={cspNonce}
      dangerouslySetInnerHTML={{
        __html: stripIndents`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${id}');
      `,
      }}
    />
  ) : null
}

export function getGoogleTagManagerBodyHtml(id: string) {
  return id ? (
    <noscript>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  ) : null
}
