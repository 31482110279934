import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react'

import Editorial from './themeHeaders/Editorial'
import EditorialThreeRowsHeader from '../epages.editorial/themeHeaders/ThreeRowsHeader'
import EditorialTwoRowsHeader from '../epages.editorial/themeHeaders/TwoRowsHeader'
import Essence from './themeHeaders/Essence'
import Limelight from './themeHeaders/Limelight'
import OneRowHeader from './themeHeaders/OneRowHeader'
import OneRowLogoLeftHeader from './themeHeaders/OneRowLogoLeftHeader'
import Structure from './themeHeaders/Structure'
import ThreeRowHeader from './themeHeaders/ThreeRowHeader'
import TwoRowsHeader from './themeHeaders/TwoRowsHeader'
import TwoRowsLogoLeftHeader from './themeHeaders/TwoRowsLogoLeftHeader'
import Uptown from './themeHeaders/Uptown'
import VisionOneRowHeader from '../epages.vision/themeHeaders/OneRowHeader'
import VisionTwoRowsHeader from '../epages.vision/themeHeaders/TwoRowsHeader'

const headerMap: Record<ThemeHeaderTypes, FunctionComponent<PropsWithChildren<any>>> = {
  oneRow: OneRowHeader,
  twoRows: TwoRowsHeader,
  uptown: Uptown,
  editorial: Editorial,
  essence: Essence,
  structure: Structure,
  limelight: Limelight,
  threeRows: ThreeRowHeader,
  oneRowLogoLeft: OneRowLogoLeftHeader,
  twoRowsLogoLeft: TwoRowsLogoLeftHeader,

  // Legacy, old header type names. Needed until the old header type names are
  // no longer in use (i.e. not in the database of any platform).
  // See also "replacementHeaderTypes" in "utils/themes.ts".
  neutral: ThreeRowHeader,
  vision: OneRowLogoLeftHeader,
  twoRowsNestedMenu: TwoRowsLogoLeftHeader,
}

type Props = {
  shop: Core.FullShop
  themeSettings: Theme.Settings
}

export default function ThemeHeader(props: Readonly<Props>): ReactElement {
  const {
    themeSettings,
    shop: { theme },
  } = props

  const selectedHeader: ThemeHeaderTypes = themeSettings?.themeHeader?.headerType
  let Element
  if (selectedHeader === 'twoRows' && theme.name.startsWith('epages.vision')) {
    Element = VisionTwoRowsHeader
  } else if (selectedHeader === 'oneRow' && theme.name.startsWith('epages.vision')) {
    Element = VisionOneRowHeader
  } else if (selectedHeader === 'twoRows' && theme.name.startsWith('epages.editorial')) {
    Element = EditorialTwoRowsHeader
  } else if (selectedHeader === 'threeRows' && theme.name.startsWith('epages.editorial')) {
    Element = EditorialThreeRowsHeader
  } else {
    Element = headerMap[selectedHeader]
  }

  return <Element {...props} />
}
