import type { ReactElement } from 'react'

export default function Layout({ shop, content }: LayoutThemeComponentProps): ReactElement {
  return (
    <div className="template-layout">
      <div>{shop.slug}</div>
      Globals: {typeof process} {typeof global} {typeof Promise}
      <main>{content}</main>
    </div>
  )
}
