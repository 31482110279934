import { HeadlineBlock, MultiColumnBlock, NestedBlockWithHeadline } from './MultiColumnBlock'
import { IconWithText, ImageWithText, VideoWithText } from './PluginWithTextBelow'

const blocks = {
  'headline-block': HeadlineBlock,
  'nested-headline-block': NestedBlockWithHeadline,
  'multi-column': MultiColumnBlock,
  'image-with-text': ImageWithText,
  'icon-with-text': IconWithText,
  'video-with-text': VideoWithText,
}

export default blocks
