/**
 * Initialize the tracking and add the event target to the window.
 *
 */
function init() {
  try {
    window.eComEventTarget = new EventTarget()
  } catch (err) {
    // in Edge and Safari EventTarget is no constructor
    window.eComEventTarget = document.createElement('aside')
  }
}

/**
 * trigger an event on the event target
 */
function track(name: Storefront.EventName, detail?: any): void
function track(name: string, detail?: any): void
function track(name: string, detail?: any): void {
  if (typeof window !== 'object' || !window.eComEventTarget) return

  // Check if all 3rd party scripts are loaded and ready before firing the events.
  if (window.__EP.readyState === 'complete') {
    dispatchTrackEvent(name, detail)
  } else {
    document.getElementById('app')!.addEventListener(
      'app:load',
      () => {
        dispatchTrackEvent(name, detail)
      },
      { once: true },
    )
  }
}

function addLegacyProductProperties(product: Core.Product | Frontend.Product, isBeyondShop: boolean) {
  const legacyProperty = { _deprecated: 'use strikePrice and isStrikePriceRRP instead', ...product.strikePrice }
  const useListPrice = isBeyondShop && !product.isStrikePriceRRP

  return {
    ...product,
    listPrice: product.strikePrice && useListPrice ? legacyProperty : null,
    manufacturerPrice: product.strikePrice && !useListPrice ? legacyProperty : null,
  }
}

export { track, init, addLegacyProductProperties }

function dispatchTrackEvent(name: Storefront.EventName | string, data?: any) {
  const detail = data ? ('toJS' in data ? data : deepFreeze(data)) : undefined
  window.eComEventTarget.dispatchEvent(new CustomEvent(name, { detail }))
}

function deepFreeze(obj: Record<string, any>) {
  // fetch property keys
  const propKeys = Object.getOwnPropertyNames(obj)

  // recursively freeze all properties
  propKeys.forEach((key) => {
    const propValue = obj[key]

    if (propValue && typeof propValue === 'object') deepFreeze(propValue)
  })

  return Object.freeze(obj)
}
