import Immutable from 'immutable'

import * as actions from '../actions'

export default function location(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.UPDATE_LOCATION:
      return state
        .set('location', Immutable.fromJS(action.location))
        .setIn(['view', 'editorMode'], action.location.pathname?.startsWith('/editor'))
    default:
      return state
  }
}
