import { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'
import noop from 'lodash/noop'

export default class Resizable extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
    onResize: PropTypes.func,
  }

  static defaultProps = {
    position: 'bottom-right',
    onResize: noop,
  }

  constructor(props) {
    super(props)

    this.widthSignFactor = getWidthSignFactor(props.position)
    this.heightSignFactor = getHeightSignFactor(props.position)
  }

  componentDidUpdate(prevProps) {
    if (this.props.position !== prevProps.position) {
      this.widthSignFactor = getWidthSignFactor(this.props.position)
      this.heightSignFactor = getHeightSignFactor(this.props.position)
    }
  }

  handleResizeElementMouseDown = (event) => {
    event.preventDefault()

    this.rect = this.element.getBoundingClientRect()

    this.isMouseDown = true
    this.initialWidth = this.rect.width
    this.initialHeight = this.rect.height
    this.initialClientX = event.clientX
    this.initialClientY = event.clientY

    window.requestAnimationFrame(this.updateElementSize)

    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseMove = (event) => {
    event.preventDefault()

    this.deltaX = event.clientX - this.initialClientX
    this.deltaY = this.initialClientY - event.clientY
  }

  handleMouseUp = () => {
    this.isMouseDown = false
    this.shouldResize = false
    this.deltaX = this.deltaY = 0

    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
  }

  updateElementSize = () => {
    if (this.isMouseDown) {
      window.requestAnimationFrame(this.updateElementSize)
    }

    this.shouldResize = this.deltaX * this.deltaX + this.deltaY * this.deltaY > 100

    if (this.shouldResize) {
      const width = this.initialWidth + this.deltaX * this.widthSignFactor
      const height = this.initialHeight + this.deltaY * this.heightSignFactor
      const isNewWidth = width !== this.width && this.rect.left + width - window.innerWidth < 0
      const isNewHeight = height !== this.height && this.rect.top + height - window.innerHeight < 0

      if (isNewWidth) {
        this.width = width
        this.element.style.width = `${width}px`
      }

      if (isNewHeight) {
        this.height = height
        this.element.style.height = `${height}px`
      }

      if (isNewWidth || isNewHeight) {
        this.props.onResize(this.width, this.height)
      }
    }
  }

  render() {
    const element = this.props.children

    return cloneElement(
      element,
      {
        className: cc([element.props.className, 'ep-resizable']),
        ref: (node) => {
          this.element = node
          const { ref } = element
          if (typeof ref === 'function') ref(node)
        },
      },
      [
        element.props.children,
        <div
          className="ep-resizable-handle"
          data-position={this.props.position}
          onMouseDown={this.handleResizeElementMouseDown}
          key="handle"
        />,
      ],
    )
  }
}

function getWidthSignFactor(position) {
  return position.endsWith('left') ? -1 : 1
}

function getHeightSignFactor(position) {
  return position.startsWith('bottom') ? -1 : 1
}
