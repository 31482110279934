import type { ReactElement } from 'react'

import Link from '../../templateComponents/Link'

type Props = {
  error: {
    message: string
    statusCode: number
    requestId: string | null
  }
  shop: Frontend.Shop
} & TranslateProps

export default function ErrorComponent({ t, error, shop }: Readonly<Props>): ReactElement {
  return (
    <div className="error-page">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>{t('views.storefrontView.errorPageSection.errorcode', { code: error.statusCode })}</h1>
            <div className="error-page-info">
              <h3>
                {t(
                  error.statusCode === 404
                    ? 'views.storefrontView.errorPageSection.error404'
                    : 'views.storefrontView.errorPageSection.error500',
                )}
              </h3>
              {error.requestId && (
                <p className="error-page-info-explanation">
                  {t('views.storefrontView.errorPageSection.idExplanation')} <code>{error.requestId}</code>
                </p>
              )}
              <p>
                <Link to={shop.href} className="error-page-info-link">
                  {t('views.storefrontView.returnToStartpageLink.label')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
