import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Immutable from 'immutable'
import cc from 'classcat'

import { getGoogleTagManagerBodyHtml } from './utils/googleTagManager'

/**
 * Renders the document for the app.
 * Used in the `App` component on the client, and in the `serveApp` middleware on the server.
 *
 * We need to render the same document structure on the server as well as on the client because the entire
 * document is hydrated on the client, including correct locale and meta information, e.g. title, description,
 * etc. which also gets updated on the client when navigating to a different page.
 *
 * Note that our main scripts are added via SSR by React, and third-party scripts (e.g. storefront app scripts)
 * are dynamically initialized on the client via the `App` component.
 */
export function Document({
  preloadLinkElements,
  styleLinkElements,
  syncStartScriptElement,
  asyncSnippetScriptElements,
  loadableRequiredChunksScriptElements,
  googleTagManagerId,
  children,
}: Readonly<DocumentProps>) {
  const meta = useSelector<State, View.Meta[] | ImmutableList>((state) => state.getIn(['view', 'meta']))
  const link = useSelector<State, View.Link[] | ImmutableList>((state) => state.getIn(['view', 'link']))
  const locale = useSelector<State, string>((state) => state.getIn(['shop', 'locale']))
  const isNowPreview = useSelector<State, boolean>((state) => state.hasIn(['location', 'query', 'isNowPreview']))

  // `meta` and `link` are an Immutable.List, but only initially.
  const currentMeta: View.Meta[] = Immutable.List.isList(meta) ? (meta as ImmutableList).toJS() : meta
  const currentLink: View.Link[] = Immutable.List.isList(link) ? (link as ImmutableList).toJS() : link

  const metaElements = currentMeta.map((attributes) =>
    attributes.title ? (
      <title key="title">{attributes.title}</title>
    ) : (
      <meta key={`${attributes.name || attributes.property}${attributes.content}`} {...attributes} />
    ),
  )

  const linkElements = currentLink.map((attributes) => (
    <link key={attributes.rel + attributes.href + attributes.hrefLang} {...attributes} />
  ))

  return (
    <html
      suppressHydrationWarning // the no-js class is removed on the client in "start.js"
      className={cc(['no-js', isNowPreview && 'ep-mbo-preview-mode'])}
      lang={locale.substring(0, 2)}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {metaElements}
        {preloadLinkElements}
        {asyncSnippetScriptElements}
        {styleLinkElements}
        {linkElements}
        {syncStartScriptElement}
      </head>
      <body className={cc([isNowPreview && 'ep-mbo-preview-mode'])}>
        {googleTagManagerId && getGoogleTagManagerBodyHtml(googleTagManagerId)}
        <div id="app">{children}</div>
        {loadableRequiredChunksScriptElements}
      </body>
    </html>
  )
}

type DocumentProps = {
  googleTagManagerId: string
  children: ReactElement

  // The following props are only set when rendering on the server (`serveApp` middleware).
  // React treats <html>, <head> and <body> as special types of host elements and will never
  // remove them from the DOM, i.e. no hydration errors on the client. For more details, see:
  // https://github.com/facebook/react/issues/24430#issuecomment-1440427646
  preloadLinkElements?: ReactElement
  styleLinkElements?: ReactElement
  syncStartScriptElement?: ReactElement
  asyncSnippetScriptElements?: ReactElement | null
  loadableRequiredChunksScriptElements?: ReactElement
}
