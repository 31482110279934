export type SearchActionTypes =
  | SelectFacetedSearchFacetValueAction
  | SetFacetedSearchFacetRangeAction
  | ResetAllFiltersAction
  | ResolvedApiAction<GetSearchSuggestions>

export const SELECT_FACETED_SEARCH_FACET_VALUE = 'SELECT_FACETED_SEARCH_FACET_VALUE'
type SelectFacetedSearchFacetValueAction = {
  type: typeof SELECT_FACETED_SEARCH_FACET_VALUE
  payload: {
    facetId: string
    valueId: string
    selected: boolean
  }
}
export function selectFacetedSearchFacetValue(
  facetId: string,
  valueId: string,
  selected: boolean,
): SelectFacetedSearchFacetValueAction {
  return {
    type: SELECT_FACETED_SEARCH_FACET_VALUE,
    payload: {
      facetId,
      valueId,
      selected,
    },
  }
}

export const SET_FACETED_SEARCH_FACET_RANGE = 'SET_FACETED_SEARCH_FACET_RANGE'
type SetFacetedSearchFacetRangeAction = {
  type: typeof SET_FACETED_SEARCH_FACET_RANGE
  payload: {
    facetId: string
    range: {
      min: number
      max: number
    }
  }
}
export function setFacetedSearchFacetRange(
  facetId: string,
  range: { min: number; max: number },
): SetFacetedSearchFacetRangeAction {
  return {
    type: SET_FACETED_SEARCH_FACET_RANGE,
    payload: {
      facetId,
      range,
    },
  }
}

export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'
type ResetAllFiltersAction = {
  type: typeof RESET_ALL_FILTERS
}
export function resetAllFilters(): ResetAllFiltersAction {
  return {
    type: RESET_ALL_FILTERS,
  }
}

type GetSearchSuggestions = {
  type: typeof GET_SEARCH_SUGGESTIONS
  payload: {
    query: string
  }
}
export const GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS'
export const GET_SEARCH_SUGGESTIONS_SUCCESS = 'GET_SEARCH_SUGGESTIONS_SUCCESS'
export const GET_SEARCH_SUGGESTIONS_FAILURE = 'GET_SEARCH_SUGGESTIONS_FAILURE'
export function getSearchSuggestions(query: string): ApiAction<GetSearchSuggestions> {
  return {
    type: GET_SEARCH_SUGGESTIONS,
    idempotent: true,
    payload: { query },
    callApi: (api, payload, { locale }) =>
      api
        .get('/api/v2/suggestedSearch', {
          params: {
            q: payload.query,
            limit: 50,
            sort: 'createdAt-desc',
            locale,
          },
        })
        .then((res) => res.data),
  }
}
