import type { ReactElement } from 'react'

const getSortedBulkPrices = (bulkPrices: NonNullable<Frontend.Product['bulkPrices']>) =>
  // Array.sort changes the original variable which causes bugs with react state.
  // See https://stackoverflow.com/a/66721870
  [...bulkPrices].sort((a, b) => a.quantity.amount - b.quantity.amount)

type BulkPricesDataStructureProps = {
  bulkPrices: Frontend.Product['bulkPrices']
}

export function BulkPricesDataStructure({ bulkPrices }: Readonly<BulkPricesDataStructureProps>): ReactElement | null {
  if (!bulkPrices || bulkPrices.length === 0) return null

  return (
    <div>
      {getSortedBulkPrices(bulkPrices).map((bulkPrice) => (
        <div key={bulkPrice.quantity.amount}>
          <div itemProp="priceSpecification" itemScope itemType="http://schema.org/UnitPriceSpecification">
            <meta itemProp="price" content={bulkPrice.price.amount.toString()} />
            <meta itemProp="priceCurrency" content={bulkPrice.price.currency} />
            <div itemProp="eligibleQuantity" itemScope itemType="http://schema.org/QuantitativeValue">
              <meta itemProp="minValue" content={bulkPrice.quantity.amount.toString()} />
              <meta itemProp="maxValue" content={bulkPrice.quantity.amount.toString()} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

type BulkPricesProps = {
  product: Frontend.Product
} & TranslateProps

export default function BulkPrices({ product, t }: Readonly<BulkPricesProps>): ReactElement | null {
  if (!product.bulkPrices || product.bulkPrices.length === 0) return null

  const hasDiscount = product.bulkPrices.some((bulkPrice) => bulkPrice.priceSavings.percent.percentage !== 0)

  return (
    <div className="product-addition bulk-prices">
      <h2 className="product-addition-headline">{t('components.productComponent.bulkPrices.title')}</h2>

      <div className="product-addition-list-head">
        <span>{t('components.productComponent.bulkPrices.quantity')}</span>
        <span>{t('components.productComponent.bulkPrices.price')}</span>
        {hasDiscount && <span>{t('components.productComponent.bulkPrices.percentageDiscount')}</span>}
      </div>

      {getSortedBulkPrices(product.bulkPrices).map((bulkPrice) => (
        <div key={bulkPrice.quantity.amount}>
          <span className="product-addition-list-quantity">
            {t('components.productComponent.bulkPrices.itemQuantity', {
              quantity: bulkPrice.quantity.amount,
              unit: bulkPrice.quantity.unit,
            })}
          </span>
          <span className="product-addition-list-price">
            {bulkPrice.priceSavings?.regularBulkPrice?.formatted || bulkPrice.price.formatted}
          </span>
          <span className="product-addition-list-price">
            {t('components.productComponent.bulkPrices.pricePerItem', {
              price: bulkPrice.priceSavings?.regularBulkPrice?.formatted || bulkPrice.price.formatted,
            })}
          </span>
          {hasDiscount && (
            <>
              <span className="product-addition-list-discount">
                {bulkPrice.priceSavings.percent?.formatted !== '0 %' && bulkPrice.priceSavings.percent?.formatted}
              </span>
              <span className="product-addition-list-discount">
                {' '}
                {bulkPrice.priceSavings.percent?.formatted !== '0 %' &&
                  t('components.productComponent.bulkPrices.percentageDiscountValue', {
                    percent: bulkPrice.priceSavings.percent?.formatted,
                  })}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
