type Params = object | (Params | Date)[]
type ParamsSerializer = (params: URLSearchParams | Params) => string

/*
  API compatible but dependency free version of
  https://github.com/axios/axios/blob/v0.27.2/lib/helpers/buildURL.js
  passes https://github.com/axios/axios/blob/v0.27.2/test/specs/helpers/buildURL.spec.js

  Note that after 0.27.2, there were breaking changes to buildURL,
  see https://github.com/axios/axios/pull/4734

  We need this function because after 0.27.2, buildURL is no longer exported,
  see https://github.com/axios/axios/issues/5000#issuecomment-1311350960
 */
export default function buildURL(url = '', params?: URLSearchParams | Params, paramsSerializer?: ParamsSerializer) {
  const fallbackOrigin = 'http://baseurl-will-be-discarded'
  const { href, origin, pathname, search } = new URL(url, fallbackOrigin)
  const hasOrigin = origin !== fallbackOrigin

  // WHATWG URL adds a slash after the host, Axios doesn't but keeps it when it's brought
  let axiosPathname = pathname
  if (
    href.substring(origin.length).substring(0, 1) !==
    url
      // strip redundant default port just like WHATWG URL
      .replace(/(https?|wss?):\/\/([^/]+):(80|443)/, '$1://$2')

      .substring(hasOrigin ? origin.length : 0)
      .substring(0, 1)
  ) {
    axiosPathname = axiosPathname.substring(1)
  }

  let builtUrl = axiosPathname + search

  if (hasOrigin) builtUrl = origin + builtUrl

  if (params) {
    let builtParams: string
    if (typeof paramsSerializer === 'function') {
      builtParams = paramsSerializer(params)
    } else if (params instanceof URLSearchParams) {
      builtParams = params.toString()
    } else {
      builtParams = parseParams(params)
    }
    if (builtParams) {
      builtUrl += (search ? '&' : '?') + builtParams
    }
  }

  return builtUrl
}

function parseParams(params: Params) {
  const url = new URL('http://host-will-be-discarded')

  for (const name in params) {
    const param = maybeParseDate(params[name])

    if (!isAllowedValue(param)) continue

    if (Array.isArray(param)) {
      param.filter(isAllowedValue).forEach((entry) => {
        url.searchParams.append(name + '[]', maybeParseDate(entry).toString())
      })
    } else if (typeof param === 'object') {
      url.searchParams.set(name, JSON.stringify(param))
    } else {
      url.searchParams.set(name, param)
    }
  }

  return url.searchParams
    .toString()
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']')
}

function isAllowedValue(param: Params | null | undefined | string) {
  return param !== null && typeof param !== 'undefined'
}

function maybeParseDate(param: Params) {
  if (param instanceof Date) return param.toISOString()
  return param
}
