import type { ReactElement } from 'react'

type Props = {
  searchData: Frontend.SearchData
} & TranslateProps

export default function SearchSortingForm({ t, searchData }: Readonly<Props>): ReactElement {
  return (
    <div className="sorting">
      <button type="button" className="sorting-button">
        {t('components.productListComponent.sortingButton.label')}
      </button>
      <span>{t('components.productListComponent.sortingDropdown.label')}</span>
      <div className="sorting-select">
        <select
          value={searchData.sortings.find((s) => s.active)?.sorting}
          onChange={(e) => searchData.updateSorting(e.target.value)}
        >
          {searchData.sortings.map((sorting) => (
            <option value={sorting.sorting} key={sorting.label}>
              {t('components.productListComponent.sortingDropdown.options.' + sorting.label)}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
