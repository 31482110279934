import type { SetStateAction } from 'react'
import { useEffect, useRef } from 'react'

import { getNormalizedZoomFactor } from './ImageEditor.helpers'
import { maxZoomFactor, zoomStep } from './ImageEditorMenu'

export function useWheelZoom(
  container: HTMLElement | null,
  setZoomFactor: (value: SetStateAction<number>) => void,
  enabled = true,
) {
  const isEnabled = useRef(enabled)
  useEffect(() => {
    isEnabled.current = enabled
  }, [enabled])

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (!isEnabled.current) return
      event.preventDefault()
      setZoomFactor((zoomFactor) => {
        let newZoomFactor = zoomFactor
        if (event.deltaY > 0 && zoomFactor > 1) {
          newZoomFactor = getNormalizedZoomFactor(zoomFactor - zoomStep)
        }
        if (event.deltaY < 0 && zoomFactor < maxZoomFactor) {
          newZoomFactor = getNormalizedZoomFactor(zoomFactor + zoomStep)
        }
        return newZoomFactor
      })
    }

    container?.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      container?.removeEventListener('wheel', handleWheel)
    }
  }, [container, setZoomFactor])
}
