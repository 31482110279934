import type { AxiosInstance } from 'axios'
import { useEffect, useState } from 'react'

import usePrevious from '../utils/hooks/usePrevious'
import withReduxContext from '../utils/withReduxContext'

function XhrLoadingBar({ store }) {
  const [runningRequests, setRunningRequests] = useState(0)
  const [percent, setPercent] = useState(0)
  const previousRunningRequests = usePrevious(runningRequests)
  const api: AxiosInstance = store.api

  useEffect(() => {
    const requestInterceptorId = api.interceptors.request.use((config) => {
      setRunningRequests((runningRequests) => runningRequests + 1)
      return config
    }, Promise.reject)

    const responseInterceptorId = api.interceptors.response.use(
      (response) => {
        setRunningRequests((runningRequests) => runningRequests - 1)
        return response
      },
      (error) => {
        setRunningRequests((runningRequests) => runningRequests - 1)
        return Promise.reject(error)
      },
    )

    return () => {
      api.interceptors.request.eject(requestInterceptorId)
      api.interceptors.response.eject(responseInterceptorId)
    }
  }, [api])

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    // Auto-increment the progress bar after a short moment when there are running requests,
    // and restart progress when a new request started while another is still running.
    if (runningRequests > 0 && percent < 99) {
      if (previousRunningRequests && previousRunningRequests < runningRequests) {
        setPercent(0)
      }
      timeoutId = setTimeout(() => setPercent(percent + (Math.random() + 1 - Math.random())), 500)
    }

    // Finish when all running requests are finished.
    if (runningRequests === 0 && previousRunningRequests === 1 && percent < 99) {
      setPercent(99.9)
    }

    // Reset the progress bar after having it shown for some time.
    if (percent > 99) {
      timeoutId = setTimeout(() => setPercent(0), 400)
    }

    return () => clearTimeout(timeoutId)
  }, [percent, runningRequests, previousRunningRequests])

  return <div className="xhr-loading-bar" style={{ ['--progress' as string]: percent > 0 ? percent : null }} />
}

export default withReduxContext(XhrLoadingBar)
