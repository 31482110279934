const scopes = {
  category: 'c',
  product: 'p',
  blank: 'i',
}

export function createMultiLanguageLinks(
  pageType: 'category' | 'product' | 'blank',
  slugs: Array<{ locale: string; slug: string }> | string,
  shop: ImmutableMap,
  guid?: string,
) {
  const baseUrl: string = shop.getIn(['locationBase', 'origin'])
  const visibleLocales: string[] = shop
    .get('locales')
    .filter((locale: ImmutableMap) => locale.get('isVisible'))
    .map((locale: ImmutableMap) => locale.get('identifier'))
    .toJS()

  return visibleLocales.length > 1
    ? visibleLocales.reduce<{ rel: 'alternate'; hrefLang: string; href: string }[]>((tags, locale) => {
        let item = typeof slugs === 'string' ? { locale, slug: slugs } : slugs.find((slug) => slug.locale === locale)

        // Fallback to GUID of entity in case a slug is not yet available.
        if (!item?.slug && guid) item = { locale, slug: guid }

        if (!item) return tags

        const language = item.locale.substring(0, 2)
        const prefix = item.slug ? `/${scopes[pageType]}/` : ''

        if (item.locale === shop.get('defaultLocale')) {
          tags.push({
            rel: 'alternate',
            hrefLang: language,
            href: `${baseUrl}${prefix}${item.slug}`,
          })
          tags.push({
            rel: 'alternate',
            hrefLang: 'x-default',
            href: `${baseUrl}${prefix}${item.slug}`,
          })
        } else {
          tags.push({
            rel: 'alternate',
            hrefLang: language,
            href: `${baseUrl}/${language}${prefix}${item.slug}`,
          })
        }

        return tags
      }, [])
    : []
}
