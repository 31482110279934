import i18next, { init } from 'i18next'

export const languages = [
  'en-GB',
  'en',
  'de',
  'de-AT',
  'de-CH',
  'ca',
  'cs',
  'da',
  'es',
  'es-MX',
  'fi',
  'fr',
  'it',
  'nl',
  'no',
  'pt',
  'pt-BR',
  'ru',
  'sv',
]
const ns = ['base', 'themes']

if (!i18next.isInitialized) {
  init({
    ns,
    lng: 'en',
    defaultNS: 'base',
    fallbackLng: {
      // it is no-NO in Now, but nb-NO in Beyond
      'nb-NO': ['no', 'en'],
      default: ['en'],
    },
    joinArrays: '\n',
    returnNull: false,
    interpolation: {
      // Escaping not needed (values are escaped by React)
      escapeValue: false,
      // Enable translation of nested keys
      skipOnVariables: false,
    },
    resources: {
      // do NOT remove this object! Otherwise no resources will ever be loaded
    },
    debug: false,
  })
}

// so impure, aren't we...
export async function ensureResourceBundlesLoaded(lng: string, instance = i18next): Promise<void> {
  const fallbackLng =
    lng.length > 2
      ? lng
          .substring(0, 2)
          // it is no-NO in Now, but nb-NO in Beyond
          .replace('nb', 'no')
      : null
  await Promise.all(
    ns.map(async (namespace) => {
      if (!instance.hasResourceBundle(lng, namespace)) {
        try {
          instance.addResourceBundle(lng, namespace, await import(`../locales/${namespace}.${lng}.json`))
        } catch {
          // finally will take care of loading the 2-character locales.
        } finally {
          // Ensure the fallback language is loaded, i18next will not load them.
          if (fallbackLng && !instance.hasResourceBundle(fallbackLng, namespace))
            instance.addResourceBundle(
              fallbackLng,
              namespace,
              await import(`../locales/${namespace}.${fallbackLng}.json`),
            )
        }
      }
    }),
  )
}

export default i18next
