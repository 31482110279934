import type { AnyAction, Store } from 'redux'
import { Component } from 'react'
import type { ReactElement } from 'react'
import type { RouteComponentProps, RouterState } from 'react-router'
import { connect } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'

import {
  NAME_ASC,
  NAME_DESC,
  PRICE_ASC,
  PRICE_DESC,
  RELEVANCE,
  createSortings,
  getDefaultSorting,
  pagingDefaults,
} from '../utils/pageAndSort'
import type { Query } from '../components/templateComponents/FacetedSearch/FacetedSearchToolbarTop'
import type { SearchDataType } from '../store/reducers/products'
import { addLegacyProductProperties, track } from '../utils/tracking'
import { changeSearchQuery } from '../components/templateComponents/FacetedSearch/FacetedSearchToolbarTop'
import { getPlain } from '../store/utils'
import { loadProductsForSearchAsync } from '../store/actions'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import withReduxContext from '../utils/withReduxContext'

type Props = {
  viewError?: any
  searchData?: State
  category?: State
  isBeyond: boolean
  isBusy: boolean
  store: Store<State>
} & TranslateProps

type ReactState = {
  isToolbarTopInView: boolean
}

type ComponentRouteProps = RouteComponentProps<unknown, unknown, Props, Query>

export class SearchRaw extends Component<Props & ComponentRouteProps, ReactState> {
  state = {
    isToolbarTopInView: true,
  }

  lastSearchData: SearchDataType | null = null

  static storeUpdate = (props: ComponentRouteProps, state: State): (AnyAction | GlobalAction)[] => {
    const { searchData } = mapStateToProps(state, props)
    const isForwardNavigation = props.location.action === 'PUSH'
    const componentDidUpdateWillTakeCare = props.location.action === 'REPLACE'

    const updates: (AnyAction | GlobalAction)[] = []
    if ((isForwardNavigation || !searchData) && !componentDidUpdateWillTakeCare) {
      updates.push(loadProductsForSearchAsync())
    }
    return updates
  }

  static meta = (props: RouterState, state: State, t: TranslateProps['t']): View.Meta[] => {
    const title = props.location.query.q
      ? t('components.productSearchComponent.resultsCard.resultsState.title', { queryString: props.location.query.q })
      : t('components.productSearchComponent.submitButton.label')

    const meta: View.Meta[] = [
      { title: `${title} - ${state.getIn(['shop', 'title'])}` },
      { name: 'robots', content: 'noindex, follow' },
    ]

    return meta
  }

  static loadableSsrChunks = (_props: RouterState, state: State) => {
    const hasFacets = !isEmpty(state.getIn(['searchData', 'facets']))
    return hasFacets ? ['FacetedSearch'] : []
  }

  static contentCreationDisabled = (): boolean => true

  componentDidMount(): void {
    const { searchData, location, isBeyond } = this.props

    if (searchData) {
      const data: SearchDataType = getPlain<SearchDataType>(searchData)
      this.lastSearchData = data

      track('searchResults:view', {
        products: data.products.map((product) => addLegacyProductProperties(product, isBeyond)),
        query: location.query,
      })
    }
  }

  componentDidUpdate(prevProps: Props & ComponentRouteProps): void {
    const { searchData, location, isBeyond } = this.props
    const data: SearchDataType | undefined = getPlain<SearchDataType | undefined>(searchData)
    const prevData: SearchDataType | undefined = getPlain<SearchDataType | undefined>(prevProps.searchData)

    // Check for changes in the location query.
    // If something relevant has changed we are not going to append products but load them new.
    // We have to combine all query parameters from current and previews location because
    // some parameters could have been removed, like search facets.
    const didSomethingChange = [...Object.keys(location.query), ...Object.keys(prevProps.location.query)]
      // Changes to `page` mean we do actually want to append products, so we ignore them.
      .filter((key) => key !== 'page')
      .some((key) => !isEqual(location.query[key], prevProps.location.query[key]))

    if (data && !isEqual(data, prevData)) {
      track('searchResults:view', {
        products: data.products.map((product) => addLegacyProductProperties(product, isBeyond)),
        query: location.query,
      })
    }
    if (location && !isEqual(prevProps.location, location)) {
      const dispatch: any = this.props.store.dispatch
      dispatch(loadProductsForSearchAsync(!didSomethingChange && location.query.page !== prevProps.location.query.page))
    }

    if (prevProps.searchData) {
      this.lastSearchData = getPlain<SearchDataType | undefined>(prevProps.searchData) || null
    }
  }

  render(): ReactElement {
    const { viewError, isBeyond, location, isBusy, store } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="page" />

    const propsSearchData = getPlain<SearchDataType | undefined>(this.props.searchData)

    // Ensure smooth transition: use the last truthy searchData as fallback
    const searchData: SearchDataType =
      propsSearchData ||
      this.lastSearchData ||
      ({
        products: [],
        totalNumberOfProducts: 0,
        query: '',
        sort: 'relevance',
      } as SearchDataType)

    const { q } = location.query
    const queryPage = parseInt(location.query.page)
    const sortingOptions = isBeyond
      ? { ...RELEVANCE, ...PRICE_ASC, ...PRICE_DESC }
      : { ...RELEVANCE, ...NAME_ASC, ...NAME_DESC, ...PRICE_ASC, ...PRICE_DESC }
    const sort = searchData.sort

    const sortings = createSortings(sort, sortingOptions)

    const loadedPageCount = Math.ceil(searchData.products.length / pagingDefaults.resultsPerPage)
    const pageCount = Math.ceil(searchData.totalNumberOfProducts / pagingDefaults.resultsPerPage)
    const showLoadMoreButton = searchData.products.length < searchData.totalNumberOfProducts
    const templateName = q && !isEmpty(searchData.facets) ? 'FacetedSearch' : 'Search'

    const trackProductClick = (product: Core.Product, productIndex: number) => {
      track('product:click', {
        type: 'search',
        detail: q,
        product: addLegacyProductProperties(product, isBeyond),
        productIndex,
      })
    }

    return (
      <Theme withLayout currentView="Search">
        {(renderView: any, props: any) =>
          renderView(templateName, {
            ...props,
            searchData: {
              queryString: q,
              ...searchData,
              products: searchData.products.slice(0, (queryPage || 1) * pagingDefaults.resultsPerPage),
              sortings,
              updateSorting: (sorting: string) => {
                changeSearchQuery(store, { sort: sorting, page: 1, q })
              },
            },
            loadedPageCount,
            loadMoreProducts: () => {
              if (loadedPageCount >= pageCount) return

              const page = Number(location.query.page) || 1
              changeSearchQuery(store, { sort, page: page + 1, q })
            },
            showLoadMoreButton,
            isBusy,
            showScrollButton: !this.state.isToolbarTopInView,
            onScrollIntoView: (isToolbarTopInView: boolean) => this.setState({ isToolbarTopInView }),
            trackProductClick,
          })
        }
      </Theme>
    )
  }
}

function mapStateToProps(state: State, props: ComponentRouteProps) {
  const { q, sort } = props.location.query
  const isBeyond = Boolean(state.getIn(['shop', 'beyond']))
  const serializedQuery = `${q}-${sort || getDefaultSorting()}`

  return {
    isBeyond,
    viewError: state.getIn(['view', 'error']),
    searchData: state.getIn(['searchData', serializedQuery], undefined),
    isBusy: state.getIn(['view', 'busy']),
  }
}

export default compose(connect(mapStateToProps), withReduxContext)(SearchRaw)
