import type { Location } from 'history'

export type LocationActionTypes = UpdateLocationAction

type UpdateLocationAction = {
  type: typeof UPDATE_LOCATION
  location: Partial<Location>
}
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export function updateLocation(location: Partial<Location>): UpdateLocationAction {
  return {
    type: UPDATE_LOCATION,
    location,
  }
}
