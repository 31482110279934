import { fromJS } from 'immutable'
import striptags from 'striptags'

import { emptyHeadline } from '../../../editorSidebarBlockTemplates'
import Element from '../Element'
import blockTypes from './index'

const pluginsWithoutHeadline = ['epages.text', 'epages.divider']

export type Props = Readonly<{
  config: UnknownRecord
  data: ImmutableMap
  editorView: boolean
  onDataChange: (data: ImmutableMap) => void
}>
export const MultiColumnBlock = makeMultiColumnBlock(false)
export const NestedBlockWithHeadline = makeMultiColumnBlock(true)

export function HeadlineBlock(props: Props) {
  return (
    <div className="dali-grid-row">
      <div className="dali-grid-column">
        <NestedBlockWithHeadline {...props} />
      </div>
    </div>
  )
}
HeadlineBlock.getElements = getElements

function makeMultiColumnBlock(isNested: boolean) {
  function MultiColumnBlock({ config, editorView, data, onDataChange }: Props) {
    const columns = data.get('columns')
    const isMultiColumn = columns.size > 1

    const needsNoHeadline = columns.size === 1 && pluginsWithoutHeadline.includes(columns.getIn(['0', 'type']))
    const isHeadlineEmpty = striptags(data.getIn(['headline', 'data', 'content'])) === ''

    let renderedHeadline: JSX.Element | null = (
      <Element
        config={config}
        editorView={editorView}
        isMultiColumn={isMultiColumn}
        element={data.get('headline') || fromJS(emptyHeadline)}
        onElementChange={(element: Core.Plugin) => onDataChange(data.set('headline', element))}
      />
    )

    let renderedColumns: JSX.Element = columns.map((column, index) => {
      const BlockComponent = blockTypes[column.get('type')]
      return (
        <div key={index} className={isNested ? undefined : 'dali-grid-column'}>
          {BlockComponent ? (
            <BlockComponent
              config={config}
              editorView={editorView}
              data={column.get('data')}
              onDataChange={(block: Core.Block) => onDataChange(data.setIn(['columns', index, 'data'], block))}
            />
          ) : (
            <Element
              config={config}
              editorView={editorView}
              element={column}
              isMultiColumn={isMultiColumn}
              onElementChange={(element: Core.Plugin) => onDataChange(data.setIn(['columns', index], element))}
            />
          )}
        </div>
      )
    })

    if (!isNested) {
      renderedHeadline = <div className="dali-grid-headline">{renderedHeadline}</div>
      renderedColumns = <div className="dali-grid-row">{renderedColumns}</div>
    }

    if ((editorView && needsNoHeadline) || (!editorView && isHeadlineEmpty)) renderedHeadline = null

    return (
      <>
        {renderedHeadline}
        {renderedColumns}
      </>
    )
  }

  MultiColumnBlock.getElements = getElements

  MultiColumnBlock.displayName = isNested ? 'NestedBlockWithHeadline' : 'MultiColumnBlock'

  return MultiColumnBlock
}

function getElements(data: Core.Block['data']) {
  return [data.headline, ...data.columns].filter(Boolean)
}
