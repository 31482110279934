import { Map } from 'immutable'

import * as actions from '../actions'

export default function customer(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.LOGIN_CUSTOMER_SUCCESS:
      return state
        .setIn(['customer', 'loggedIn'], true)
        .setIn(['customer', 'loggedInUntil'], action.response.exp * 1000)
        .deleteIn(['customer', 'confirmedNewEmail'])
    case actions.SIGNUP_CUSTOMER_SUCCESS:
      return state
        .setIn(['customer', 'loggedIn'], true)
        .setIn(['customer', 'loggedInUntil'], action.response.exp * 1000)
        .setIn(['customer', 'firstName'], action.firstName)
        .setIn(['customer', 'lastName'], action.lastName)
    case actions.GET_CUSTOMER_DETAILS_SUCCESS:
      return state
        .setIn(['customer', 'billingAddress'], action.response.billingAddress)
        .setIn(['customer', 'shippingAddress'], action.response.shippingAddress)
    case actions.UPDATE_CUSTOMER_BILLING_ADDRESS_SUCCESS:
      return state.setIn(['customer', 'billingAddress'], action.response)
    case actions.UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS:
      return state.setIn(['customer', 'shippingAddress'], action.response)
    case actions.REFRESH_CUSTOMER_LOGIN_SUCCESS:
      return state.setIn(['customer', 'loggedInUntil'], action.response.exp * 1000)
    case actions.LOGOUT_CUSTOMER_SUCCESS:
      return state.set('customer', Map({ confirmedNewEmail: state.getIn(['customer', 'confirmedNewEmail']) }))
    case actions.REQUEST_EMAIL_CHANGE_SUCCESS:
      return state.setIn(['customer', 'requestedNewEmail'], action.response)
    case actions.CONFIRM_EMAIL_CHANGE_SUCCESS:
      return state.setIn(['customer', 'confirmedNewEmail'], action.response)
    case actions.LIST_ORDERS_SUCCESS:
      return state.setIn(['customer', 'orders'], action.response)
    default:
      return state
  }
}
