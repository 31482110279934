import type { MouseEvent, ReactNode } from 'react'
import { useRef, useState } from 'react'

interface AccordionItemProps {
  title: string
  titleClassName?: string
  children: ReactNode
}

export default function AccordionItem({ title, titleClassName, children }: Readonly<AccordionItemProps>) {
  const [disclosureTriangleOpen, setDisclosureTriangleOpen] = useState<boolean>(false)
  const [isAnimating, setIsAnimating] = useState<boolean>(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDetailsElement>(null)
  const animationRef = useRef<Animation | null>(null)

  const onAnimationFinish = (open: boolean) => {
    animationRef.current = null
    setIsAnimating(false)
    if (detailsRef.current) {
      detailsRef.current.open = open
      detailsRef.current.style.height = ''
    }
  }

  const animateHeight = (startHeight: string, endHeight: string, isOpening: boolean) => {
    setIsAnimating(true)
    if (animationRef.current) animationRef.current.cancel()

    if (detailsRef.current) {
      animationRef.current = detailsRef.current.animate(
        {
          height: [startHeight, endHeight],
        },
        {
          duration: 300,
          easing: 'ease-in-out',
        },
      )
      animationRef.current.onfinish = () => onAnimationFinish(isOpening)
      animationRef.current.oncancel = () => setIsAnimating(false)
    }
  }

  const expand = () => {
    const startHeight = `${detailsRef.current?.offsetHeight ?? 0}px`
    const summaryHeight = summaryRef.current?.offsetHeight ?? 0
    const contentHeight = contentRef.current?.offsetHeight ?? 0
    const endHeight = `${summaryHeight + contentHeight}px`
    animateHeight(startHeight, endHeight, true)
  }

  const shrink = () => {
    const startHeight = `${detailsRef.current?.offsetHeight}px`
    const endHeight = `${summaryRef.current?.offsetHeight}px`
    animateHeight(startHeight, endHeight, false)
  }

  const open = () => {
    if (detailsRef.current) {
      detailsRef.current.style.height = `${detailsRef.current.offsetHeight}px`
      detailsRef.current.open = true
      setDisclosureTriangleOpen(true)
      window.requestAnimationFrame(() => expand())
    }
  }

  const handleToggle = (event: MouseEvent) => {
    event.preventDefault()

    if (isAnimating) return

    if (!detailsRef.current?.open) {
      open()
    } else {
      setDisclosureTriangleOpen(false)
      window.requestAnimationFrame(() => shrink())
    }
  }

  return (
    <details ref={detailsRef} className={`${disclosureTriangleOpen ? 'open' : 'close'}`}>
      <summary ref={summaryRef} className={titleClassName} onClick={handleToggle}>
        {title}
      </summary>
      <div style={{ display: 'grid' }} ref={contentRef}>
        {children}
      </div>
    </details>
  )
}
