import type { ImgHTMLAttributes } from 'react'
import { useSelector } from 'react-redux'

import LazyImage from './LazyImage'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

const widths = ['180', '360', '540', '720', '900', '1080'] as const

type Props = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet' | 'sizes' | 'alt'> & TranslateProps

export function PlaceholderImageRaw({ t, ...htmlAttributes }: Props) {
  const hashedThemeAssets = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'hashedThemeAssets']))

  return (
    <LazyImage
      src={`/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
        'epages.base/assets/images',
        'placeholder_540.jpg',
      ])}`}
      srcSet={widths
        .map(
          (w) =>
            `/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
              'epages.base/assets/images',
              `placeholder_${w}.jpg`,
            ])} ${w}w`,
        )
        .join(',')}
      sizes="auto"
      alt={t('components.productComponent.placeholderImageAltText')}
      {...htmlAttributes}
    />
  )
}

/** Renders a product placeholder image */
export default compose(withI18n('shop'), translate())(PlaceholderImageRaw)
