export default function defaultOptionsMiddleware() {
  return ({ getState }) =>
    (next) =>
    (action) => {
      return next({
        ...action,
        options: {
          showErrorNotification: true,
          locale: getState().getIn(['shop', 'locale']),
          ...action.options,
        },
      })
    }
}
