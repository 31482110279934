import { func, number, shape, string } from 'prop-types'
import { useState } from 'react'
import cc from 'classcat'

import Lightbox from './Lightbox'
import Portal from './Portal'

export default function EnergyLabel({ energyLabel, energyLabelSourceFile, t }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="energy-label-wrapper">
      <span
        onClick={() => setIsOpen(true)}
        className={cc([
          'energy-label',
          `energy-label energy-label-grade-${energyLabel.colorId}`,
          {
            'energy-label-range-wrapper': !!energyLabel.range,
          },
        ])}
        title={t('components.energyLabelComponent.energyEfficencyClass')}
      >
        {energyLabel.range ? (
          <span className="energy-label-content-wrapper">
            <span className="energy-label-range">
              <span>{energyLabel.range.max}</span>
              <span>&uarr;</span>
              <span>{energyLabel.range.min}</span>
            </span>
            <span className="energy-label-text">{energyLabel.text}</span>
          </span>
        ) : (
          energyLabel.text
        )}
      </span>
      <Portal>
        <Lightbox show={isOpen} closeOnBackdropClick onClose={() => setIsOpen(false)}>
          <img className="energy-label-image" src={energyLabelSourceFile} alt={energyLabel.text} />
        </Lightbox>
      </Portal>
    </div>
  )
}

EnergyLabel.propTypes = {
  energyLabel: shape({
    colorId: number.isRequired,
    text: string.isRequired,
    range: shape({ max: string.isRequired, min: string.isRequired }),
  }).isRequired,
  energyLabelSourceFile: string.isRequired,
  t: func.isRequired,
}
