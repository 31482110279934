import type { ReactElement } from 'react'

import Breadcrumb from '../epages.base/Breadcrumb'
import CustomCss from '../../templateComponents/CustomCss'
import DeliveryCountrySelection from '../../templateComponents/DeliveryCountrySelection'
import Footer from '../epages.base/Footer'
import HeaderAnnouncementBar from '../epages.base/HeaderAnnouncementBar'
import HeaderUsp from '../epages.base/HeaderUsp'
import ThemeHeader from '../epages.base/ThemeHeader'

export default function Layout(props: LayoutThemeComponentProps): ReactElement {
  const {
    themeSettings,
    isTaxationByDestinationCountryEnabled,
    isEditor,
    content,
    shop,
    breadcrumb,
    legalPages,
    footerPages,
    t,
  } = props

  return (
    <div className="container">
      <CustomCss src={(themeSettings.customStyle || {}).customCss} />

      {!isEditor && isTaxationByDestinationCountryEnabled ? (
        <section className="section delivery-country-selection">
          <DeliveryCountrySelection t={t} />
        </section>
      ) : null}

      {shop.userSettings.headerAnnouncement?.active ? (
        <HeaderAnnouncementBar headerAnnouncementHtml={shop.userSettings.headerAnnouncement.announcementHtml} />
      ) : null}

      {shop.userSettings.headerUsps.length ? <HeaderUsp headerUsps={shop.userSettings.headerUsps} /> : null}

      <div className="page-canvas">
        <ThemeHeader {...props} />
        <section className="section">
          <div className="wrapper">
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
        </section>

        <main className="main">{content}</main>

        <Footer shop={shop} legalPages={legalPages} footerPages={footerPages} t={t} />
      </div>
    </div>
  )
}
