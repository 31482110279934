import type { ChangeEvent, MouseEvent, ReactElement, Reducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useReducer } from 'react'
import Cookies from 'js-cookie'

import { getPlain } from '../../store/utils'
import { loadShopCountries, setSellingCountry } from '../../store/actions'

function getInitialState(shopLocale: string, sellingCountryIdAlreadySet: boolean): MachineState {
  if (typeof window === 'undefined') return { id: 'on-server' }

  const cookieValue = Cookies.get('epCountrySelection')

  if (cookieValue && sellingCountryIdAlreadySet) return { id: 'cookie-aleady-set', cookieValue }

  const locale = new Intl.Locale(window.navigator.language)

  if (!locale.region) return { id: 'select-delivery-country-manually', context: { selectedCountryId: null } }

  const language = new Intl.DisplayNames([shopLocale], { type: 'language' }).of(locale.language)
  const region = new Intl.DisplayNames([shopLocale], { type: 'region' }).of(locale.region)

  if (!language || !region) return { id: 'select-delivery-country-manually', context: { selectedCountryId: null } }

  return {
    id: 'confirm-detected-country',
    context: {
      code2: locale.region,
      language,
      region,
    },
  }
}

type MachineState =
  | { id: 'initial' }
  | { id: 'confirm-detected-country'; context: { code2: string; language: string; region: string } }
  | { id: 'select-delivery-country-manually'; context: { selectedCountryId: string | null } }
  | { id: 'cookie-aleady-set'; cookieValue: string }
  | { id: 'on-server' }
  // Final state
  | { id: 'country-selected' }

type MachineAction =
  | { type: 'CONFIRM_COUNTRY' }
  | { type: 'REPLACE_STATE'; payload: MachineState }
  | { type: 'CHOOSE_COUNTRY' }
  | { type: 'SET_SELECTED_COUNTRY'; payload: string | null }

type MachineReducer = Reducer<MachineState, MachineAction>

const reducer: MachineReducer = (state, action) => {
  switch (action.type) {
    case 'REPLACE_STATE':
      return action.payload
    case 'CONFIRM_COUNTRY':
      return { id: 'country-selected' }
    case 'CHOOSE_COUNTRY':
      return { id: 'select-delivery-country-manually', context: { selectedCountryId: null } }
    case 'SET_SELECTED_COUNTRY':
      return { id: 'select-delivery-country-manually', context: { selectedCountryId: action.payload } }
    default:
      return state
  }
}

export default function DeliveryCountrySelection({ t }: TranslateProps): ReactElement | null {
  const featureEnabled = useSelector<State, boolean>(
    // Enable feature only in NOW shops when the setting is set to true
    (state) => !state.getIn(['shop', 'beyond']) && state.getIn(['tax', 'taxationByDestinationCountry']),
  )
  const cookieConsent = useSelector<State, boolean>((state) => state.get('cookieConsent'))
  const shopLocale = useSelector<State, string>((state) => state.getIn(['shop', 'locale']).replace('_', '-'))
  const shopCountryId = useSelector<State, number>((state) => state.getIn(['shop', 'countryId']))
  const shopSellingCountryId = useSelector<State, number>((state) => state.getIn(['shop', 'sellingCountryId']))
  const shopMboCountries = useSelector<State, Core.SellingCountry[]>((state) =>
    getPlain(state.getIn(['shop', 'mboCountries'], [])),
  )

  const initialState = getInitialState(shopLocale, !!shopSellingCountryId)

  useEffect(() => {
    if (!shopSellingCountryId && cookieConsent !== null) {
      Cookies.set('epCountrySelection', `${shopCountryId}`, { expires: 365 * 2, sameSite: 'lax' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (featureEnabled) {
      dispatch(loadShopCountries(shopLocale))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopMboCountries.length])

  useEffect(() => {
    if (shopMboCountries.length > 0) {
      send({
        type: 'REPLACE_STATE',
        payload:
          initialState.id === 'confirm-detected-country'
            ? shopMboCountries.find((country) => country.code2 === initialState.context.code2 && country.isEU)
              ? initialState
              : { id: 'select-delivery-country-manually', context: { selectedCountryId: null } }
            : initialState,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopMboCountries.length])

  const dispatch = useDispatch<GlobalDispatch>()

  const [machine, send] = useReducer<MachineReducer>(reducer, { id: 'initial' })

  const confirmDetectedCountry = (evt: MouseEvent<HTMLButtonElement>) => {
    const deliveryCountryId = (
      shopMboCountries.find((country) => country.code2 === evt.currentTarget.dataset.code2) ?? shopMboCountries[0]
    ).countryId

    // expires defines expiration of the cookie in days.
    Cookies.set('epCountrySelection', `${deliveryCountryId}`, { expires: 365 * 2, sameSite: 'lax' })
    dispatch(setSellingCountry(deliveryCountryId))

    send({
      type: 'CONFIRM_COUNTRY',
    })

    window.location.reload()
  }

  const chooseDeliveryCountry = () => {
    send({
      type: 'CHOOSE_COUNTRY',
    })
  }

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    send({
      type: 'SET_SELECTED_COUNTRY',
      payload: evt.target.value === 'none' ? null : evt.target.value,
    })
  }

  const confirmSelectedCountry = (evt: MouseEvent<HTMLButtonElement>) => {
    const countryId = evt.currentTarget.dataset.countryId as string
    // expires defines expiration of the cookie in days.
    Cookies.set('epCountrySelection', `${countryId}`, { expires: 365 * 2, sameSite: 'lax' })
    dispatch(setSellingCountry(Number(countryId)))

    send({
      type: 'CONFIRM_COUNTRY',
    })

    window.location.reload()
  }

  if (!['confirm-detected-country', 'select-delivery-country-manually'].includes(machine.id)) return null

  return (
    <div role="dialog" className="delivery-country-selection-topbar">
      {machine.id === 'confirm-detected-country' && (
        <>
          <span className="country-detected">
            {t('components.deliveryCountrySelection.detectedCountryConfirmation.text', {
              language: machine.context.language,
              country: machine.context.region,
            })}
          </span>
          <div>
            <button onClick={confirmDetectedCountry} data-code2={machine.context.code2}>
              {t('components.deliveryCountrySelection.detectedCountryConfirmation.yesButton', {
                country: machine.context.region,
              })}
            </button>
            <button onClick={chooseDeliveryCountry} data-code2={machine.context.code2}>
              {t('components.deliveryCountrySelection.detectedCountryConfirmation.noButton')}
            </button>
          </div>
        </>
      )}
      {machine.id === 'select-delivery-country-manually' && (
        <>
          <label htmlFor="delivery-country-selection">
            {t('components.deliveryCountrySelection.manualSelection.text')}
          </label>
          <div className="country-selection">
            <select onChange={handleChange} id="delivery-country-selection">
              <option value="none">
                {t('components.deliveryCountrySelection.manualSelection.selectCountryOption')}
              </option>
              {shopMboCountries.map(({ countryId, name }) => (
                <option key={countryId} value={countryId}>
                  {name}
                </option>
              ))}
              <option value={shopCountryId}>
                {t('components.deliveryCountrySelection.manualSelection.otherCountryOption')}
              </option>
            </select>
          </div>
          <button
            onClick={confirmSelectedCountry}
            disabled={!machine.context.selectedCountryId}
            data-country-id={machine.context.selectedCountryId}
          >
            {t('components.deliveryCountrySelection.manualSelection.confirmButton')}
          </button>
        </>
      )}
    </div>
  )
}
