import type { AllActionTypes } from '../actions'
import type { FailureAction } from './errors'
import breadcrumb from './breadcrumb'
import cart from './cart'
import categories from './categories'
import categorySearch from './categorySearch'
import customer from './customer'
import errors from './errors'
import location from './location'
import navigation from './navigation'
import pages from './pages'
import products from './products'
import search from './search'
import shop from './shop'
import view from './view'

type Reducer = (state: State, action: AllActionTypes | FailureAction) => State

const combineReducers = (reducers: Reducer[]): Reducer => {
  return (state, action) => {
    return reducers.reduce((state, red) => red(state, action), state)
  }
}

export default combineReducers([
  location,
  errors,
  view,
  shop,
  cart,
  customer,
  navigation,
  categories,
  categorySearch,
  breadcrumb,
  products,
  search,
  pages,
])
