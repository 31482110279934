const timer = {
  start: function (): [number, number] {
    return process.hrtime()
  },
  end: function (start?: [number, number]): number {
    const elapsed = process.hrtime(start)
    return (elapsed[0] + elapsed[1] * 1e-9) * 1e3
  },
}

export function createFunctionWithTimeout(callback: () => void, optTimeout: number): () => void {
  let called = false
  function fn() {
    if (!called) {
      called = true
      callback()
    }
  }
  setTimeout(fn, optTimeout || 1000)
  return fn
}

export default timer
